import { axiosInstance, axiosPrivate } from '../../constants/axiosInstance';
import { endpoints } from '../../constants/url.endpoint';
import { userActions } from './user.reducer';

const { loginSuccess, logoutSuccess} = userActions;

export const login =(userCredentials) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosPrivate({
                url: endpoints.userLogin,
                method: 'POST',
                data: userCredentials
            }).then((res) => {
                dispatch(loginSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const orgSignup =(userCredentials) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosPrivate({
                url: endpoints.orgSignup,
                method: 'POST',
                data: userCredentials
            }).then((res) => {
                // dispatch(loginSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const forgetPasswordAction =(userCredentials) => async () => {
    return new Promise((resolve, reject)=>{
        axiosPrivate({
            url: endpoints.userForgetPassword,
            method: 'POST',
            data: userCredentials
        }).then((res) => {
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
};

export const resetPasswordAction =(userCredentials) => async (dispatch) => {
    return new Promise((resolve, reject)=>{
        axiosInstance({
            url: `${endpoints.userResetPassword + '/'}${userCredentials.urlToken}`,
            method: 'POST',
            data: userCredentials
        }).then((res) => {
            resolve(res)
        }).catch(err=>{
            reject(err)
        });
    })
};
    
export const logout = () => async (dispatch) => {
    try {
        return dispatch(logoutSuccess(null));
    } catch (e) {
        //catch err
    }
};