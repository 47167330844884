import React from 'react';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';
import SubscriptionPlanComponent from '../../components/OrganizationAdmin/SubscriptionPlan/SubscriptionPlan.component';

const SubscriptionPlansPage = () => {

    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<SubscriptionPlanComponent/>}/>
            </div>
        </div>
        );
    }

export default SubscriptionPlansPage;
