import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: null,
};
const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    loadingStart: (state, action) => {
      state.loading = true;
    },
    loadingStop: (state, action) => {
      state.loading = false;
    }
  }
});

export const {
    actions: loaderActions,
    reducer: loaderReducer,
    name: loaderName
} = loaderSlice;
