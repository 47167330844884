import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AptillioToaster from '../../../../components/Global/AptillioToaster/AptillioToaster.component';
import { endpoints } from '../../../../constants/url.endpoint';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';

const VerifyOrgSignupPage = () => {
    const [isEmailVerified, setEmailVerified] = useState(false);
    const axiosInstance = useAxiosInstance();
    const location = useLocation();
    const [verificationError, setVerificationError] = useState([]);
    const [toastList, setToastList] = useState([]);

    useEffect(()=>{
        const routeSplit = location?.pathname.split('/')
        const orgId = routeSplit[routeSplit.length-1]
                axiosInstance({
                    url: `${endpoints.orgSignupVerify}/${orgId}`,
                    method: 'PATCH',
                }).then((res) => {
                        setToastList([{id:0, title:"Verification Success", description: res.data.message, icon:"success"}])
                        setEmailVerified(true)
                }).catch(err => {
                        setEmailVerified(false)
                        setToastList([{id:0, title:"Failed", description: err?.response?.data?.message || "Verification failed.", icon:"error"}])
                        setVerificationError(err?.response?.data?.message || "There might be some issue with the server, please try again after some time." )
                });
    },[])
    
    return (
        <div className="forgetPassword-container">
            <div className="forgetPassword">
                <div className="forgetPassword__child forgetPasswordpage__child-right">
                    <div className="forgetPassword__child__textbox forgetPassword__child__textbox-right">
                        <div className='center'><img src="/logo-one.png" alt="logo" /></div>
                        <br />
                        <div className="forgetPassword__heading">
                            <h3>Account Verification</h3>
                        </div>
                        <div className="forgetPassword-success">
                            {
                                !isEmailVerified? <p>
                                    { verificationError ? verificationError : "Please wait while we verify your Account." }
                                </p>
                                :
                                <p>
                                    Your Aptillio Account has been verified by us. Please <a href='/login'>click here</a> to Login.
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
            </div>
        </div>
    );
};

export default VerifyOrgSignupPage;
