export const routeItems = [
    {
        label: 'Login',
        route: '/login',
        component: 'LoginPage',
        roleLevel: [],
        protected: false
    },
    {
        label: 'ForgetPassword',
        route: '/forget-password',
        component: 'ForgetPassword',
        roleLevel: [],
        protected: false
    },
    {
        label: 'ResetPassword',
        route: '/reset-password/:id',
        component: 'ResetPassword',
        roleLevel: [],
        protected: false
    },
    {
        label: 'OrganizationAdminSignup',
        route: '/organization/signup',
        component: 'OrganizationAdminSignup',
        roleLevel: [],
        protected: false
    },
    {
        label: 'VerifyOrganizationSignup',
        route: '/organization/signup/verify/:id',
        component: 'VerifyOrganizationSignup',
        roleLevel: [],
        protected: false
    },
    {
        label: 'ManageOrganisationPage',
        route: '/',
        component: 'ManageOrganisationPage',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ViewOrganisationDetails',
        route: '/manageOrganization/view/:id',
        component: 'ViewOrganisationDetails',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageHiringManager',
        route: '/manageHiringManager',
        component: 'ManageHiringManager',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AddHiringManager',
        route: '/manageHiringManager/add',
        component: 'AddHiringManager',
        roleLevel: [],
        protected: true
    },
    {
        label: 'EditHiringManager',
        route: '/manageHiringManager/edit/:id',
        component: 'EditHiringManager',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageBusinessPsychologist',
        route: '/manageBusinessPsychologist',
        component: 'ManageBusinessPsychologist',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AddBusinessPsychologist',
        route: '/manageBusinessPsychologist/add',
        component: 'AddBusinessPsychologist',
        roleLevel: [],
        protected: true
    },
    {
        label: 'EditBusinessPsychologist',
        route: '/manageBusinessPsychologist/edit/:id',
        component: 'EditBusinessPsychologist',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ViewBusinessPsychologist',
        route: '/manageBusinessPsychologist/view/:id',
        component: 'ViewBusinessPsychologist',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageCandidate',
        route: '/manageCandidate',
        component: 'ManageCandidate',
        roleLevel: [],
        protected: true
    },
    {
        label: 'SubmitCandidate',
        route: '/manageCandidate/add',
        component: 'SubmitCandidate',
        roleLevel: [],
        protected: true
    },
    {
        label: 'EditDraftCandidate',
        route: '/manageCandidate/edit/:id',
        component: 'EditDraftCandidate',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ViewCandidate',
        route: '/manageCandidate/view/:id',
        component: 'ViewCandidate',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageJobRole',
        route: '/manageJobRole',
        component: 'ManageJobRole',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AddJobRole',
        route: '/manageJobRole/add',
        component: 'AddJobRole',
        roleLevel: [],
        protected: true
    },
    {
        label: 'EditJobRole',
        route: '/manageJobRole/edit/:id',
        component: 'EditJobRole',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageSubscription',
        route: '/manageSubscription',
        component: 'ManageSubscription',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AddSubscription',
        route: '/manageSubscription/add',
        component: 'AddSubscription',
        roleLevel: [],
        protected: true
    },
    {
        label: 'EditSubscription',
        route: '/manageSubscription/edit/:id',
        component: 'EditSubscription',
        roleLevel: [],
        protected: true
    },
    {
        label: 'SubscriptionPlans',
        route: '/subscriptionPlans',
        component: 'SubscriptionPlans',
        roleLevel: [],
        protected: true
    },
    {
        label: 'CandidateParameter',
        route: '/candidateParameter',
        component: 'CandidateParameter',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AddCandidateParameter',
        route: '/candidateParameter/add',
        component: 'AddCandidateParameter',
        roleLevel: [],
        protected: true
    },
    {
        label: 'EditCandidateParameter',
        route: '/candidateParameter/edit/:id',
        component: 'EditCandidateParameter',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ProductFeaturePage',
        route: '/productFeature',
        component: 'ProductFeaturePage',
        roleLevel: [],
        protected: true
    },
    {
        label: 'MyAccount',
        route: '/myAccount',
        component: 'MyAccount',
        roleLevel: [],
        protected: true
    },
    {
        label: 'SubscriptionPayment',
        route: '/subscription/payment/:id',
        component: 'SubscriptionPayment',
        roleLevel: [],
        protected: true
    },
    {
        label: 'BookCandidateSlot',
        route: '/bookSlot/:id/:id',
        component: 'BookCandidateSlot',
        roleLevel: [],
        protected: false
    },
    {
        label: 'ManageBookingSlots',
        route: '/manageCalender',
        component: 'ManageBookingSlots',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageInvoices',
        route: '/manageInvoices',
        component: 'ManageInvoices',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageInvoices',
        route: '/manageInvoices/:id',
        component: 'ManageInvoices',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageInvoicesGroup',
        route: '/manageInvoicesByGroup',
        component: 'ManageInvoicesGroup',
        roleLevel: [],
        protected: true
    },
    {
        label: 'CandidateAssessmentCompleted',
        route: '/completeAssessment/:id',
        component: 'CandidateAssessmentCompleted',
        roleLevel: [],
        protected: false
    },
    {
        label: 'CandidateInterviewPage',
        route: '/candidate/interview/:id',
        component: 'CandidateInterviewPage',
        roleLevel: [],
        protected: false
    },
    {
        label: 'ManageResume',
        route: '/manageResume',
        component: 'ManageResume',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AddAssessmentFeedback',
        route: '/assessment/feedback/add/:id',
        component: 'AddAssessmentFeedback',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AssessmentFeedback',
        route: '/assessment/feedback/:id',
        component: 'AssessmentFeedback',
        roleLevel: [],
        protected: true
    },
    {
        label: 'AssessmentTestStatus',
        route: '/assessment/checkStatus/:id',
        component: 'AssessmentTestStatus',
        roleLevel: [],
        protected: false
    },
    {
        label: 'ManageReports',
        route: '/manageReports',
        component: 'ManageReports',
        roleLevel: [],
        protected: true
    },
    {
        label: 'ManageNotifications',
        route: '/manageNotifications',
        component: 'ManageNotifications',
        roleLevel: [],
        protected: true
    },
];