import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddManageDataComponent from '../../../components/Global/AddManageDataForm/AddManageDataForm.component';
import { candidateParamterdValidation } from '../../../components/Global/AddManageDataForm/CandidateParameterValidation';
import HomeComponent from '../../../components/Global/HomeLayout/HomeLayout.component';
import { addCandidateParameter, fetchCandidateParameter, updateCandidateParameter } from '../../../redux/AptillioAdmin/candidateParameter/candidateParameter.actions';

const AddEditCandidateParameterPage = () => {
    const location = useLocation()
    const [paramName, setCandidateParameter] = useState("")
    const [status, setBPStatus] = useState("")
    const [formConstants, setFormConstants] = useState(null)
    const [inputFields, setInputFields] = useState()
    const statusOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const apiActions={
        add:addCandidateParameter,
        fetch:fetchCandidateParameter,
        update:updateCandidateParameter,
    }
    useEffect(() => {
        const routeSplit = location?.pathname.split('/')
        if(routeSplit[routeSplit.length-1] ==="add") { 
            setFormConstants({
                title:"Candidate Parameter",
                formHeader:"Add Candidate Parameter",
                actionButton:"Add Candidate Parameter",
                confirmDialogue:"Are you sure to add this Candidate Parameter?",
                actionType: "add",
                routeBack: "/candidateParameter",
            })
        }else{
            setFormConstants({
                title:"Candidate Parameter",
                formHeader:"Update Candidate Parameter",
                actionButton:"Edit Candidate Parameter",
                confirmDialogue:"Are you sure to edit this Candidate Parameter?",
                actionType: "edit",
                routeBack: "/candidateParameter",
            })
        }
    }, [location?.pathname])


    useEffect(() => {
        setInputFields([
            { label:"Candidate Parameter", type:"text", placeholder:"Enter a parameter", key:"paramName", value:paramName, setValue:setCandidateParameter, required:true},
            { label:"Status", type:"select", placeholder:"Select", key:"status", value:status, setValue:setBPStatus,selectOptions: statusOptions},
        ])
    }, [paramName, status])
    
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<AddManageDataComponent inputFields={inputFields} formConstants={formConstants} apiAction={apiActions} inputValidation={candidateParamterdValidation}/>}/>
            </div>
        </div>
    );
};

export default AddEditCandidateParameterPage;
