import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SubmitCandidateComponent from '../../../components/Common/CandidateDetail/CandidateDetail.component';
import HomeComponent from '../../../components/Global/HomeLayout/HomeLayout.component';

const CandidateDetailPage = () => {
    const location = useLocation()
    const [actionType, setActionType] = useState("")

    useEffect(() => {
        const routeSplit = location?.pathname.split('/')
        if(routeSplit[routeSplit.length-1] ==="add") {
            setActionType("submit")
        }else{
            let routeAction=routeSplit[routeSplit.length-2];
            setActionType(routeAction)
        }
    }, [])
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<SubmitCandidateComponent actionType={actionType}/>}/>
            </div>
        </div>
    );
};

export default CandidateDetailPage;
