import React, { useState } from 'react';
// import { FaFilter, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { fetchInvoiceListing } from '../../../redux/Common/manageInvoice/manageInvoice.actions';
import { selectInvoiceListingData } from '../../../redux/Common/manageInvoice/manageInvoice.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
// import ButtonEvent from '../../Global/inputs/button.component';

const ManageInvoiceGroupComponent = ({}) => {
    // const navigateTo = useNavigate()
    const filtersData = useSelector(selectFilterData)
    const [firstname, setFirstNameFilter] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    
    const inputFilterList = [
        { text:"Filter By First Name", placeholder:"Enter First Name", key:"firstname", value:firstname, setValue:setFirstNameFilter},
    ]
    
    const dataTableColumns = [
        {
            dataField: 'orgName',
            text: 'Organization',
            sort: true,
            headerStyle: (colum, colIndex) => {
            }
        },
        {
            dataField: 'userEmail',
            text: 'Organization Email',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '22%'};
            }
        },
        {
            dataField: 'subscriptionName',
            text: 'Plan',
            headerStyle: (colum, colIndex) => {
                return { width: '12%'};
            }
        },
        {
            dataField: 'created_at',
            text: 'Payment Date',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '12%'};
            },
            formatter: (cell, row, rowIndex, formatExtraData)=>{
                return <>{row?.status?.split("")[0]?.toUpperCase() + row?.status?.substring(1,row?.status?.length)}</>
            }
        },
    ];

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>Manage Invoices</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-filter">
                                {/* <ButtonEvent customStyle={{margin:0}} func={()=>setIsDrawerOpen(true)} text={"Search/Filter"} icon={<FaFilter/>}></ButtonEvent> */}
                            </div>
                            <div className="dashboard_main-listing_header_actions-search">
                                {/* <ButtonEvent text={"Add Business Psychologist"} customStyle={{backgroundColor:"#5367FD", color:"white"}} func={()=>{navigateTo(`/manageBusinessPsychologist/add`)}} icon={<FaPlus/>}></ButtonEvent> */}
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={dataTableColumns} apiAction={fetchInvoiceListing} apiSelector={selectInvoiceListingData} filters={filtersData}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageInvoiceGroupComponent;