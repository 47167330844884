import React from 'react';
// import SmallLoader from '../../../components/loader/smallLoader';
import styles from './inputs.module.scss';

const ButtonEvent = ({max, text, customStyle, func, loading, icon, disabled, theme }) => {
    let style = {};
    if(customStyle){ style = {...style, ...customStyle}}
    if(max){style = {...style, maxWidth: max}}
    if(disabled){style = {...style, background: "grey"}}
    
    return(
        <div className={styles.input__container} onClick={e => e.stopPropagation()}>
            <button className={`${styles.input} ${theme==="secondary" ? styles.secondaryBtnTheme : styles.primaryBtnTheme}`} style={style} disabled={disabled} onClick={() => func && func()}>
                { icon && icon }
                {
                    loading ? text: text || ''
                }
            </button>
        </div>
    );
};

export default ButtonEvent;
