import { endpoints } from '../../../constants/url.endpoint';
import { manageHiringManagerActions } from './manageHiringManager.reducer';

const { hiringManagerListDataSuccess, resetHiringManagerAllData, resetHiringManagerDetail, hiringManagerDetailsSuccess } = manageHiringManagerActions;

export const fetchHiringManagerListing =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.hiringManagerList}/${apiData?.userId}`,
                method: 'GET',
                params:apiData
            }).then((res) => {
                dispatch(hiringManagerListDataSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetHiringManagerAllData());
                reject(err)
            });
    })
};

export const fetchHiringManagerDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosInstance({
                url: `${endpoints.hiringManager}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                dispatch(hiringManagerDetailsSuccess(res?.data));
                resolve(res?.data?.data?.data)
            }).catch(err => {
                dispatch(resetHiringManagerDetail());
                reject(err)
            });
    })
};

export const addHiringManagerDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.addHiringManager}`,
                method: 'POST',
                data:apiData
            }).then((res) => {
                dispatch(hiringManagerDetailsSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetHiringManagerDetail());
                reject(err)
            });
    })
};

export const updateHiringManagerDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.hiringManager}/${apiData?._id}`,
                method: 'PATCH',
                data:apiData
            }).then((res) => {
                dispatch(hiringManagerDetailsSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetHiringManagerDetail());
                reject(err)
            });
    })
};

export const fetchAvailableHiringManagerListing =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.hiringManagerShowOthers}/${apiData?._id}`,
                method: 'GET',
                params:apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const updateNewHiringManagerStatus =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.hiringManagerStatusUpdate}/${apiData?._id}`,
                method: 'PATCH',
                data:apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};