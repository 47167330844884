import React from "react";
import { useNavigate } from "react-router-dom";
import AddCandidateFeedbackComponent from "../../../components/BussinessPsychologist/AddCandidateFeedback/AddCandidateFeedback.component";
import ButtonEvent from "../../../components/Global/inputs/button.component";

const AddCandidateFeedbackPage = () => {
    const navigateTo = useNavigate()
    return (
        <div>
          <div className="interview-container">
            <div className="interview-wrapper">
                <div className="interview-logo">
                  <img src="/logo-one.png" className='interview-logo-image' alt="logo" />
                </div>
                <div className="assessmentFeedback_main-actions_btn">
                  <ButtonEvent theme={"secondary"} text={"Back To Dashboard"} func={()=>navigateTo('/')}/>
                </div>
            </div>
            <AddCandidateFeedbackComponent/>
            </div>
        </div>
    );
  }


export default AddCandidateFeedbackPage;