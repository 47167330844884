import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import './ManageReports.component.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
import { Doughnut } from 'react-chartjs-2';
import ButtonEvent from '../../Global/inputs/button.component';
import { FaFilter } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { fetchActiveOrgData, fetchOrgPerformanceData, fetchReportsData } from '../../../redux/AptillioAdmin/manageReports/manageReports.actions';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { DatePicker } from 'antd';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { activeUsersHeaders, assessmentCompletedHeaders, candidateAssignedHeaders, hiringManagersHeaders } from './manageReports.constants';
import { addToaster } from '../../../redux/appToaster/appToaster.actions';
const { RangePicker } = DatePicker;

ChartJS.register(
    ArcElement, Tooltip, Legend
)

const ManageReportsComponent = () => {
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance()
    const [dates, setDates] = useState(null);
    const [dateValue, setDateValue] = useState(null);
    const [csvData, setCsvData] = useState([])
    const [reportName, setReportName] = useState("")
    const [barChartData, setBarData] = useState([])
    const [activeUserValue, setActiveUsersValue] = useState({
        labels: ['Active', 'Inactive'],
        datasets: [{
            label: 'Count %',
            data: [0, 0],
            backgroundColor: ['black', 'red'],
            borderColor: ['black', 'red']
        }]
    })
    const csvLinkEl = React.createRef();
    const [csvHeaders, setHeaders] = useState([])

    useEffect(()=>{
        dispatch(fetchActiveOrgData(axiosInstance,{})).then(res=>{
            setActiveUsersValue(
                {
                    labels: ['Active', 'Inactive'],
                    datasets: [{
                        label: 'Count',
                        data: [parseInt(res?.data?.percentage), 100-parseInt(res?.data?.percentage)],
                        backgroundColor: ['#50AF95', 'red'],
                        borderColor: ['black', 'black']
                    }]
                }
            )
        })
        dispatch(fetchOrgPerformanceData(axiosInstance,{})).then(res=>{
            setBarData(res?.data?.result)
        })
    },[axiosInstance, dispatch]) 
    
    const [userData, setUserData] = useState(
        {
            labels: barChartData.map((data) => data.year),
            datasets: [],
        }
    );

    useEffect(()=>{
        setUserData(
            {
                labels: ["Q1", "Q2", "Q3", "Q4"],
                datasets: [
                  {
                    label: "Assessment Completed",
                    data: barChartData[0]?.quarters?.map((data, index) => {
                        if(["Q1", "Q2", "Q3", "Q4"][index] === data?.quarter.toUpperCase()){
                            return data?.count
                        }
                        return 0
                    }),
                    backgroundColor: [
                      "#50AF95",
                    ],
                    borderColor: "black",
                    borderWidth: 2,
                  },
                  {
                    label: "Online Test Pending",
                    // data: barChartData[1]?.quarters?.map((data) => data?.count),
                    data: barChartData[1]?.quarters?.map((data, index) => {
                        if(["Q1", "Q2", "Q3", "Q4"][index] === data?.quarter.toUpperCase()){
                            return data?.count
                        }
                        return 0
                    }),
                    backgroundColor: [
                      "#2a71d0",
                    ],
                    borderColor: "black",
                    borderWidth: 2,
                  },
                ],
            }
        )
    },[barChartData])
    
    const handleDownload=(type)=>{
        let apiData ={};
        if(dateValue) apiData = {startDate: moment(dateValue[0].$d).toISOString(), endDate: moment(dateValue[1].$d).toISOString()};
        dispatch(fetchReportsData(axiosInstance, type, apiData)).then(res=>{
            if(!res?.data?.length){
                dispatch(addToaster({id:0, title:"Error", description: "Could't find any relevant data for this date range.", icon:"error"}))
                return
            }
            switch (type){
                case "active_users":{
                    setCsvData(()=>{return res?.data?.map(x=>{
                        x.org_name = x?.org_name || x?.created_by?.org_name
                        return x
                    })})
                    setHeaders(activeUsersHeaders)
                    setReportName("aptillio-active-users-report.csv")
                    return
                }
                case "hiring_managers":{
                    setCsvData(res?.data)
                    setHeaders(hiringManagersHeaders)
                    setReportName("aptillio-hiring-managers-report.csv")
                    return
                }
                case "candidate_assigned":{
                    setCsvData(res?.data)
                    setHeaders(candidateAssignedHeaders)
                    setReportName("aptillio-assigned-candidates-report.csv")
                    return
                }
                case "assessment_completed":{
                    setCsvData(res?.data)
                    setHeaders(assessmentCompletedHeaders)
                    setReportName("aptillio-assessment-completed-report.csv")
                    return
                }
                default :{
                    return
                }
            }
        }).catch(err=> dispatch(addToaster({id:0, title:"Error", description: err?.response?.data?.message || "Error while generating report.", icon:"error"})))
    }

    useEffect(()=>{
        if(csvData?.length>0 && csvHeaders){
            setTimeout(() => {
                csvLinkEl?.current?.link?.click();
                setHeaders("")
            });
        }
    },[csvData, csvHeaders])

    const disabledDate = (current) => {
        if (!dates) {
          return false;
        }
        // if(dates[0]){
        //     console.log(current)
        //     console.log(dates[0].diff(moment(new Date()), 'months'))
        // }
        const tooLate = dates[0] ? dates[0].diff(moment(new Date()), 'months') <= -12 ? current.diff(dates[0], 'years') > 0 : current.isAfter(new Date()) : current.isAfter(new Date());
        const tooEarly = dates[1] && dates[1].diff(current, 'years') > 0;
        return !!tooEarly || !!tooLate;
      };
    

    const onOpenChange = (open) => {
        if (open) {
          setDates([null, null]);
        } else {
          setDates(null);
        }
    };
    
    return (
        <div className="reports_container">
            <div className="reports_wrapper">
                <div className="reports_main">
                    <div className="reports_main-header_container">
                        <div className="reports_main-listing_header">
                            <p className='reports_main-listing_header_text'><strong>Manage Reports</strong></p>
                        </div>
                    </div>
                    <div className="reports_main-charts">
                        <div className="reports_main-charts_doughnut">
                            <Doughnut
                                data={activeUserValue}
                            />
                            <div className='reports_main-charts_doughnut-header'>
                                <h3>Active Subscribed User</h3>
                            </div>
                        </div>
                        <div className="reports_main-charts_bar">
                            <Bar data={userData} />
                            <div className='reports_main-charts_bar-header'>
                                <h3>Organization Performance</h3>
                            </div>
                        </div>
                    </div>
                    <div className="reports_main-generateReports">
                        <div className="reports_main-generateReports_header">
                            <h3>Select Range to view reports</h3>
                            <div className="generateReport-dateRange">
                            <RangePicker
                                format={"DD-MM-YYYY"}
                                value={dates || dateValue}
                                bordered
                                autoFocus
                                disabledDate={disabledDate}
                                onCalendarChange={(val) => setDates(val)}
                                onChange={(val) => setDateValue(val)}
                                onOpenChange={onOpenChange}
                            />
                            </div>
                        </div>
                        <div className="reports_main-generateReports_generate">
                            <div className="generate_reports">
                                <div className="generate_reports-label">
                                    <label>No. of Active Users:</label>
                                </div>
                                <div className="generate_reports-btn">
                                    <ButtonEvent customStyle={{margin:0}} func={()=>handleDownload("active_users")} text={"Download Reports"} icon={<FaFilter/>}></ButtonEvent>
                                </div>
                            </div>
                            <div className="generate_reports">
                                <div className="generate_reports-label">
                                    <label>No. of Hiring Managers:</label>
                                </div>
                                <div className="generate_reports-btn">
                                    <ButtonEvent customStyle={{margin:0}} func={()=>handleDownload("hiring_managers")} text={"Download Reports"} icon={<FaFilter/>}></ButtonEvent>
                                </div>
                            </div>
                            <div className="generate_reports">
                                <div className="generate_reports-label">
                                    <label>Candidate Assigned:</label>
                                </div>
                                <div className="generate_reports-btn">
                                    <ButtonEvent customStyle={{margin:0}} func={()=>handleDownload("candidate_assigned")} text={"Download Reports"} icon={<FaFilter/>}></ButtonEvent>
                                </div>
                            </div>
                            <div className="generate_reports">
                                <div className="generate_reports-label">
                                    <label>Candidate Assessment Completed:</label>
                                </div>
                                <div className="generate_reports-btn">
                                    <ButtonEvent customStyle={{margin:0}} func={()=>handleDownload("assessment_completed")} text={"Download Reports"} icon={<FaFilter/>}></ButtonEvent>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CSVLink
                        headers={csvHeaders}
                        filename={reportName}
                        data={csvData}
                        ref={csvLinkEl}
                    />
                </div>
            </div>
        </div>
    )
}

export default ManageReportsComponent;