import React, { useEffect, useState } from 'react';
import './Notification.component.scss';
import { useDispatch } from 'react-redux';
import { fetchAppNotification } from '../../../redux/Common/manageNotification/manageNotification.actions';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import InfiniteScroll from 'react-infinite-scroll-component';
import notificationLoader from '../../../assets/gif/loader.gif';

const NotificationMenuComponent = ({setNotificationCount}) => {
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [notifications, setNotifications] = useState(null)
    const [count , setCount] = useState(1)
    const [isLoading , setLoading] = useState(true)
    const [hasMore , setHasMore] = useState(false)

    useEffect(()=>{
        dispatch(fetchAppNotification(axiosInstance,{ page:1, limit:10*count})).then(res=>{
            setNotifications(res?.data?.data?.notifications)
            setNotificationCount(0)
            setHasMore(res?.data?.meta?.total - res?.data?.data?.notifications?.length > 0)
            setLoading(false)
        }).catch(err=>{
            setNotifications([])
            setLoading(false)
        })
    },[count])
    
    return(
        <div className="notification-container">
            <div id="scrollableDiv" className='notification-wrapper'>
                <div className="notification_header">
                    <div className="notification_header-text">
                        Notification
                    </div>
                    <div className="notification_header-mark">
                        {/* <div className="notification_header-mark_icon"><BiCheckDouble/></div> */}
                        {/* <div className="notification_header-mark_text">Mark as read</div> */}
                        
                    </div>
                </div>
                <div className='notification_list'>
                    {
                        isLoading ?
                            <div className='notification'>
                                <div className='notification-loader'>
                                        <img src={notificationLoader} alt="aptillio-loader"/>
                                </div>
                            </div>
                        :
                        <>
                            {(notifications && !notifications?.length) &&
                                <div className='notification'>
                                    <div className='notification-content'>
                                        <div className='notification-content_header'>
                                            <h4>Don't have any notification to show at the moment.</h4>
                                        </div>
                                    </div>
                                </div>
                            }
                            {notifications && notifications.length ? <InfiniteScroll
                                dataLength={notifications?.length} //This is important field to render the next data
                                next={()=>setCount(count+1)}
                                hasMore={hasMore}
                                // loader={<h4>Loading...</h4>}
                                endMessage={
                                    count > 1 && <p style={{ textAlign: 'center', fontSize:"12px" }}>
                                        <p>Yay! You have seen it all</p>
                                    </p>
                                }
                                style={{overflow:"none"}}
                                scrollableTarget="scrollableDiv">
                                {
                                    notifications?.map(notification=> {
                                        return(
                                            
                                        <div key={notification?._id} className='notification'>
                                            {/* <div className='notification-icon'> */}
                                                {/* <input type="radio"></input> */}
                                            {/* </div> */}
                                            <div className='notification-content'>
                                                <div className='notification-content_header'>
                                                    <h4>{notification?.title}</h4>
                                                </div>
                                                <div className='notification-content_text'>
                                                    {notification?.message}
                                                </div>
                                                <div className='notification-content_detail'>
                                                    <div className='notification-content_detail-time'>
                                                        {new Date(notification?.created_at).toLocaleString('en-us',{year: 'numeric', month: 'short', day: 'numeric', hour:"numeric", minute:"numeric"})}
                                                    </div>
                                                    <div className='notification-content_detail-actions'>
                                                        {/* <div className='notification-content_detail-actions_eye'> */}
                                                            {/* <AiOutlineEye/> */}
                                                        {/* </div> */}
                                                        {/* <div className='notification-content_detail-actions_close'> */}
                                                            {/* <IoClose/> */}
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </InfiniteScroll> : ""
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default NotificationMenuComponent;