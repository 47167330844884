import React, { useEffect, useState } from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteJobRoleDetail, fetchJobRolesListing } from '../../../redux/Common/manageJobRole/manageJobRole.actions';
import { selectJobRoleListingData } from '../../../redux/Common/manageJobRole/manageJobRole.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import { selectLoaderData } from '../../../redux/progressLoader/progressLoader.selectors';
import AptillioProgressLoaderComponent from '../../Global/AptillioProgressLoader/AptillioProgressLoader.component';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';
import { MANAGE_JOB_ROLES_HEADING } from './ManageJobRole.constants';
import ReactTooltip from 'react-tooltip';

const ManageJobRoleComponent = () => {
    const navigateTo = useNavigate()
    const loader = useSelector(selectLoaderData);
    const filtersData = useSelector(selectFilterData)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [label, setJobTitle] = useState("")
    // const [email, setEmailFilter] = useState("")
    const inputFilterList = [
        { text:"Filter By Job Title", placeholder:"Job Title", key:"label", value:label, setValue:setJobTitle},
        // { text:"Filter By ", placeholder:"Email", key:"email", value:email, setValue:setEmailFilter},
    ]
    
    const organisationTableColumns = [
        {
            dataField: 'label',
            text: 'Job Title',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '30%'};
            },
        },
        {
            dataField: 'jobDescription',
            text: 'Job Description',
            formatter: (cell,row, rowIndex, formatExtraData)=>{
                return (<>
                    <span data-tip={row?.jobDescription}>{`${row.jobDescription.length>100 ? `${row.jobDescription.substring(0,50)}........` : row.jobDescription}`}</span>
                    <ReactTooltip place='right' className={"job_description-tooltip"}/>
                </>)
            }
        },
    ];

    // if(loader){ 
    //     return(
    //             <AptillioProgressLoaderComponent/>
    //         )
    // }
    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>{MANAGE_JOB_ROLES_HEADING}</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            {/* <div className="dashboard_main-listing_header_actions-filter"> */}
                                {/* <ButtonEvent func={()=>setIsDrawerOpen(true)} text={"Apply Filter"} icon={<FaFilter/>}></ButtonEvent> */}
                            {/* </div> */}
                            <div className="dashboard_main-listing_header_actions-search">
                                <ButtonEvent text={"Add Job Role"} customStyle={{backgroundColor:"#5367FD", color:"white"}} func={()=>{navigateTo(`/manageJobRole/add`)}} icon={<FaPlus/>}></ButtonEvent>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={organisationTableColumns} apiAction={fetchJobRolesListing} apiSelector={selectJobRoleListingData} filters={filtersData}
                        deleteAction={deleteJobRoleDetail}></TableComponent>
                    </div>
                </div>
            </div>
            {/* <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/> */}
        </div>
    )
}

export default ManageJobRoleComponent;