export const addSubscriptionFieldValidation = ({ subscriptionName, subscriptionCost, tokenValue , tokenPerSubmission, expiryTenure, description, actionType}) => {
    const errors = {};
    
    if (!subscriptionName) {
      errors.subscriptionName = "Bundle Name can't be empty!";
    }
    if (!subscriptionCost) {
      errors.subscriptionCost = "Bundle Cost can't be empty";
    }
    if (!tokenValue) {
      errors.tokenValue = "Token can't be empty";
    }
    if (!expiryTenure) {
      errors.expiryTenure = "Expiring Tenure can't be empty";
    }
    if (!tokenPerSubmission) {
      errors.tokenPerSubmission = "Token Submission can't be empty";
    }
    if (!description) {
      errors.description = "Description can't be empty";
    }
    return errors;
};