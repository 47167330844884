import React, { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import useAuth from '../../../hooks/useAuth.hook';
import { fetchCandidatesListing, updateCandidateDetail } from '../../../redux/Common/manageCandidates/manageCandidates.actions';
import { selectCandidateListingData } from '../../../redux/Common/manageCandidates/manageCandidates.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';
import { MANAGE_RESUMES_HEADING } from './ManageResume.constants';

const ManageResumeComponent = () => {
    const { getUserAuth } = useAuth()
    const filtersData = useSelector(selectFilterData)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [userType, setUserType] = useState("")
    const [firstname, setFirstNameFilter] = useState("")
    const [lastname, setLastNameFilter] = useState("")
    const [email, setEmailFilter] = useState("")

    const inputFilterList = [
        { text:"Filter By First Name", placeholder:"Enter First Name", key:"firstname", value:firstname, setValue:setFirstNameFilter},
        { text:"Filter By Last Name", placeholder:"Enter Last Name", key:"lastname", value:lastname, setValue:setLastNameFilter},
        { text:"Filter By Email", placeholder:"Enter Email", key:"email", value:email, setValue:setEmailFilter},
    ]
    
    const organisationTableColumns = [
        {
            dataField: 'firstname',
            text: 'First Name',
            sort: true
        },
        {
            dataField: 'lastname',
            text: 'Last Name',
        },
        {
            dataField: 'email',
            text: 'Candidate Email',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '20%'};
            }
        },
    ];

    useEffect(()=>{
        let user= getUserAuth()
        setUserType(user.userType)
    },[getUserAuth])

  
    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>
                                {MANAGE_RESUMES_HEADING}
                            </p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-filter">
                                <ButtonEvent func={()=>setIsDrawerOpen(true)} text={userType!=="org-admin" ? "Search/Filter":""} icon={<FaFilter/>}></ButtonEvent>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={organisationTableColumns} apiAction={fetchCandidatesListing} apiSelector={selectCandidateListingData} filters={filtersData}
                        dispatchActions={{updateCandidateAssessment:updateCandidateDetail}}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageResumeComponent;