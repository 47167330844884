import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { fetchOrganisation, updateOrganisation } from '../../../redux/AptillioAdmin/manageOrganisation/manageOrganisation.actions';
import AptillioToaster from '../../Global/AptillioToaster/AptillioToaster.component';
import ButtonEvent from '../../Global/inputs/button.component';
import InputEvent from '../../Global/inputs/input.component';
import './EditOrganisationDetails.component.scss';
import { updateOrgValidation } from './EditOrganisationValidation';
import { AiOutlineArrowLeft } from "react-icons/ai";
import AlertDialog from '../../Global/AlertDialog/AlertDialog.component';
import SelectBox from '../../Global/inputs/select.component';

const ViewOrganisationComponent = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigateTo = useNavigate()
    const axiosInstance = useAxiosInstance()
    const [email, setEmail] = useState("")
    const [org_name, setOrgName] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [orgStatus, setOrgStatus] = useState("")
    const [toastList, setToastList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [organisationData, setOrganisationData] = useState(null);
    const [confirmDialog, setConfirmDiaogue] = useState({ openAlert:false, alertMessage:""});
    const [confirmAction, setConfirmAction] = useState(false);

    const orgStatusOptions = [
        // { value: '', label: 'Select' },
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]

    useEffect(() => {
        const routeSplit = location?.pathname.split('/')
        const orgId = routeSplit[routeSplit.length-1]
        dispatch(fetchOrganisation(axiosInstance,{id: orgId})).then((res)=>{
            setOrganisationData(res.data.data.data)
            setEmail(res.data.data.data.email)
            setOrgName(res.data.data.data.org_name)
            setContactNo(res.data.data.data.contactNo)
            setOrgStatus(res.data.data.data.status)
        }).catch((err)=>{
            setOrganisationData(null)
            setToastList([{id:0, title:"Fetch Error", description: err?.response?.data?.message, icon:"error"}])
        })
    }, [])

    const handleUpdateOrg = () => {
        setConfirmDiaogue({ openAlert:true, alertMessage:`Are you sure to edit organization details?`})
    };

    useEffect(()=>{
        if(confirmAction===true){
            const modified_by = localStorage.getItem("userId")
            dispatch(updateOrganisation(axiosInstance,{...organisationData, email, contactNo, org_name, status:orgStatus, modified_by})).then(res=>{
                setToastList([{id:0, title:"Organization Upated", description: "organization data has been updated successfully.", icon:"success"}])
                setConfirmDiaogue({ openAlert:false, alertMessage:""});
                setTimeout(()=>{
                    navigateTo('/')
                },1500)
            }).catch(err=>{
                setConfirmDiaogue({ openAlert:false, alertMessage:""});
                setConfirmAction(false)
                setToastList([{id:0, title:"Update Error", description: err?.response?.data?.message || "Error while upating.", icon:"error"}])
            });
        }
    },[confirmAction])

    const handleSubmit = (e) => {
        e?.preventDefault();
        setFormErrors(updateOrgValidation({...organisationData,email, contactNo, org_name, status:orgStatus}));
        setIsSubmit(true);
    };

    useEffect(()=>{
        return()=>{
            clearTimeout()
            setConfirmAction(false)
        }
    },[])

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleUpdateOrg()
        }
    }, [formErrors]);
    

    // if(loader){ 
    //     return(
    //             <AptillioProgressLoaderComponent/>
    //         )
    // }
    return (
        <div className="view_org-container">
            <div className="view_org-wrapper">
                <div className="view_org_main">
                    <div className="view_org_main-header_container">
                        <div className="view_org_main-listing_header">
                            <p className='view_org_main-listing_header_text'>Update Organization Admin</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="view_org_main-inputs">
                            <div className="view_org_main-inputs_wrapper" style={{justifyContent:"flex-end"}}>
                                    <label className='org_main-input_label'><span style={{fontSize:"1.5rem", color: "red"}}>*</span> Required Fields</label>
                            </div>
                            <div className="view_org_main-inputs_wrapper org_name-input">
                                <div className="org_main-label">
                                    <label className='org_main-input_label'>Organization Name <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="org_main-input">
                                    <InputEvent nolabel={true} value={org_name} setValue={setOrgName} placeholder={"Enter Organization Name"}/>
                                    {
                                        formErrors.org_name && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.org_name}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="view_org_main-inputs_wrapper org_name-input">
                                <div className="org_main-label">
                                    <label className='org_main-input_label'> Phone Number <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="org_main-input">
                                    <InputEvent nolabel={true} value={contactNo} setValue={setContactNo} placeholder={"Enter 10 Digit Phone Number"}/>
                                    {
                                        formErrors.contactNo && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.contactNo}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="view_org_main-inputs_wrapper org_name-input">
                                <div className="org_main-label">
                                    <label className='org_main-input_label'> Status</label>
                                </div>
                                <div className="org_main-input">
                                    {
                                        organisationData?.status ==="pending" ?
                                        <InputEvent nolabel={true} value={orgStatus} setValue={setOrgStatus} disabled/> :
                                        <SelectBox value={orgStatus} setValue={setOrgStatus} options={orgStatusOptions}/>
                                    }
                                    {
                                        formErrors.status && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.status}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="view_org_main-inputs_wrapper org_name-input">
                                <div className="org_main-label">
                                    <label className='org_main-input_label'> Organization Admin Email</label>
                                </div>
                                <div className="org_main-input">
                                    <InputEvent nolabel={true} value={email} setValue={setEmail} disabled/>
                                    {
                                        formErrors.email && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.email}</p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="view_org_main-action">
                            <div className="view_org_main-action_button">
                            {
                                organisationData && organisationData?.status !== "pending" &&
                                <ButtonEvent text={"Save"} func={handleSubmit}/>
                            }
                            </div>
                            <div className="view_org_main-action_button">
                                <ButtonEvent text={"Back"} customStyle={{backgroundColor:"#5165F8", color:"white"}} 
                                icon={<AiOutlineArrowLeft/>} func={()=>navigateTo('/')}/>
                            </div>
                        </div>
                    </form>
                </div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
                <AlertDialog 
                openAlert={confirmDialog?.openAlert}
                alertMessage={confirmDialog.alertMessage}
                setConfirmDiaogue = {setConfirmDiaogue}
                setConfirmAction={setConfirmAction}/>
            </div>
        </div>
    )
}

export default ViewOrganisationComponent