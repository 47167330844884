import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    listing: [],
    data:null
};
const manageCandidateSlice = createSlice({
  name: 'manageCandidate',
  initialState,
  reducers: {
    candidateListDataSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;

    },
    candidateDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetCandidateDetail: (state, action) => {
      state.data = null;
    },
    resetManageCandidateAllData: (state, action) => {
      state={
        listing: [],
        data:null
      };
    },
  }
});

export const {
    actions: manageCandidatesActions,
    reducer: manageCandidatesReducer,
    name: manageCandidate
} = manageCandidateSlice;

