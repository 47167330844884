import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    listing: [],
    data:null
};
const candidateParameterSlice = createSlice({
  name: 'candidateParameter',
  initialState,
  reducers: {
    candidateParameterListingSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;

    },
    viewCandidateParameterSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetCandidateParameterDetail: (state, action) => {
      state.data = null;
    },
    resetCandidateParameterAllData: (state, action) => {
      state={
        listing: [],
        data:null
      };
    },
  }
});

export const {
    actions: candidateParameterActions,
    reducer: candidateParameterReducer,
    name: candidateParameter
} = candidateParameterSlice;

