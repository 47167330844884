import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';
import { addToaster } from '../../../../redux/appToaster/appToaster.actions';
import InnerListingComponent from '../../../Global/InnerListing/InnerListing.component';
import './AssessmentListing.component.scss'
import { fetchAssessmentScore } from "../../../../redux/CompleteAssessment/CompleteAssessment.actions";
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const AssessmentListingComponent = ({listData}) => {
    const assessmentActionRef = useRef()
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const navigateTo = useNavigate()
    const [selectedRowIndex, setSelectedRowIndex] = useState("")

    const assessmentTimelineColumns = [
        {
            dataField: 'assessmentStatusId.statusName',
            text: 'Assessment Status',
        },
        {
            dataField: 'created_at',
            text: 'Date',
            headerStyle: (colum, colIndex) => {
                return { width: '25%'};
            }
        },
    ];

    const assessmentStatusColumns = [
        {
            dataField: 'assessmentStatusId.statusName',
            text: 'Assessment Status',
        },
        {
            dataField: 'actions',
            text: 'Action',
            headerStyle: (colum, colIndex) => {
                return { width: '20%', textAlign: 'center'};
            },
            formatExtraData: selectedRowIndex,
            formatter: (cell,row, rowIndex, formatExtraData)=>{
                return <span key={rowIndex} className='table_action' style={{textAlign:"center"}}>
                        <div className="table_action-icon">
                            <img alt='table-action' onClick={(e)=>{setSelectedRowIndex(row?.assessmentStatusId?._id || row?.value); e.stopPropagation()}} className='action-icon' src="/icons/table-action-icon.svg"></img>
                        </div>
                        {
                            formatExtraData === ((row?.assessmentStatusId?._id || row?.value)) ? 
                            <div className="table_action-popup" ref={assessmentActionRef}>
                            {   !row?.assessmentStatusId?.statusName?.toLowerCase().includes("test pending") &&
                                <div className="table_action-popup_wrapper">
                                    <div className="action_popup">
                                        <div className="action_popup-icon"><MdOutlineSpaceDashboard/></div>
                                        <div className="action_popup-text" onClick={()=>viewTestScore(listData)}><p className='action_popup-text'>View Test Score</p></div>
                                    </div>
                                    {(row?.assessmentStatusId?.statusName?.toLowerCase().includes("interview completed") || row?.assessmentStatusId?.statusName?.toLowerCase().includes("assessment completed")) && <div className="action_popup">
                                        <div className="action_popup-icon"><MdOutlineSpaceDashboard/></div>
                                        <div className="action_popup-text" onClick={()=>navigateTo(`/assessment/feedback/${listData?._id}`)}><p className='action_popup-text'>View Feedback</p></div>
                                    </div>}
                                </div>
                            }
                            </div> : ""
                        }
            </span>
        }
        },
    ];

    const viewTestScore=(candidateData)=>{
        dispatch(fetchAssessmentScore(axiosInstance, {id:candidateData?._id})).then(res=>{
            window.open(res?.data?.resultUrl,'_blank')
        }).catch(err=>{
            dispatch(addToaster({id:0, title:"Fetch Error", description: err?.response?.data?.message || "Error while generating assessment score.", icon:"error"}))
        })
    }

    useEffect(()=>{
        //for listening to on-click event for table actions
        function handler(event) {
            if(!assessmentActionRef.current?.contains(event.target)) {
                setSelectedRowIndex("")
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    },[])

    return (
        <div className="assessment_listing">
            <div className="assessment_listing-header">
                Assessment Timelines:
            </div>
            <div className="assessment_listing-table">
                <InnerListingComponent columns={assessmentTimelineColumns} tableData={listData?.assessmentTimelines}/>
            </div>
            <div className="assessment_listing-header">
                Assessment Progress:
            </div>
            <div className="assessment_listing-table">
                <InnerListingComponent columns={assessmentStatusColumns} tableData={[{...listData?.assessmentTimelines[listData?.assessmentTimelines?.length-1]}]}/>
            </div>
        </div>
    )
}

export default AssessmentListingComponent;