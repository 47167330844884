export const updateOrgValidation = ({ email, contactNo, org_name, status}) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneNumberRegex = new RegExp(/^[0-9]{10}$/i);

    if (!email) {
      errors.email = "Email can't be empty!";
    } else if (!regex.test(email.trim())) {
      errors.email = "This is not a valid email format.";
    }
    if (!contactNo) {
      errors.contactNo = "Phone Number can't be empty";
    }else if(contactNo.length !==10){
        errors.contactNo = "Phone Number must be of 10 numbers only.";
    }else if(!phoneNumberRegex.test(contactNo)){
        errors.contactNo = "Phone Number is invalid.";
    }

    if (!org_name) {
      errors.org_name = "Organization Name can't be empty.";
    }
    if (!status) {
      errors.status = "Status can't be empty.";
    }
    return errors;
};