export const activeUsersHeaders = [
    { label: "Organization Name", key: "org_name" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "User Type", key: "userTypeId.name" },
    { label: "Email", key: "email" },
    { label: "Contact Number", key: "contactNo" },
    { label: "Created Date", key: "created_at" },
];

export const hiringManagersHeaders = [
    { label: "Organization Name", key: "created_by.org_name" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "Contact Number", key: "contactNo" },
    { label: "Designation", key: "designation" },
    { label: "Created", key: "created_at" },
];

export const candidateAssignedHeaders = [
    // { label: "Organization Name", key: "org_name" },
    { label: "Hiring Manager", key: "created_by.firstname" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "Contact Number", key: "contactNo" },
    { label: "Assigned BP", key: "bpAssign.firstname" },
    // { label: "Assessment Scheduled", key: "assessmentScheduleId" },
    // { label: "Assessment Expiry", key: "assessmentExpiryDate" },
    { label: "Created", key: "created_at" },
    { label: "Status", key: "status" },
];

export const assessmentCompletedHeaders = [
    // { label: "Organization Name", key: "org_name" },
    { label: "Hiring Manager", key: "created_by.firstname" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "Contact Number", key: "contactNo" },
    { label: "Assigned BP", key: "bpAssign.firstname" },
    // { label: "Assessment Scheduled", key: "assessmentScheduleId" },
    // { label: "Assessment Expiry", key: "assessmentExpiryDate" },
    { label: "Created", key: "created_at" },
];