import { endpoints } from '../../../constants/url.endpoint';

export const fetchAppNotification =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints?.notifications}/${apiData?.userId}`,
                method: 'GET',
                params:apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchNotificationCount =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints?.notificationCount}/${apiData?.userId}`,
                method: 'GET',
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchNotificationPreferences =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints?.notificationPreferences}/${apiData?.userId}`,
                method: 'GET',
                // params:apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const updateNotificationPreferences =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints?.editNotificationPreference}/${apiData?.id}`,
                method: 'PATCH',
                data:apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};