import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'

import { postsReducer } from './posts/posts.reducer';
import { userReducer } from './users/user.reducer';
import { loaderReducer } from './progressLoader/progressLoader.reducer';
import { filterReducer } from './Common/tablefilter/tableFilter.reducer';
import { manageOrganisationReducer } from './AptillioAdmin/manageOrganisation/manageOrganisation.reducer';
import { manageHiringManagerReducer } from './Common/manageHiringManager/manageHiringManager.reducer';
import { toasterReducer } from './appToaster/appToaster.reducer';
import { manageCandidatesReducer } from './Common/manageCandidates/manageCandidates.reducer';
import { manageJobRoleReducer } from './Common/manageJobRole/manageJobRole.reducer';
import { myAccountReducer } from './Common/myAccount/myAccount.reducer';
import { manageBusinessPsychologistReducer } from './AptillioAdmin/manageBusinessPsychologist/manageBusinessPsychologist.reducer';
import { manageSubscriptionReducer } from './AptillioAdmin/manageSubscription/manageSubscription.reducer';
import { candidateParameterReducer } from './AptillioAdmin/candidateParameter/candidateParameter.reducer';
import { manageInvoiceReducer } from './Common/manageInvoice/manageInvoice.reducer';
  
// const rootPersistConfig = {
  // key: 'root',
  // storage: storageSession
// }

const userPersistConfig = {
    key: 'user',
    storage: storageSession,
}

const reducers = {
    user: persistReducer(userPersistConfig, userReducer),
    posts: postsReducer,
    loader: loaderReducer,
    tableFilters: filterReducer,
    manageOrganisation: manageOrganisationReducer,
    manageHiringManager: manageHiringManagerReducer,
    toaster: toasterReducer,
    manageCandidates: manageCandidatesReducer,
    manageJobRole: manageJobRoleReducer,
    myAccount: myAccountReducer,
    manageBusinessPsychologist: manageBusinessPsychologistReducer,
    manageSubscription: manageSubscriptionReducer,
    candidateParameter: candidateParameterReducer,
    manageInvoice: manageInvoiceReducer

};

const reducer = combineReducers(reducers);
// const persistedReducer = persistReducer(rootPersistConfig, reducer);


export const store = configureStore({
    reducer: reducer,
    // reducer: persistReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store);
