import { toasterActions } from './appToaster.reducer';

const { addToasterData, resetToasterList } = toasterActions;

export const addToaster =(toasterObject) => async (dispatch) => {
    dispatch(addToasterData(toasterObject))
};

export const resetToasters =() => async (dispatch) => {
    dispatch(resetToasterList())
};
