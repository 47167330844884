export const submitFeedbackValidation = ({ratingId, videoUrl, summary}) => {
    const errors = {};

    if (!summary) {
      errors.summary = "Please submit the video trascription.";
    } 
    if (!videoUrl) {
      errors.videoUrl = "Please submit a video summary.";
    }
    if (!ratingId) {
      errors.ratingId = "Please submit a rating.";
    }
    return errors;
};