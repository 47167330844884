import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    listing: [],
    data:null
};
const manageJobRoleSlice = createSlice({
  name: 'manageJobRole',
  initialState,
  reducers: {
    jobRoleListDataSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;

    },
    jobRoleDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetJobRoleDetail: (state, action) => {
      state.data = null;
    },
    resetManageJobRoleAllData: (state, action) => {
      state={
        listing: [],
        data:null
      };
    },
  }
});

export const {
    actions: manageJobRoleActions,
    reducer: manageJobRoleReducer,
    name: manageJobRole
} = manageJobRoleSlice;

