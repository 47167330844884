import { Route, Routes } from 'react-router-dom';
import { routeItems } from './routing/routes';
import React, { Suspense } from 'react';
import LoginPage from './pages/Login/Login.page';
import PrivateRoute from './routing/privateRoute';
import ManageOrganisationPage from './pages/manageOrganisation/manageOrganisation.page';
import ManageHiringManagerPage from './pages/manageHiringManager/manageHiringManager.page';
import ManageBusinessPsychologistPage from './pages/manageBusinessPsychologist/manageBusinessPsychologist.page';
import ForgetPasswordPage from './pages/forgetPassword/forgetPassword.page';
import ResetPasswordPage from './pages/resetPassword/resetPassword.page';
import SignupPage from './pages/organization/signup/signup.page';
import ViewOrganisationPage from './pages/manageOrganisation/edit/editOrganisationDetails.page';
import VerifyOrgSignupPage from './pages/organization/signup/verify/verifyOrganization.page';
import ManageCandidatePage from './pages/manageCandidate/manageCandidate.page';
import AddHiringManagerPage from './pages/manageHiringManager/add/addHiringManager.page';
import ManageJobRolePage from './pages/manageJobRole/manageJobRole.page';
import AddJobRolePage from './pages/manageJobRole/add/addJobRole.page';
import CandidateDetailPage from './pages/manageCandidate/submit/candidateDetail.page';
import MyAccountPage from './pages/myAccount/myAccount.page';
import AddEditBusinessPsychologistPage from './pages/manageBusinessPsychologist/add/addEditBusinessPsychologist.page';
import ManageSubscriptionPage from './pages/manageSubscription/manageSubscription.page';
import AddEditSubscriptionPage from './pages/manageSubscription/add/addEditSubscription.page';
import SubscriptionPlansPage from './pages/subscriptionPlans/subscriptionPlans.page';
import CandidateParameterPage from './pages/candidateParameter/candidateParameter.page';
import AddEditCandidateParameterPage from './pages/candidateParameter/add/addEditCandidateParameter.page';
import ProductFeaturePage from './pages/productFeature/productFeature.page';
import StripePaymentComponent from './components/Widget/stripePayment/stripePayment.component';
import VideoInterviewComponent from './components/Common/Interview/VideoInterview.component';
import ManageInvoicePage from './pages/manageInvoices/manageInvoices.page';
import ManageInvoiceGroupPage from './pages/manageInvoicesByGroup/manageInvoicesByGroup.page';
import ManageResumePage from './pages/manageResume/manageResume.page';
import AssessmentFeedbackPage from './pages/AssessmentFeedback/AssessmentFeedback.page';
import CheckAssessmentStatusPage from './pages/CheckAssessmentStatus/CheckAssessmentStatus.page';
import AddCandidateFeedbackPage from './pages/AssessmentFeedback/add/AddAssessmentFeedback.page';
import ManageReportsPage from './pages/ManageReports/manageReports.page';
import ManageNotificationPage from './pages/manageNotifications/manageNotification.page';
const BookSlotPage = React.lazy(() => import('./pages/bookCandidateSlot/bookCandidateSlot.page'));
const CalendlyBookingListingPage = React.lazy(() => import('./pages/manageCalenderListing/manageCalenderListing.page'));
const CompleteAssessmentPage = React.lazy(() => import('./pages/CompleteAssessment/CompleteAssessment.page'));

const PageList = {
    LoginPage: <LoginPage />,
    ForgetPassword: <ForgetPasswordPage/>,
    ResetPassword: <ResetPasswordPage/>,
    ManageOrganisationPage: <ManageOrganisationPage />,
    ViewOrganisationDetails: <ViewOrganisationPage/>,
    ManageHiringManager: <ManageHiringManagerPage/>,
    AddHiringManager: <AddHiringManagerPage/>,
    EditHiringManager: <AddHiringManagerPage/>,
    ManageBusinessPsychologist: <ManageBusinessPsychologistPage/>,
    AddBusinessPsychologist: <AddEditBusinessPsychologistPage/>,
    EditBusinessPsychologist: <AddEditBusinessPsychologistPage/>,
    OrganizationAdminSignup: <SignupPage />,
    VerifyOrganizationSignup: <VerifyOrgSignupPage/>,
    ManageCandidate: <ManageCandidatePage/>,
    SubmitCandidate: <CandidateDetailPage/>,
    EditDraftCandidate: <CandidateDetailPage/>,
    ViewCandidate: <CandidateDetailPage/>,
    ManageJobRole: <ManageJobRolePage/>,
    AddJobRole: <AddJobRolePage/>,
    EditJobRole: <AddJobRolePage/>,
    ManageSubscription: <ManageSubscriptionPage/>,
    AddSubscription: <AddEditSubscriptionPage/>,
    EditSubscription: <AddEditSubscriptionPage/>,
    SubscriptionPlans: <SubscriptionPlansPage/>,
    MyAccount: <MyAccountPage/>,
    CandidateParameter: <CandidateParameterPage/>,
    AddCandidateParameter: <AddEditCandidateParameterPage/>,
    EditCandidateParameter: <AddEditCandidateParameterPage/>,
    ProductFeaturePage: <ProductFeaturePage/>,
    SubscriptionPayment: <StripePaymentComponent/>,
    BookCandidateSlot: <BookSlotPage/>,
    ManageBookingSlots: <CalendlyBookingListingPage/>,
    CandidateAssessmentCompleted: <CompleteAssessmentPage/>,
    CandidateInterviewPage: <VideoInterviewComponent/>,
    ManageInvoices: <ManageInvoicePage/>,
    ManageInvoicesGroup: <ManageInvoiceGroupPage/>,
    ManageResume: <ManageResumePage/>,
    AssessmentFeedback: <AssessmentFeedbackPage/>,
    AssessmentTestStatus: <CheckAssessmentStatusPage/>,
    AddAssessmentFeedback: <AddCandidateFeedbackPage/>,
    ManageReports: <ManageReportsPage/>,
    ManageNotifications: <ManageNotificationPage/>,
};

const App = () => {
    return (
        <Suspense>
            <Routes>
                {routeItems.map((item, i) => {
                    if (!item.protected) {
                        return <Route key={item.component} path={item.route} element={PageList[item.component]} />;
                    } else {
                        return (
                            <Route
                                key={item.component}
                                path={item.route}
                                element={
                                    <PrivateRoute
                                        path={item.route}
                                        children={PageList[item.component]}
                                    />
                                }
                            />
                        );
                    }
                })}
            </Routes>
        </Suspense>
    );
};

export default App;
