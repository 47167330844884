import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonEvent from '../../components/Global/inputs/button.component';
import InputEvent from '../../components/Global/inputs/input.component';
import { forgetPasswordAction } from '../../redux/users/user.actions';
import './forgetPassword.styles.scss';
import { validate } from './forgetPassworValidation';

const ForgetPasswordPage = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("")
    const [isForgetPasswordDone, setIsForgetPasswordDone] = useState(false)
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState(null)
    
    const handleForgetPassword = () => {
        dispatch(forgetPasswordAction({email})).then(res=>{
            setIsForgetPasswordDone(true)
        }).catch(err=>{
            setApiErrorMessage(err?.response?.data?.message || err?.message)
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setApiErrorMessage(null)
        setFormErrors(validate({email: email}));
        setIsSubmit(true);
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleForgetPassword()
        }
    }, [formErrors]);

    return (
        <div className="forgetPassword-container">
            <div className="forgetPassword">
                <div className="forgetPassword__child forgetPasswordpage__child-right">
                    <div className="forgetPassword__child__textbox forgetPassword__child__textbox-right">
                        <div className='center'><img src="/logo-one.png" alt="logo" /></div>
                        <br />
                        <div className="forgetPassword__heading">
                            <h3>Forgot Password ?</h3>
                        </div>
                        {
                            !isForgetPasswordDone && <br />
                        }
                        {
                            isForgetPasswordDone ? 
                                <div className="forgetPassword-success">
                                    <p>
                                        The reset password link is shared on your registered Email. 
                                        Please click on the link to proceed further.
                                    </p>
                                </div> : 
                                <form onSubmit={handleSubmit}>
                                    <InputEvent text="Enter Registered Email Address" required={true} customStyle={{label:{fontWeight : "600", fontSize:"1rem"}}} value={email} setValue={setEmail}/>
                                    <br />
                                    {formErrors.email && 
                                        <>
                                            <p style={{"color": 'red'}}>{formErrors?.email}</p>
                                            <br></br>
                                        </>
                                    }
                                    <ButtonEvent text={"PROCEED"} customStyle={{backgroundColor: '#5265f9', color: '#fff'}} />
                                    <br />
                                    {apiErrorMessage &&
                                        <>
                                            <p style={{"color": 'red'}}>{apiErrorMessage}</p>
                                            <br></br>
                                        </>
                                    }
                                    <div className="redirect_actions">
                                        <p className='redirect_actions-backlogin' ><a href='/login'>Back to login</a></p>
                                    </div>
                                </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPasswordPage;
