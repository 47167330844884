import React from 'react';
import ManageResumeComponent from '../../components/BussinessPsychologist/ManageResume/ManageResume.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';

const ManageResumePage = () => {
    
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageResumeComponent/>}/>
            </div>
        </div>
    );
};

export default ManageResumePage;