export const validate = ({password, confirmPassword}) => {
    const errors = {};
    // const text_escaped = text.replace(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$");
    const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/i);
    if (!password) {
      errors.password = "Password is required.";
    } else if (password.length < 8) {
      errors.password = "Password must be more than 8 characters.";
    } else if (password.length > 16) {
      errors.password = "Password cannot exceed more than 16 characters.";
    }else if (!regex.test(password.trim())) {
      errors.password = "Password is invalid.";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    } else if (confirmPassword.length < 8) {
      errors.confirmPassword = "Confirm Password must be more than 8 characters.";
    } else if (confirmPassword.length > 16) {
      errors.confirmPassword = "Confirm Password cannot exceed more than 16 characters.";
    } else if (password!==confirmPassword) {
      errors.confirmPassword = "Password & Confirm Password must be same.";
    }
    
    return errors;
};