export const validate = ({ email, password}) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      errors.email = "Email can't be empty!";
    } else if (!regex.test(email.trim())) {
      errors.email = "This is not a valid email format!";
    }
    if (!password) {
      errors.password = "Password can't be empty";
    }
    return errors;
};