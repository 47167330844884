import { useEffect, useState } from "react";
import { AiOutlineFolderView, AiTwotoneEdit } from "react-icons/ai";
import { BiConversation, BiSearch } from "react-icons/bi";
import { FaDownload } from "react-icons/fa";
import { MdOutlineAssignmentTurnedIn, MdOutlineSpaceDashboard } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth.hook";
import { addToaster } from "../../../redux/appToaster/appToaster.actions";
import AssignCandidateDialogue from "../../AptillioAdmin/AssignCandidateDialogue/AssignCandidateDialogue";
import AlertDialog from "../AlertDialog/AlertDialog.component";
import updateJobRoleIcon from "../../../assets/aptilio-table-icons/update_jobRole_icon.png";
// import deleteJobRoleIcon from "../../../assets/aptilio-table-icons/delete_jobRole_icon.png";
import { fetchBusinessPsychologistListing } from "../../../redux/AptillioAdmin/manageBusinessPsychologist/manageBusinessPsychologist.actions";
import { generateInvoice } from "../../../redux/Common/manageInvoice/manageInvoice.actions";
import { jsPDF } from "jspdf";
import { axiosInstance } from "../../../constants/axiosInstance";
import actionIcon from "../../../assets/aptilio-table-icons/table-action-icon.svg";
import { fetchAssessmentScore } from "../../../redux/CompleteAssessment/CompleteAssessment.actions";

const FormatActionComponent = ({cell, row, rowIndex, formatExtraData, setSelectedRowIndex, actionMenuRef, location, deleteRecord, dispatchActions}) => {
    const navigateTo = useNavigate()
    const dispatch = useDispatch()
    const { getUserAuth }= useAuth()
    const [userAuth, setUserAuth] = useState(null);
    // const [toastList, setToastList] = useState([]);
    const [confirmDialog, setConfirmDiaogue] = useState({ openAlert:false, alertMessage:""});
    const [assignBPDialog, setAssignBPDiaogue] = useState({ openDialogue:false, dialogueMessage:""});
    const [confirmAction, setConfirmAction] = useState(false);

    const handleRoute =()=>{
        switch (location?.pathname){
            case '/':
                return navigateTo(`/manageOrganization/view/${row?._id}`);
            case "/manageHiringManager":
                return navigateTo(`./edit/${row?._id}`);
            case "/manageCandidate":
                return navigateTo(`./edit/${row?._id}`);
            case "/manageBusinessPsychologist":
                return navigateTo(`./edit/${row?._id}`);
            case "/manageJobRole":
                return navigateTo(`./edit/${row?.value}`);
            default:
                return navigateTo(`./edit/${row?.value || row?._id}`);
        }
    }

    useEffect(()=>{
        const authValues = getUserAuth()
        setUserAuth(authValues)
    },[getUserAuth])

    useEffect(()=>{
        if(confirmAction){
            deleteRecord(row.value || row._id).then(res=>{
                dispatch(addToaster({id:0, title:"Job Role Deleted", description: "Job role data has been deleted successfully.", icon:"success"}))
                setConfirmDiaogue({ openAlert:false, alertMessage:""});
            }).catch(err=>{
                setConfirmDiaogue({ openAlert:false, alertMessage:""});
                dispatch(addToaster({id:0, title:"Delete Error", description: err?.response?.data?.message, icon:"error"}))
            })
            setConfirmAction(false)
        }
    },[confirmAction])

    const deleteJobRole =()=>{
        switch (location?.pathname){
            case "/":
                return void(0);
            case "/manageHiringManager":
                // return navigateTo(`./edit/${row?._id}`);
                return void(0);
            case "/manageCandidate":
                return void(0);
            case "/manageJobRole":
                return setConfirmDiaogue({ openAlert:true, alertMessage:`Are you sure to delete this Job Role?`})
            default:
                break
        }
    }

    const handleCandidate =(action)=>{
        if(action?.routeName && (action?.routeName?.includes("edit") || action?.routeName?.includes("view") || action?.routeName?.includes("feedback"))){
            return navigateTo(action?.routeName)
        }
        if(action?.name?.includes("Test")){
            dispatch(fetchAssessmentScore(axiosInstance, {id:row?._id})).then(res=>{
                window.open(res?.data?.resultUrl,'_blank')
            }).catch(err=>{
                dispatch(addToaster({id:0, title:"Fetch Error", description: err?.response?.data?.message || "Error while generating writtten test score.", icon:"error"}))
            })
        }
        if(action?.routeName ==="assignBP"){
            setAssignBPDiaogue({ openDialogue:true, dialogueMessage:"Assign Business Psychologist", dispatchActions:{submit:dispatchActions?.updateCandidateAssessment, userId: row._id, select: fetchBusinessPsychologistListing} });
            return 
        }
    }

    const handleInvoiceDownload =(id)=>{
        dispatch(generateInvoice(axiosInstance, {id})).then(res=>{
            
            var doc = new jsPDF('p', 'pt', 'a4');
            doc.setFont('Inter-Regular', 'normal');
            doc.html(res?.html, {
                callback: function(doc) {
                    // Save the PDF
                    doc.save('aptillio-invoice.pdf');
                },
                x: 15,
                y: 15,
                width: 570, //target width in the PDF document
                windowWidth: 650 //window width in CSS pixels
            });
        }).catch(err=>{
            dispatch(addToaster({id:0, title:"Invoice Error", description: err?.response?.data?.message || "Error while fetching invoice.", icon:"error"}))
        })
    }

    const handleCandidateIcon=(icon)=>{
        switch (icon){
            case "view":
                return <AiOutlineFolderView/>;
            case "edit":
                return <AiTwotoneEdit/>;
            case "assign":
                return <MdOutlineAssignmentTurnedIn/>;
            default:
                return <BiConversation/>;
        }
    }

    const renderSwitchMenu =(row)=>{
        const split=location.pathname.split('/')[1]
        switch (split){
            case "manageCandidate":
                return (<>{
                    row?.actions.map((x)=>{
                        if(x?.routeName==="assignBP" && row?.bpAssign){
                            return void(0)
                        }else{
                            return(
                                <div key={x._id} className="action_popup" onClick={()=>handleCandidate(x)}>
                                    <div className="action_popup-icon">{handleCandidateIcon(x?.icon)}</div>
                                    <div className="action_popup-text"><p className='action_popup-text'>{x?.name}</p></div>
                                </div>
                            )}
                        })
                    }
                </>
                    
                )
            case "manageJobRole":
                return (<>
                    <div className="action_popup" style={{color: userAuth?.userId !== row.created_by && "lightgrey"}}
                        onClick={ userAuth?.userId === row.created_by ? handleRoute : undefined}>
                        <div className="action_popup-icon"><img alt="update-job-role-icon" src={updateJobRoleIcon}></img></div>
                        <div className="action_popup-text"><p className='action_popup-text'>Update Job Role</p></div>
                     </div>
                    <div className="action_popup" style={{color:userAuth?.userId !== row.created_by && "lightgrey"}} 
                        onClick={  userAuth?.userId === row.created_by ? deleteJobRole : undefined}>
                        <div className="action_popup-icon"><MdOutlineSpaceDashboard/></div>
                        <div className="action_popup-text"><p className='action_popup-text'>Delete Job Role</p></div>
                     </div>
                </>)
            case "manageBusinessPsychologist":
                return (<>
                    <div className="action_popup" onClick={handleRoute}>
                        <div className="action_popup-icon"><BiSearch/></div>
                        <div className="action_popup-text"><p className='action_popup-text'>View / Edit Details</p></div>
                    </div>
                </>)
            case "manageInvoicesByGroup":
                return (<>
                    <div className="action_popup" onClick={()=>navigateTo(`/manageInvoices/${row?._id}`)}>
                        <div className="action_popup-icon"><FaDownload/></div>
                        <div className="action_popup-text"><p className='action_popup-text'>View Invoices</p></div>
                    </div>
                </>)   
            case "manageInvoices":
                return (<>
                    <div className="action_popup" onClick={()=>handleInvoiceDownload(row?.docId)}>
                        <div className="action_popup-icon"><FaDownload/></div>
                        <div className="action_popup-text"><p className='action_popup-text'>Download Invoice</p></div>
                    </div>
                </>)
            case "manageResume":
                return (<>
                    <div className="action_popup" onClick={()=>row.resumePath && window.open(row?.resumePath)}>
                        <div className="action_popup-icon"><FaDownload/></div>
                        <div className="action_popup-text"><p className='action_popup-text'>Download Resume</p></div>
                    </div>
                </>)
            default:
                return <>
                    <div className="action_popup" onClick={handleRoute}>
                        <div className="action_popup-icon"><BiSearch/></div>
                        <div className="action_popup-text"><p className='action_popup-text'>View / Edit Details</p></div>
                    </div>
                </>}
    }

    useEffect(()=>{
        return ()=>{
            setConfirmAction(false)
        }
    },[])
    
    return(
        <span key={rowIndex} className='table_action' style={{textAlign:"center"}}>
            <div className="table_action-icon">
                <img alt='table-action' onClick={(e)=>{setSelectedRowIndex(row._id || row.value); e.stopPropagation()}} className='action-icon' src={actionIcon}></img>
            </div>
            {
                formatExtraData === (row._id || row.value) ? <div className="table_action-popup" ref={actionMenuRef}>
                {
                    <div className="table_action-popup_wrapper">
                        {renderSwitchMenu(row)}
                    </div>
                    
                }
            </div> : ""
            }
            <AlertDialog
                openAlert={confirmDialog?.openAlert}
                alertMessage={confirmDialog.alertMessage}
                setConfirmDiaogue = {setConfirmDiaogue}
                setConfirmAction={setConfirmAction}/>
            <AssignCandidateDialogue
                dialogueContainer={assignBPDialog}
                setConfirmDiaogue = {setAssignBPDiaogue}/>
        </span>
    )
}

export default FormatActionComponent;