export const jobRoleFieldValidation = ({ jobTitle, jobDescription, actionType}) => {
    const errors = {};
    
    if (!jobTitle) {
      errors.jobTitle = "Job Titile can't be empty!";
    }
    if (!jobDescription) {
      errors.jobDescription = "Job description can't be empty";
    }
    return errors;
};