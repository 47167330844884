import React, { useState } from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchBusinessPsychologistListing } from '../../../redux/AptillioAdmin/manageBusinessPsychologist/manageBusinessPsychologist.actions';
import { selectBusinessPsychologistListingData } from '../../../redux/AptillioAdmin/manageBusinessPsychologist/manageBusinessPsychologist.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import { MANAGE_BUSINESS_PSYCHOLOGIST_HEADING } from '../ManageOrganisation/ManageOrganisation.constants';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';

const ManageBusinessPsychologistComponent = ({}) => {
    const navigateTo = useNavigate()
    const filtersData = useSelector(selectFilterData)
    const [firstname, setFirstNameFilter] = useState("")
    const [lastname, setLastNameFilter] = useState("")
    const [email, setEmailFilter] = useState("")
    const [expertise, setExpertiseFilter] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [statusFilter, setStatusFilter] = useState("")
    const statusFilterOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const inputFilterList = [
        { text:"Filter By First Name", placeholder:"Enter First Name", key:"firstname", value:firstname, setValue:setFirstNameFilter},
        { text:"Filter By Last Name", placeholder:"Enter Last Name", key:"lastname", value:lastname, setValue:setLastNameFilter},
        { text:"Filter By Email", placeholder:"Enter Email", key:"email", value:email, setValue:setEmailFilter},
        { text:"Filter By Expertise", placeholder:"Enter Business Psychologist Expertise", key:"expertise", value:expertise, setValue:setExpertiseFilter},
        { text:"Filter By Status", placeholder:"Select", value:statusFilter, key:"status", setValue:setStatusFilter, type:"select", selectOptions:statusFilterOptions}
    ]
    
    const businessPsychologistAllDataTableColumns = [
        {
            dataField: 'firstname',
            text: 'First Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '12%'};
            }
        },
        {
            dataField: 'lastname',
            text: 'Last Name',
            headerStyle: (colum, colIndex) => {
                return { width: '12%'};
            }
        },
        {
            dataField: 'email',
            text: 'Email ID',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '20%'};
            }
        },
        {
            dataField: 'expertise',
            text: 'Expertise'
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '16%'};
            },
            formatter: (cell, row, rowIndex, formatExtraData)=>{
                return <>{row?.status?.split("")[0]?.toUpperCase() + row?.status?.substring(1,row?.status?.length)}</>
            }
        },
    ];

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>{MANAGE_BUSINESS_PSYCHOLOGIST_HEADING}</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-filter">
                                <ButtonEvent customStyle={{margin:0}} func={()=>setIsDrawerOpen(true)} text={"Search/Filter"} icon={<FaFilter/>}></ButtonEvent>
                            </div>
                            <div className="dashboard_main-listing_header_actions-search">
                                <ButtonEvent text={"Add Business Psychologist"} customStyle={{backgroundColor:"#5367FD", color:"white"}} func={()=>{navigateTo(`/manageBusinessPsychologist/add`)}} icon={<FaPlus/>}></ButtonEvent>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={businessPsychologistAllDataTableColumns} apiAction={fetchBusinessPsychologistListing} apiSelector={selectBusinessPsychologistListingData} filters={filtersData}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageBusinessPsychologistComponent;