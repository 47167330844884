import React, { useEffect, useRef, useState } from 'react';
import { IoMdNotifications } from 'react-icons/io';
import { MdHelp } from 'react-icons/md';
import './header.component.scss';
import { useDispatch } from 'react-redux';
import io from 'socket.io-client';
import useHandleLogout from '../../../hooks/useHandleLogout.hook';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import NotificationMenuComponent from '../Notification/Notification.component' 
import { fetchMyAccountUserDetail } from '../../../redux/Common/myAccount/myAccount.actions';
import { fetchNotificationCount } from '../../../redux/Common/manageNotification/manageNotification.actions';
const socket = io.connect(process.env.REACT_APP_SOCKET_URL)

const HeaderComponent = () => {
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [logoutMenu, setLogoutMenu] = useState(false);
    const { handleLogout } = useHandleLogout();
    const logoutMenuRef = useRef();
    const [notificationCount, setNotificationCount] = useState(0);
    const [notificationToggle, setNotificationToggle] = useState(false);
    const [userInitial, setUserInitial] = useState('');
    const [userDetail, setUserDetail] = useState(null);
    const notificationRef = useRef();

    useEffect(()=>{
        socket.on("connect", () => {
            console.log("connected");            
        });
    },[socket]);

    useEffect(()=>{
        // socket.on("connect", () => {
            socket.on(`notify_${localStorage.getItem("userId")}`,(res)=> {
                setNotificationCount(res?.count)
                // fetchUnreadCount().then(res=>setNotificationCount(res?.totalCount))
            });
        // });
    })

    const fetchUnreadCount=()=>{
        return new Promise((resolve, reject)=>{
            dispatch(fetchNotificationCount(axiosInstance, {})).then(res=>{
                resolve(res?.data?.data)
            })
        })
    }

    useEffect(() => {
        fetchUnreadCount().then(res=>setNotificationCount(res?.totalCount))
    }, []);

    useEffect(() => {
        dispatch(fetchMyAccountUserDetail(axiosInstance)).then((res) => {
            setUserDetail(res?.user);
        });
    }, [axiosInstance, dispatch]);

    useEffect(() => {
        userDetail && setUserInitial(`${userDetail.firstname[0]}${userDetail.lastname[0]}`);
    }, [userDetail]);

    useEffect(() => {
        //for listening to on-click event for table actions
        function handler(event) {
            if (!logoutMenuRef?.current?.contains(event?.target)) {
                setLogoutMenu(false);
            }
        }
        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler);
    }, []);

    useEffect(() => {
        //for listening to on-click event for notification menu
        function handler(event) {
            if (!notificationRef.current?.contains(event.target)) {
                setNotificationToggle(false);
            }
        }
        window.addEventListener('click', handler);
        return () => window.removeEventListener('click', handler);
    }, []);

    return (
        <div className="header-container">
            <div className="header-wrapper">
                <div className="header-logo">
                    <img src="/logo-one.png" className="header-logo-image" alt="logo" />
                </div>
                <div className="header-icons">
                    {/* <div className="header-icons-search"> */}
                    {/* <AiOutlineSearch className='header-icons-search-icon'/> */}
                    {/* <p>Search</p> */}
                    {/* </div> */}
                    <div className="header-icons-help">
                        <MdHelp />
                        <p>Help</p>
                    </div>
                    <div className="header-icons-notifications" onClick={()=>setNotificationToggle(true)} ref={notificationRef}>
                        <IoMdNotifications />
                        {
                            notificationCount>0 && (
                                <span className="header-icons-notifications-alert">
                                    <p>{notificationCount}</p>
                                </span>
                            )
                        }
                        {
                            notificationToggle ? <NotificationMenuComponent setNotificationCount={setNotificationCount} setNotificationToggle={setNotificationToggle}/>
                            :<></>
                        }
                    </div>
                    <div
                        className="header-icons-profile"
                        ref={logoutMenuRef}
                        onClick={() => setLogoutMenu(true)}>
                        {userInitial.toUpperCase()}
                        {logoutMenu && (
                            <div className="header-logout_menu">
                                <div className="header-logout_menu-wrapper" onClick={handleLogout}>
                                    <p>LOGOUT</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderComponent;
