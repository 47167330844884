import { endpoints } from "../../../constants/url.endpoint";

export const fetchReportsData =(axiosInstance ,type,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            let url;
            switch (type){
                case "active_users" : {
                    url=endpoints.activeUserReport;
                    break;
                }
                case "hiring_managers" : {
                    url=endpoints.hiringManagerReport;
                    break;
                }
                case "candidate_assigned" : {
                    url=endpoints.assignedCandidatesReport;
                    break;
                }
                case "assessment_completed" : {
                    url=endpoints.assessmentCompletdReport;
                    break;
                }
                default :{
                    break;
                }
            }
            axiosInstance({
                // url: `${type ==="active_users" ? endpoints.activeUserReport : endpoints.hiringManagerReport}`,
                url: url,
                method: 'GET',
                params: apiData
            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchActiveOrgData =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.activeOrganizationsData}`,
                method: 'GET',
                // params:apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchOrgPerformanceData =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.organizationPerformanceReport}`,
                method: 'GET',
                // params:apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};
