import { endpoints } from '../../../constants/url.endpoint';
import { manageInvoiceActions } from './manageInvoice.reducer';

const { manageInvoiceListSuccess, resetManageInvoice } = manageInvoiceActions;

export const fetchInvoiceListing =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = apiData.userId || localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.invoiceListings}/${apiData?.userId}`,
                method: 'GET',
                params: apiData
            }).then((res) => {
                dispatch(manageInvoiceListSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetManageInvoice());
                reject(err)
            });
    })
};

export const generateInvoice =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.invoiceDetail}/${apiData?.id}`,
                method: 'GET',
            }).then((res) => {
                resolve(res?.data)
            }).catch(err => {
                reject(err)
            });
    })
};