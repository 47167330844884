import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  toasterData: null,
};
const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    addToasterData: (state, action) => {
      state.toasterList = action.payload;
    },
    resetToasterList: (state, action) => {
      state.toasterList = null;
    }
  }
});

export const {
    actions: toasterActions,
    reducer: toasterReducer,
    name: toaster
} = toasterSlice;
