import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import "./AssignCandidateDialogue.scss";
import { AiOutlineClose } from "react-icons/ai";
import SearchableAsyncSelectBox from '../../Global/searchableAsyncSelect/searchableAsyncSelect.component';
import ButtonEvent from '../../Global/inputs/button.component';
import { fetchBusinessPsychologistListing } from '../../../redux/AptillioAdmin/manageBusinessPsychologist/manageBusinessPsychologist.actions';

export default function AssignCandidateDialogue({dialogueContainer, setConfirmDiaogue}) {
  const [open, setOpen] = React.useState(false);
  const [selectedUserId, setSelectBpId] = React.useState("")
  const [required, setRequired] = React.useState(false)
  React.useEffect(()=>{
    setOpen(dialogueContainer?.openDialogue)
  },[dialogueContainer?.openDialogue])

  React.useEffect(()=>{
      return ()=>{
          setOpen(false)
      }
  },[])

  const handleClose = () => {
    setConfirmDiaogue({ openDialogue:false, dialogueMessage:""});
    setSelectBpId("")
    setRequired(false)
    setOpen(false);
  };

  const handleSubmit = () => {
    setRequired(true)
    if(selectedUserId){
      handleClose()
      const { submit } = dialogueContainer?.dispatchActions;
      submit({_id:dialogueContainer?.dispatchActions?.userId, assigneeId: selectedUserId}).then(res=>{
        // dispatch(addToaster({id:0, title:"Business Psychologist Assigned", description: "Business psychologist has been assigned successfully for this candidate.", icon:"success"}))
        // handleClose()
      }).catch(err=>{
        // dispatch(addToaster({id:0, title:"Update Error", description: err?.response?.data?.message || "Error while assigning a business psychologist.", icon:"error"}))
      })
    }else{
      return
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="assign_candidate">
          <div className="assign_candidate-wrapper">
              <div className="assign_candidate-wrapper-heading_main"><p>{dialogueContainer?.dialogueMessage}</p></div>
          </div>
          <div className="assign_candidate-wrapper-select_bp">
            <SearchableAsyncSelectBox selectApi={dialogueContainer?.dispatchActions?.select} selectById={dialogueContainer?.dispatchActions?.userId} value={selectedUserId} setValue={setSelectBpId}/>
            {required && !selectedUserId && <p className='input_field-error_text'>Please select from the dropdown.</p>}
            <div className="assign_candidate-wrapper-select_bp-submit">
              <ButtonEvent func={handleSubmit} text={"Assign"}/>
          </div>
          </div>
        </div>
        <button onClick={handleClose} className='dialogue_box-close'>
            <AiOutlineClose></AiOutlineClose>
        </button>
      </Dialog>
    </div>
  );
}