/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './table.component.scss';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { removeFilters } from '../../../redux/Common/tablefilter/tableFilter.actions';
import FormatActionComponent from './tableActionFormatter';
import useAuth from '../../../hooks/useAuth.hook';
import { useLocation } from 'react-router-dom';
import { addToaster } from '../../../redux/appToaster/appToaster.actions';

const TableComponent = ({columns, apiAction, apiSelector, filters, deleteAction, dispatchActions, fetchId}) => {
    const { auth } = useAuth()
    const location = useLocation()
    const axiosInstance = useAxiosInstance()
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [dataList, setDataList] = useState([]);
    const [totalData, setTotalData] = useState("");
    const [apiDefaultData, setApiDefaultData] = useState({});
    const [sortData, setSortData] = useState({})
    const [selectedRowIndex, setSelectedRowIndex] = useState("")
    
    const dispatch = useDispatch();
    const tableData = useSelector(apiSelector)
    const actionMenuRef = useRef()
    const tableColumns=[...columns, {
        dataField: 'action',
        text: 'Action',
        headerStyle: (colum, colIndex) => {
            return { width: '8%', textAlign: 'center' };
        },
        formatExtraData: selectedRowIndex,
        formatter: (cell,row, rowIndex, formatExtraData)=>{
            return <FormatActionComponent cell={cell} row={row} rowIndex={rowIndex} location={location} formatExtraData={formatExtraData} 
            setSelectedRowIndex={setSelectedRowIndex} actionMenuRef={actionMenuRef} deleteRecord={deleteRecord} dispatchActions={{updateCandidateAssessment}}/>
        }
    }]

    useEffect(()=>{
        dispatch(removeFilters())

        //for listening to on-click event for table actions
        function handler(event) {
            if(!actionMenuRef?.current?.contains(event?.target)) {
                setSelectedRowIndex("")
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    },[])

    useEffect(() => {
        if(auth?.userType !== "aptillio-admin"){
            const userId = auth?.userId
            setApiDefaultData({userId: userId})
        }
    }, [auth])

    useEffect(()=>{
        setPage(1)
        const paramsData = {...apiDefaultData!=={} ? apiDefaultData : null, page:1, limit:pageSize,...filters!=={} ? filters : void(0), userId: fetchId}
        dispatch(apiAction(axiosInstance ,paramsData))
    },[filters, apiDefaultData])

    useEffect(()=>{
        setDataList(tableData?.listing?.listingData || [])
        setTotalData(tableData?.listing?.meta?.total || 0)
    },[tableData])

    const deleteRecord = (id) => {
        const created_by = localStorage.getItem("userId")
        return new Promise((resolve, reject)=>{
            dispatch(deleteAction(axiosInstance, {id, created_by})).then(res=>{
                const paramsData = {...apiDefaultData!=={} ? apiDefaultData : null, page:1, limit:pageSize,...filters!=={} ? filters : void(0),  userId: fetchId}
                dispatch(apiAction(axiosInstance ,paramsData))
                resolve(res)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    
    const updateCandidateAssessment = (data) => {
        return new Promise((resolve, reject)=>{
            const {updateCandidateAssessment} = dispatchActions;
            dispatch(updateCandidateAssessment(axiosInstance, {...data, bpAssign: data?.assigneeId})).then(res=>{
                resolve("candidate assessment updated")
                dispatch(addToaster({id:0, title:"Business Psychologist Assigned", description: "Business psychologist has been assigned successfully for this candidate.", icon:"success"}))
                const paramsData = {...apiDefaultData!=={} ? apiDefaultData : null, page:1, limit:pageSize,...filters!=={} ? filters : void(0)}
                dispatch(apiAction(axiosInstance ,paramsData))
            }).catch(err=>{
                dispatch(addToaster({id:0, title:"Update Error", description: err?.response?.data?.message || "Error while assigning a business psychologist.", icon:"error"}))
                reject(err)
            })
        })
    }
    
    const pageButtonRenderer = ({
        page,
        active,
        text,
        // disabled,
        // title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        // ....
        return (
            <li key={page + Math.random()} className={active ? "active page-item" : "page-item"}>
                <a href="#" onClick={handleClick}>{page}</a>
            </li>
        );
    };

    const options = {
        pageButtonRenderer,
        hideSizePerPage: true,
        withFirstAndLast: false,
        prePageText: <span className="prev"><BsArrowLeft/>PREV</span>,
        nextPageText:<span className="next">NEXT<BsArrowRight/></span>,
        sizePerPage:pageSize,
        paginationSize:15,
        totalSize : totalData,
        onPageChange: (page, sizePerPage) => {
            dispatch(apiAction(axiosInstance,{ ...apiDefaultData!=={} ? apiDefaultData : null, page, limit:pageSize, ...filters, ...sortData!=={} ? sortData : null, userId: fetchId}))
        }
    };

    const handleTableChange=(type,{page, sortField, sortOrder})=>{
        if(type==="pagination"){
            setPage(page);
        }
        if(type==="sort"){
            setSortData({sortOrder, sortBy: sortField})
            dispatch(apiAction(axiosInstance, { ...apiDefaultData!=={} ? apiDefaultData : null, page, limit:pageSize, sortBy: sortField, sortOrder,...filters,  userId: fetchId}))
        }
    }
      
    return (
        <div className="table_container">
            <div className="table_wrapper">
                <div className="table_search">
                </div>
                <div className="table_main">
                    <BootstrapTable headerWrapperClasses="table_main-header" keyField='_id' data={ dataList } columns={ tableColumns ? tableColumns : columns }
                    bootstrap4
                    striped
                    hover
                    condensed
                    remote
                    bordered={ false }
                    noDataIndication="No Records Found."
                    pagination={paginationFactory(options)}
                    onTableChange={handleTableChange}/>
                </div>
            </div>
        </div>
    )
}

export default TableComponent;