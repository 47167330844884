import React, { useEffect, useState } from 'react';
// import { FaFilter, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import useAuth from '../../../hooks/useAuth.hook';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchInvoiceListing } from '../../../redux/Common/manageInvoice/manageInvoice.actions';
import { selectInvoiceListingData } from '../../../redux/Common/manageInvoice/manageInvoice.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const ManageInvoiceComponent = ({}) => {
    const {id} = useParams()
    const navigateTo = useNavigate()
    const { getUserAuth } = useAuth()
    const filtersData = useSelector(selectFilterData)
    const [firstname, setFirstNameFilter] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [userType, setUserType] = useState("")
    
    const inputFilterList = [
        { text:"Filter By First Name", placeholder:"Enter First Name", key:"firstname", value:firstname, setValue:setFirstNameFilter},
    ]
    
    const businessPsychologistAllDataTableColumns = [
        {
            dataField: 'subscriptionName',
            text: 'Plan',
            headerStyle: (colum, colIndex) => {
                return { width: '15%'};
            }
        },
        {
            dataField: 'created_at',
            text: 'Payment Date',
            sort: true,
        },
        {
            dataField: 'transactionId',
            text: 'Transaction',
            headerStyle: (colum, colIndex) => {
                return { width: '30%'};
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '15%'};
            },
        },
    ];

    useEffect(()=>{
        let user= getUserAuth()
        setUserType(user.userType)
    },[getUserAuth])

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>Manage Organization Invoices</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            {
                                userType==="aptillio-admin" &&
                                <div className="dashboard_main-listing_header_actions-filter">
                                    <ButtonEvent func={()=>navigateTo("/manageInvoicesByGroup")} icon={<AiOutlineArrowLeft/>} text={"Back To Admin Dashboard"}></ButtonEvent>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={businessPsychologistAllDataTableColumns} apiAction={fetchInvoiceListing} apiSelector={selectInvoiceListingData} filters={filtersData} fetchId={id}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageInvoiceComponent;