import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import AptillioToaster from '../../Global/AptillioToaster/AptillioToaster.component';
import ButtonEvent from '../../Global/inputs/button.component';
import InputEvent from '../../Global/inputs/input.component';
import './CandidateDetail.component.scss';
import { submitCandidateValidation } from './CandidateDetailValidation';
import { AiFillFile, AiOutlineArrowLeft } from "react-icons/ai";
import AlertDialog from '../../Global/AlertDialog/AlertDialog.component';
import SelectBox from '../../Global/inputs/select.component';
import { fetchCandidateDetail, fetchCandidateJobRoles, fetchCandidateParams, submitCandidateDetail, updateCandidateDetail } from '../../../redux/Common/manageCandidates/manageCandidates.actions'
import AssessmentListingComponent from '../ManageCandidate/AssessmentListing/AssessmentListing.component';
import { MdRefresh } from 'react-icons/md';
import AWS from 'aws-sdk';
import { awsAccessKey, awsBucketName, awsRegion, awsSecretKey } from '../../../constants/envExports';
import { isLoading } from '../../../redux/progressLoader/progressLoader.actions';
import { IoMdDownload } from 'react-icons/io';

AWS.config.update({
    accessKeyId: awsAccessKey,
    secretAccessKey: awsSecretKey
  })
const myBucket = new AWS.S3({
    params: { Bucket: awsBucketName },
    region: awsRegion,
})

const CandidateDetailComponent = ({actionType}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigateTo = useNavigate()
    const axiosInstance = useAxiosInstance()
    const [email, setEmail] = useState("")
    const [firstname, setFirstName] = useState("")
    const [lastname, setLastName] = useState("")
    const [jobRoleId, setJobRole] = useState("")
    const [resumePath, setResumePath] = useState("")
    const [candidateParamId, setCandidateParameter] = useState("")
    const [additionalInfo, setAdditionalInfo] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [assessmentStatusId, setAssessmentStatusId] = useState("")
    const [toastList, setToastList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [candidateData, setCandidateData] = useState(null);
    const [confirmDialog, setConfirmDiaogue] = useState({ openAlert:false, alertMessage:""});
    const [confirmAction, setConfirmAction] = useState(false);
    const [jobRolesOptions, setJobRolesOptions] = useState([]);
    const [candidateParamsOptions, setCandidateParamsOptions] = useState([]);
    const hiddenFileInput = React.useRef(null);

    const handleResumeUpload = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        // Allowing file type
        let fileExtension = fileUploaded.name.split('.').pop()
        if(fileUploaded.size > 5000000){
            setToastList([{id:0, title:"File Size Error", description: "Invalid File Size. File size should not exceed 3mb.", icon:"error"}])
            return setResumePath("")
        }
        if (fileUploaded?.type === "application/pdf" || fileUploaded?.type === "application/msword" || 
        fileExtension === "pdf" || fileExtension==="docx" || fileExtension==="doc"){
            onFileChange(event)
        }else{
            setToastList([{id:0, title:"File Type Error", description: "Invalid File Type. File type should be doc or pdf.", icon:"error"}])
            return setResumePath("")      
        }
    };

    const onFileChange = async (e) => {
        //getting resume file 
        const file = e.target.files[0]
        const name = e.target.files[0].name
        dispatch(isLoading(true))
        // const filetypes = /docx|doc|pdf|gif/;
        // const extname = filetypes.test((file?.name.split('.').pop() + "")?.toLowerCase());
    
          const fileReader = new FileReader();
          fileReader.onload = function (e) {
          }
          fileReader.readAsDataURL(file);
          uploadToS3(file, name)
        // }
      }
    
    const uploadToS3 = async(file, name) => {
        const fileKey = `resume-${name}`;
        const params = {
        Body: file,
        Bucket: awsBucketName,
        Key: fileKey,
        };
        try {
            const upload = await myBucket.upload(params).promise();
            if(upload){
                setResumePath(upload?.Location)
                dispatch(isLoading(false))
            }
        } catch (error) {
            setToastList([{id:0, title:"Error", description: "Error while uploading the resume.", icon:"error"}])
            dispatch(isLoading(false))
        }
    }

    useEffect(() => {
        //fetch candidate params
        dispatch(fetchCandidateParams(axiosInstance,setCandidateParamsOptions))
        dispatch(fetchCandidateJobRoles(axiosInstance,setJobRolesOptions))
    }, [])

    useEffect(() => {
        //fetch candidate details in case of edit draft
        if ((actionType && actionType === "view") || actionType === "edit"){
            const routeSplit = location?.pathname.split('/')
            const candidateId = routeSplit[routeSplit.length-1]
            dispatch(fetchCandidateDetail(axiosInstance,{id: candidateId})).then(res=>{
                setCandidateData(res?.candidate)
                setEmail(res?.candidate.email)
                setFirstName(res?.candidate.firstname)
                setLastName(res?.candidate.lastname)
                setContactNo(res?.candidate.contactNo)
                setJobRole(res?.candidate?.jobRoleId?._id)
                setCandidateParameter(res?.candidate?.candidateParamId?._id)
                setResumePath(res?.candidate.resumePath)
                setAdditionalInfo(res?.candidate?.additionalInfo)
                setAssessmentStatusId(res?.candidate?.assessmentStatusId?._id)
            }).catch(err=>{
                setCandidateData(null)
                setToastList([{id:0, title:"Fetch Error", description: err?.response?.data?.message, icon:"error"}])
            })
        }
    }, [actionType])

    useEffect(()=>{
        if(confirmAction===true){
            setConfirmDiaogue({ openAlert:false, alertMessage:""});
            sumbmitDetailsAction()
        }
    },[confirmAction])

    const sumbmitDetailsAction=(saveAsDraft)=>{
        const created_by = localStorage.getItem("userId")
        const orgId = localStorage.getItem("organizationId")
        const apiData= {email, contactNo, firstname, lastname, jobRoleId, candidateParamId, resumePath, created_by, orgId, additionalInfo, modified_by:created_by,
            draft:saveAsDraft, ...{assessmentStatus: saveAsDraft ? "Draft": "Candidate Submission", assessmentStatusId}}
            dispatch((actionType==="view" || actionType==="edit") ? updateCandidateDetail(axiosInstance, {_id: candidateData?._id, ...apiData}): submitCandidateDetail(axiosInstance, apiData)).then(res=>{
                saveAsDraft ? setToastList([{id:0, title:"Candidate Draft Added", description: "Candidate Detail are saved as draft.", icon:"success"}])
                : setToastList([{id:0, title:"Candidate Submitted", description: "Candidate Detail has been submitted successfully.", icon:"success"}])
                setTimeout(()=>{
                    navigateTo('/manageCandidate')
                },1500)
            }).catch(err=>{
                setConfirmAction(false)
                setToastList([{id:0, title:"Submit Error", description: err?.response?.data?.message, icon:"error"}])
            });
    }

    const handleSubmit = (e, asDraft) => {
        e?.preventDefault();
        const validationError = submitCandidateValidation({email, contactNo, firstname, lastname, jobRoleId, candidateParamId, resumePath})
        setFormErrors(validationError);
        if (Object.keys(validationError).length === 0) {
            asDraft ? sumbmitDetailsAction(asDraft=true)
            : setConfirmDiaogue({ openAlert:true, alertMessage:`Are you sure to submit candidate details?`})
        }
    };

    useEffect(()=>{
        return()=>{
            clearTimeout()
            setConfirmAction(false)
        }
    },[])

    return (
        <div className="view_candidate-container">
            <div className="view_candidate-wrapper">
                <div className="view_candidate_main">
                    <div className="view_candidate_main-header_container">
                        <div className="view_candidate_main-listing_header">
                            <p className='view_candidate_main-listing_header_text'>{(actionType==="edit" || actionType==="view" )? "Candidate Details": "Submit Candidate"}</p>
                        </div>
                    </div>
                    <form onSubmit={(e)=>e.preventDefault()}>
                        <div className="view_candidate_main-inputs">
                            { actionType !=="view" && <div className="view_candidate_main-inputs_wrapper" style={{justifyContent:"flex-end"}}>
                                    <label className='candidate_main-input_label'><span style={{fontSize:"1.5rem", color: "red"}}>*</span> Required Fields</label>
                            </div>}
                            <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                <div className="candidate_main-label">
                                    <label className='candidate_main-input_label'>Candidate First Name: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="candidate_main-input">
                                    <InputEvent nolabel={true} customStyle={{backgroundColor:actionType==="view" && "white"}} disabled={actionType==="view"} value={firstname} setValue={setFirstName} placeholder={actionType!=="view"?"Enter First Name":""}/>
                                    {
                                        formErrors.firstname && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.firstname}</p>
                                        
                                    }
                                </div>
                            </div>
                            <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                <div className="candidate_main-label">
                                    <label className='candidate_main-input_label'>Candidate Last Name:<span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="candidate_main-input">
                                    <InputEvent nolabel={true} customStyle={{backgroundColor:actionType==="view" && "white"}} disabled={actionType==="view"} value={lastname} setValue={setLastName} placeholder={actionType!=="view"?"Enter Last Name":""}/>
                                    {
                                        formErrors.lastname && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.lastname}</p>
                                    }
                                </div>
                            </div>
                            <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                <div className="candidate_main-label">
                                    <label className='candidate_main-input_label'> Candidate Phone No:<span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="candidate_main-input">
                                    <InputEvent type={"number"} nolabel={true} customStyle={{backgroundColor:actionType==="view" && "white"}} disabled={actionType==="view"} value={contactNo} setValue={setContactNo} placeholder={actionType!=="view"?"Enter 10 Digit Phone Number":""}/>
                                    {
                                        formErrors.contactNo && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.contactNo}</p>
                                    }
                                </div>
                            </div>
                            <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                <div className="candidate_main-label">
                                    <label className='candidate_main-input_label'> Candidate Email Id: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="candidate_main-input">
                                    <InputEvent nolabel={true} customStyle={{backgroundColor:actionType==="view" && "white"}} disabled={actionType==="view"} value={email} setValue={setEmail} placeholder={actionType!=="view"?"Enter Candidate's Email Id":""}/>
                                    {
                                        formErrors.email && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.email}</p>
                                    }
                                </div>
                            </div>
                            <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                <div className="candidate_main-label">
                                    <label className='candidate_main-input_label'> Candidate Resume: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="candidate_main-input">
                                    <div className="candidate_main-input_candidate_resume-actions">
                                        <input type="file" accept=".pdf,.doc,.docx" ref={hiddenFileInput} onChange={handleChange} style={{display:"none"}}/>
                                        {(!resumePath && actionType !=="view") ? <>
                                            <div className="candidate_main-input_candidate_resume-actions-btn">
                                                <ButtonEvent text={"Upload Resume"} func={handleResumeUpload} customStyle={{backgroundColor:"#5165F8", color:"white"}}/>
                                                <label style={{fontWeight: 500}}>File accepted(.pdf or .doc)</label>
                                            </div>
                                        </>: <label onClick={actionType!=="view" ? handleResumeUpload:void(0)} className="candidate_main-input_candidate_resume-actions_uploaded">
                                            <span className='file-icon'><AiFillFile/></span> <span className='resume-path'>{resumePath?.split("-")[3]}</span><span className='file-icon'>
                                                {actionType!=="view" ? <MdRefresh/> : <IoMdDownload onClick={()=>window.open(candidateData?.resumePath)}/>}
                                            </span></label>}
                                    </div>
                                    {
                                        formErrors.resumePath && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.resumePath}</p>
                                    }
                                </div>
                            </div>
                            <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                {/* <div className="candidate_main-label"> */}
                                {/* </div> */}
                                <div className="candidate_main-additional_info">
                                    Additional Info:
                                </div>
                            </div>
                            {
                                actionType !=="view" && 
                                <>
                                    <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                        <div className="candidate_main-label">
                                            <label className='candidate_main-input_label'> Job Role: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                        </div>
                                        <div className="candidate_main-input">
                                            <SelectBox value={jobRoleId} setValue={setJobRole} options={jobRolesOptions}/>
                                            {
                                                formErrors.jobRoleId && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.jobRoleId}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                        <div className="candidate_main-label">
                                            <label className='candidate_main-input_label'> Candidate Parameters: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                        </div>
                                        <div className="candidate_main-input">
                                            <SelectBox value={candidateParamId} setValue={setCandidateParameter} options={candidateParamsOptions}/>
                                            {
                                                formErrors.candidateParamId && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.candidateParamId}</p>
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            {
                                actionType ==="view" && 
                                <>
                                    <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                        <div className="candidate_main-label">
                                            <label className='candidate_main-input_label'> Job Title: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                        </div>
                                        <div className="candidate_main-input">
                                            <InputEvent nolabel={true} customStyle={{backgroundColor:actionType==="view" && "white"}} disabled={actionType==="view"} value={candidateData?.jobRoleId?.jobTitle}/>
                                        </div>
                                    </div>
                                    <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                        <div className="candidate_main-label">
                                            <label className='candidate_main-input_label'> Job Description: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                        </div>
                                        <div className="candidate_main-input">
                                            <InputEvent nolabel={true} customStyle={{backgroundColor:actionType==="view" && "white"}} disabled={actionType==="view"} value={candidateData?.jobRoleId?.jobDescription}/>
                                        </div>
                                    </div>
                                    <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                        <div className="candidate_main-label">
                                            <label className='candidate_main-input_label'> Candidate Parameters: <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                        </div>
                                        <div className="candidate_main-input">
                                            <InputEvent nolabel={true} customStyle={{backgroundColor:actionType==="view" && "white"}} disabled={actionType==="view"} value={candidateData?.candidateParamId?.paramName}/>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="view_candidate_main-inputs_wrapper candidate_name-input">
                                <div className="candidate_main-label">
                                    <label className='candidate_main-input_label'>Additional Info:</label>
                                </div>
                                <div className="candidate_main-input additional_info-input">
                                    {
                                    actionType ==="view" ?
                                        <p className='view_textarea-text'>{candidateData?.additionalInfo}</p>:
                                        <InputEvent type={"textarea"} maxLength={1000} customStyle={{backgroundColor:actionType==="view" && actionType==="view" && "white"}} disabled={actionType==="view"} nolabel={true} value={additionalInfo} setValue={setAdditionalInfo} placeholder={actionType!=="view"?"Additional info.....":""}/>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            actionType ==="view" &&
                            <div className="view_candidate_main-assessment_listing_wrapper">
                                <AssessmentListingComponent listData={candidateData}/>
                            </div>
                        }
                        {actionType !=="view" &&
                            <div className="view_candidate_main-action">
                                <div className="view_candidate_main-action_button">
                                            {(actionType==="edit" || actionType==="view") && (candidateData && candidateData!=={}) && <ButtonEvent text={"Submit Candidate"} func={(e)=>handleSubmit(e,false)}/>}
                                            {(actionType==="submit" || actionType==="add") && <ButtonEvent text={"Submit Candidate"} func={(e)=>handleSubmit(e,false)}/>}
                                </div>
                                <div className="view_candidate_main-action_button save_as_draft">
                                    {(actionType==="edit" || actionType==="view") && (candidateData && candidateData!=={}) &&
                                        <ButtonEvent text={"Update as draft"} customStyle={{color:"#5165F8", backgroundColor:"white"}}
                                        func={(e)=>handleSubmit(e,true)}/>}
                                    {(actionType==="submit" || actionType==="add") &&
                                        <ButtonEvent text={"Save as draft"} customStyle={{color:"#5165F8", backgroundColor:"white"}}
                                        func={(e)=>handleSubmit(e,true)}/>}
                                </div>
                            </div>
                        }
                        <div className="view_candidate_main-action">
                            <div className="view_candidate_main-action_button">
                                <ButtonEvent text={"Back To Dashboard"} customStyle={{backgroundColor:"#5165F8", color:"white"}} 
                                icon={<AiOutlineArrowLeft/>} func={()=>navigateTo('/manageCandidate')}/>
                            </div>
                        </div>
                    </form>
                </div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={3500}/>
                <AlertDialog 
                openAlert={confirmDialog?.openAlert}
                alertMessage={confirmDialog.alertMessage}
                setConfirmDiaogue = {setConfirmDiaogue}
                setConfirmAction={setConfirmAction}/>
            </div>
        </div>
    )
}

export default CandidateDetailComponent;