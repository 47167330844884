import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddEditSubscriptionComponent from '../../../components/AptillioAdmin/AddEditSubscription/AddEditSubscription.component';
import { addSubscriptionFieldValidation } from '../../../components/AptillioAdmin/AddEditSubscription/AddSubscriptionValidation';
import HomeComponent from '../../../components/Global/HomeLayout/HomeLayout.component';
import { addSubscription, fetchSubscriptionDetail, updateSubscription } from '../../../redux/AptillioAdmin/manageSubscription/manageSubscription.actions';

const AddEditSubscriptionPage = () => {
    const location = useLocation()
    const [subscriptionName, setSubscriptionName] = useState("")
    const [subscriptionCost, setSubscriptionCost] = useState("")
    const [tokenValue, setTokenValue] = useState("")
    const [description, setDescription] = useState("")
    const [expiryTenure, setExpiryTenure] = useState("")
    const [status, setStatus] = useState("active")
    const [tokenPerSubmission, setTokenPerSubmission] = useState("")
    const [formConstants, setFormConstants] = useState(null)
    const [inputFields, setInputFields] = useState(
        [
            { label:"Bundle Name", type:"text", placeholder:"Enter Bundle Name", key:"subscriptionName", value:subscriptionName, setValue:setSubscriptionName, required:true},
            { label:"Bundle Cost", type:"number", placeholder:"Enter Bundle Cost", key:"subscriptionCost", value:subscriptionCost, setValue:setSubscriptionCost, required:true},
            { label:"Token Value", type:"number", placeholder:"Enter Token Value", key:"tokenValue", value:tokenValue, setValue:setTokenValue, required:true},
            { label:"Token Per Submission", type:"text", placeholder:"Enter Token per submission", key:"tokenPerSubmission", value:tokenPerSubmission, setValue:setTokenPerSubmission, required:true},
            { label:"Plan Details", type:"text", placeholder:"Enter Plan Details", key:"description", value:description, setValue:setDescription, required:true},
            { label:"Expiration Tenure", type:"number", placeholder:"100", key:"expiryTenure", value:expiryTenure, setValue:setExpiryTenure, required:true, maxWidth:"50%"},
        ]
    );

    const handleToggle=()=>{
        setStatus(status ==="active" ? "inactive": "active")
    }

    const apiActions={
        add:addSubscription,
        fetch:fetchSubscriptionDetail,
        update:updateSubscription,
    }
    useEffect(() => {
        const routeSplit = location?.pathname.split('/')
        if(routeSplit[routeSplit.length-1] ==="add") { 
            setFormConstants({
                title:"Subscription Plan",
                formHeader:"Add Subscription Plan",
                actionButton:"Add Subscription Plan",
                confirmDialogue:"Are you sure to add this subscription plan?",
                actionType: "add",
                routeBack: "/manageSubscription",
            })
        }else{
            setFormConstants({
                title:"Subscription Plan",
                formHeader:"Edit Subscription Plan",
                actionButton:"Update Subscription Plan",
                confirmDialogue:"Are you sure to update this subscription plan?",
                actionType: "edit",
                routeBack: "/manageSubscription",
            })
        }
    }, [])


    useEffect(() => {
        setInputFields(
            [
                { label:"Bundle Name", type:"text", placeholder:"Enter Bundle Name", key:"subscriptionName", value:subscriptionName, setValue:setSubscriptionName, required:true},
                { label:"Bundle Cost", type:"number", placeholder:"Enter Bundle Cost", key:"subscriptionCost", value:subscriptionCost, setValue:setSubscriptionCost, required:true},
                { label:"Token Value", type:"number", placeholder:"Enter Token Value", key:"tokenValue", value:tokenValue, setValue:setTokenValue, required:true},
                { label:"Token Per Submission", type:"number", placeholder:"Enter Token per submission", key:"tokenPerSubmission", value:tokenPerSubmission, setValue:setTokenPerSubmission, required:true},
                { label:"Plan Details", type:"textarea", placeholder:"Enter Plan Details", key:"description", value:description, setValue:setDescription, required:true, maxLength:'250'},
                { label:"Expiration Tenure", type:"number", placeholder:"100", key:"expiryTenure", value:expiryTenure, setValue:setExpiryTenure, required:true, maxWidth:"50%"},
                { label:"Status", type:"switch", key:"status", value:status, setValue:setStatus, required:true,handleToggle ,isOn:status==="active"},
            ]
        )
    }, [subscriptionName, subscriptionCost, tokenValue, description, expiryTenure, status, tokenPerSubmission])
    
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<AddEditSubscriptionComponent inputFields={inputFields} formConstants={formConstants} apiAction={apiActions} inputValidation={addSubscriptionFieldValidation}/>}/>
            </div>
        </div>
    );
};

export default AddEditSubscriptionPage;
