import { endpoints } from "../../../constants/url.endpoint";

export const fetchInterviewToken =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.interviewDetail}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            });
    })
};

export const addCandidateNotes =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.addCandidateNotes}/${apiData.id}`,
                method: 'POST',
                data:apiData
            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            });
    })
};

export const getCandidateNotes =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.getCandidateNotes}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            });
    })
};

export const endInterview =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.endInterview}/${apiData.id}`,
                method: 'PATCH',
                data:apiData
            }).then((res) => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            });
    })
};