import React, { useEffect } from 'react';
import styles from './inputs.module.scss';
import { BiInfoCircle } from "react-icons/bi";
import ReactTooltip from 'react-tooltip';
import { maxWidth } from '@mui/system';

const InputEvent = ({
    maxWidth,
    text,
    value,
    setValue,
    customStyle,
    disabled,
    nolabel,
    placeholder,
    index,
    icon,
    iconFunc,
    type,
    id,
    required,
    maxLength,
    onEnterPress,
    tooltipText
}) => {
    let style = {};
    if (customStyle) {
        style = { ...style, ...customStyle };
    }
    if (maxWidth) {
        style = { ...style, maxWidth: maxWidth };
    }

    const handleChange = (e) => {
        const { name} = e.target;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setValue(value);
    };

    useEffect(()=>{
        if(id==="reset_confirm_pass") return window.onload = () => {
            const myInput = document.getElementById(id);
            if(myInput){
                myInput.onpaste = e => e.preventDefault();
            }
        }
        return()=>{
            // id=null;
        }
    },[])

    const handleKeyPress=(e)=>{
        if(e.key==='Enter' && onEnterPress){
            onEnterPress(e)
        }
        if(type==="number"){
            if(e.key ==='e' || e.key==="-" || e.key===".") e.preventDefault()
        }
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
             object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        if(type==="number" || type===Number || type==="Number"){
            // object.target.
        }
    }

    return (
        <div className={styles.input__container}>
            {!nolabel && <label style={customStyle?.label && customStyle.label}>{text || 'label'} {required && <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span>} {tooltipText?.length>3 && <span className='info-icon' data-tip={tooltipText}><BiInfoCircle/></span>}</label>}
            <ReactTooltip place='right' className={"password-tooltip"}/>
            <div style={{position: 'relative'}} className={styles.input__wrapper}>
                {type==="textarea" ? 
                <textarea
                    value={value}
                    onChange={(e) =>
                        !disabled && setValue && handleChange(e)
                    }
                    type={type || "text"}
                    className={styles.input}
                    style={style}
                    disabled={disabled}
                    placeholder={placeholder}
                    required={required || false}
                    maxLength={maxLength || 1000}
                    onKeyDown={handleKeyPress}
                /> :
                <input
                    id={id}
                    value={value}
                    onChange={(e) =>
                        !disabled && setValue && handleChange(e)
                    }
                    onInput={maxLengthCheck}
                    type={type || "text"}
                    className={styles.input}
                    style={style}
                    disabled={disabled}
                    placeholder={placeholder}
                    required={required || false}
                    maxLength={type==="number"? 10: maxLength? maxLength:100}
                    // max="9999999999"
                    onKeyDown={handleKeyPress}
                />
                }
                {icon && (
                    <div className={styles.icon} onClick={() => iconFunc && iconFunc(index, value)}>
                        <img
                            src={icon || '/icons/delete-button.png'}
                            alt="icon"
                        />
                    </div>
                )}
            </div>
            {type === "textarea" && <p style={{textAlign: 'right', fontSize:'0.9rem'}}>{value?.length}/{maxLength || 1000}</p>}
        </div>
    );
};

export default InputEvent;
