import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    data:null
};
const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    myAccountDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetMyAccountDetail: (state, action) => {
      state.data = null;
    },
  }
});

export const {
    actions: myAccountActions,
    reducer: myAccountReducer,
    name: myAccount
} = myAccountSlice;

