import { endpoints } from '../../../constants/url.endpoint';
import { manageJobRoleActions } from './manageJobRole.reducer';

const { jobRoleListDataSuccess, resetManageJobRoleAllData, jobRoleDetailsSuccess , resetJobRoleDetail} = manageJobRoleActions;

export const fetchJobRolesListing =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.candidatesJobListing}/${apiData?.userId}`,
                method: 'GET',
                params:apiData
            }).then((res) => {
                dispatch(jobRoleListDataSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetManageJobRoleAllData());
                reject(err)
            });
    })
};

export const addJobRole =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.candidatesAddJob}`,
                method: 'POST',
                data:apiData
            }).then((res) => {
                dispatch(jobRoleDetailsSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetJobRoleDetail());
                reject(err)
            });
    })
};

export const fetchJobRoleDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosInstance({
                url: `${endpoints.jobRoles}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                dispatch(jobRoleDetailsSuccess(res?.data));
                resolve(res?.data?.data?.data)
            }).catch(err => {
                dispatch(resetJobRoleDetail());
                reject(err)
            });
    })
};

export const updateJobRoleDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.jobRoles}/${apiData?._id}`,
                method: 'PATCH',
                data:apiData
            }).then((res) => {
                dispatch(jobRoleDetailsSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetJobRoleDetail());
                reject(err)
            });
    })
};

export const deleteJobRoleDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.jobRoles}/${apiData?.id}`,
                method: 'DELETE',
                data: {
                    created_by:apiData.created_by
                }
            }).then((res) => {
                dispatch(resetManageJobRoleAllData());
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};