export const submitCandidateValidation = ({ lastname, firstname ,email, contactNo, resumePath, jobRoleId, candidateParamId}) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneNumberRegex = new RegExp(/^[0-9]{10}$/i);
    if (!firstname) {
      errors.firstname = "First Name can't be empty.";
    }
    if (!lastname) {
      errors.lastname = "Last Name can't be empty.";
    }
    // if (!resumePath) {
    //   errors.resumePath = "Please upload candidate's resume.";
    // }
    if (!jobRoleId) {
      errors.jobRoleId = "Job Role can't be empty.";
    }
    if (!candidateParamId) {
      errors.candidateParamId = "Candidate Parameter can't be empty.";
    }
    if (!email) {
      errors.email = "Email can't be empty!";
    } else if (!regex.test(email.trim())) {
      errors.email = "This is not a valid email format.";
    }
    if (!contactNo) {
      errors.contactNo = "Phone Number can't be empty";
    }else if(contactNo.length !==10){
        errors.contactNo = "Phone Number must be of 10 numbers only.";
    }else if(!phoneNumberRegex.test(contactNo)){
        errors.contactNo = "Phone Number is invalid.";
    }
    return errors;
};