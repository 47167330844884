import React, { useState } from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchSubscriptionListing } from '../../../redux/AptillioAdmin/manageSubscription/manageSubscription.actions';
import { manageSubscriptionState } from '../../../redux/AptillioAdmin/manageSubscription/manageSubscription.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';

const ManageSubscriptionComponent = ({}) => {
    const navigateTo = useNavigate()
    const filtersData = useSelector(selectFilterData)
    const [statusFilter, setStatusFilter] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const statusFilterOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const inputFilterList = [
        { text:"Filter By Status", placeholder:"Select", value:statusFilter, key:"status", setValue:setStatusFilter, type:"select", selectOptions:statusFilterOptions}
    ]
    const businessPsychologistAllDataTableColumns = [
        {
            dataField: 'subscriptionName',
            text: 'Bundle Name',
        },
        {
            dataField: 'subscriptionCost',
            text: 'Bundle Cost',
            sort: true,
        },
        {
            dataField: 'created_at',
            text: 'Creation Date',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData)=>{
                return <>{row?.status?.split("")[0]?.toUpperCase() + row?.status?.substring(1,row?.status?.length)}</>
            }
        },
    ];

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>Manage Subscription</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-filter">
                                <ButtonEvent func={()=>setIsDrawerOpen(true)} text={"Filter"} icon={<FaFilter/>}></ButtonEvent>
                            </div>
                            <div className="dashboard_main-listing_header_actions-search">
                                <ButtonEvent text={"Add Plan"} customStyle={{backgroundColor:"#5367FD", color:"white"}} func={()=>{navigateTo(`/manageSubscription/add`)}} icon={<FaPlus/>}></ButtonEvent>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={businessPsychologistAllDataTableColumns} apiAction={fetchSubscriptionListing} apiSelector={manageSubscriptionState} filters={filtersData}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageSubscriptionComponent;