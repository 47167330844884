import { endpoints } from '../../../constants/url.endpoint';
import { manageOrganisationActions } from './manageOrganisation.reducer';

const { manageOrganisationsuccess, viewOrganisationDetailsSuccess, resetManageOrganisationData, resetOrganisationDetail} = manageOrganisationActions;

export const fetchOrganisationListing =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: endpoints.organisation,
                method: 'GET',
                params:apiData
            }).then((res) => {
                dispatch(manageOrganisationsuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetManageOrganisationData());
                reject(err)
            });
    })
};

export const fetchOrganisation =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.organisation}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                dispatch(viewOrganisationDetailsSuccess(res?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetOrganisationDetail());
                reject(err)
            });
    })
};

export const updateOrganisation =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.organisation}/${apiData._id}`,
                method: 'PATCH',
                data: apiData,
            }).then((res) => {
                dispatch(viewOrganisationDetailsSuccess(res?.data));
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const deleteOrganisation =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.organisation}/${apiData.id}`,
                method: 'DELETE',
            }).then((res) => {
                dispatch(resetOrganisationDetail());
                resolve(res)
            }).catch(err => {
                dispatch(resetOrganisationDetail());
                reject(err)
            });
    })
};