import React from 'react';
import ProductFeatureComponent from '../../components/OrganizationAdmin/ProductFeature/ProductFeature.component';

const ProductFeaturePage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <ProductFeatureComponent/>
            </div>
        </div>
    );
};

export default ProductFeaturePage;