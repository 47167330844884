import React from 'react';
import CandidateParameterComponent from '../../components/AptillioAdmin/CandidateParameters/CandidateParameters.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';

const CandidateParameterPage = () => {

    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<CandidateParameterComponent/>}/>
            </div>
        </div>
    );
};

export default CandidateParameterPage;
