import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  filters:{},
};
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetFilters: (state, action) => {
      state.filters = {};
    }
  }
});

export const {
    actions: filterActions,
    reducer: filterReducer,
    name: tableFilters
} = filterSlice;
