import React, { useState } from 'react'
import { FaFilter } from 'react-icons/fa';
import { selectOrganisationListingData } from '../../../redux/AptillioAdmin/manageOrganisation/manageOrganisation.selectors';
import { fetchOrganisationListing } from '../../../redux/AptillioAdmin/manageOrganisation/manageOrganisation.actions';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';
import './ManageOrganisation.component.scss';
import { MANAGE_ORGANISATIONS_HEADING } from "./ManageOrganisation.constants";
import { useSelector } from 'react-redux';

const ManageOrganisationComponent = () => {

    const statusFilterOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const filtersData = useSelector(selectFilterData)
    const [org_name, setNameFilter] = useState("")
    const [email, setEmailFilter] = useState("")
    const [contactNo, setContactNoFilter] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const inputFilterList = [
        { text:"Filter By Organization", placeholder:"Organization name", key:"org_name", value:org_name, setValue:setNameFilter},
        { text:"Filter By Organization Email", placeholder:"Organization email", key:"email", value:email, setValue:setEmailFilter},
        { text:"Filter By Phone No.", placeholder:"Phone No.", value:contactNo, key:"contactNo", setValue:setContactNoFilter, type:"number"},
        { text:"Filter By Status", placeholder:"Select", value:statusFilter, key:"status", setValue:setStatusFilter, type:"select", selectOptions:statusFilterOptions}
    ]
    
    const organisationTableColumns = [
        {
            dataField: 'org_name',
            text: 'Organization Name',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '16%'};
            }
        },
        {
            dataField: 'email',
            text: 'Organization Admin Email ID',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '22%'};
            }
        },
        {
            dataField: 'contactNo',
            text: 'Phone No.',
            headerStyle: (colum, colIndex) => {
                return { width: '12%'};
            }
        },
        {
            dataField: 'status',
            text: 'Subscription Status',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '15%'};
            },
            formatter: (cell, row, rowIndex, formatExtraData)=>{
                return <>{row?.status?.split("")[0]?.toUpperCase() + row?.status?.substring(1,row?.status?.length)}</>
            }
        },
    ];

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>{MANAGE_ORGANISATIONS_HEADING}</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-filter">
                                <ButtonEvent customStyle={{margin:0}} func={()=>setIsDrawerOpen(true)} text={"Search/Filter"} icon={<FaFilter/>}></ButtonEvent>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={organisationTableColumns} apiAction={fetchOrganisationListing} apiSelector={selectOrganisationListingData} filters={filtersData}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageOrganisationComponent