/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from 'react';
import ButtonEvent from '../../Global/inputs/button.component';
import './SubscriptionPlan.component.scss';
import subscriptionIcon from '../../../assets/subscription-icon.png';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
// import { getSubscriptionPlans } from '../../../redux/AptillioAdmin/manageSubscription/manageSubscription.actions';
import { selectSubscriptionsData } from '../../../redux/AptillioAdmin/manageSubscription/manageSubscription.selectors';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import useAuth from '../../../hooks/useAuth.hook';
import { useNavigate } from 'react-router-dom';
import { getSubscriptionPlans } from '../../../redux/AptillioAdmin/manageSubscription/manageSubscription.reducer';

const ManageSubscriptionComponent = ({}) => {
    const dispatch = useDispatch()
    const navigateTo = useNavigate()
    const { getUserAuth } = useAuth()
    const axiosInstance = useAxiosInstance()
    const subscriptionData = useSelector(selectSubscriptionsData)
    const [subscriptionPlans, setSubscriptionPlans] = useState([])

    useEffect(()=>{
        const userId = getUserAuth()?.userId
        dispatch(getSubscriptionPlans({axiosInstance, ...{userId, status:"active"}}))
    },[])

    useEffect(()=>{
        if(subscriptionData && subscriptionData?.plans){
            setSubscriptionPlans(subscriptionData?.plans?.listingData || [])
        }
    },[subscriptionData, subscriptionData.plans])

    return (
        <div className="subscriptionPlan_container">
            <div className="subscriptionPlan_wrapper">
                <div className="subscriptionPlan_main">
                    <div className="subscriptionPlan_main-header_container">
                        <div className="subscriptionPlan_main-header">
                            <p className='subscriptionPlan_main-header_text'>Subscription Details</p>
                        </div>
                    </div>
                    <div className="subscriptionPlan_main-grid">
                        {subscriptionPlans.map(plan=>{
                            return(
                                <div className={`subscriptionPlan_main-grid-plan ${plan?.activePlan?.length > 0 ? 'active_plan':undefined}`}>
                                    <div className="subscriptionPlan_main-grid-plan_icon">
                                        <img alt="plan-icon" src={subscriptionIcon}></img>
                                    </div>
                                    {plan?.activePlan?.length > 0 && <div class="ribbon-1 left">Active Plan</div>}
                                    <div className="subscriptionPlan_main-grid-plan_type">
                                        <p>{plan?.subscriptionName}</p>
                                    </div>
                                    <div className="subscriptionPlan_main-grid-plan_price">
                                        <p>{`$ ${plan?.subscriptionCost}/year`}</p>
                                    </div>
                                    <div className="subscriptionPlan_main-grid-plan_type">
                                        <p>{`Tokens : ${plan?.tokenValue}`}</p>
                                    </div>
                                    {
                                        plan?.activePlan?.length>0 ? 
                                        <div className="subscriptionPlan_main-grid-plan_type">
                                            <p>{`Balance : ${plan?.activePlan[0]?.subscription?.currentToken}`}</p>
                                        </div> : ""
                                    }
                                    <div className="subscriptionPlan_main-grid-plan_buy">
                                        {
                                        plan?.activePlan?.length>0 ? 
                                        <p className='active_plan-text'>
                                            Expires on : {new Date(plan?.activePlan[0]?.subscription?.endDate).toLocaleString('en-us',{year: 'numeric', month: 'short', day: 'numeric'})}
                                        </p>
                                        :
                                        <ButtonEvent text={"Buy Now"} func={()=>navigateTo(`/subscription/payment/${plan?._id}`)} customStyle={{backgroundColor:"#5367FD", color:"white", margin:"auto", width:"60%"}}/> 
                                        }
                                    </div>
                                    <div className="subscriptionPlan_main-grid-plan_content">
                                        <p>
                                            {plan.description}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <div className="myAccount-container-separation"></div>
                <div className="subscriptionPlan_main">
                    <div className="subscriptionPlan_main-header_container">
                        <div className="subscriptionPlan_main-header">
                            <p className='subscriptionPlan_main-header_text'>Subscription Renewal Settings</p>
                        </div>
                    </div>
                    <div className="subscriptionPlan_main-renewal">
                        <div className="subscriptionPlan_main-renewal_wrapper">
                            <div className="subscriptionPlan-renewal_content">
                                <h3 className="renewal_heading">Auto Renewal</h3>
                                <p className="renewal_subHeading">Subscripion will automatically renew after 12 months</p>
                            </div>
                            <div className="subscriptionPlan-renewal_action">
                                <ButtonEvent text={"Change To Manual Renewal"} customStyle={{backgroundColor:"#5367FD", color:"white"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subscriptionPlan_main">
                    <div className="subscriptionPlan_main-header_container">
                        <div className="subscriptionPlan_main-header">
                            <p className='subscriptionPlan_main-header_text'>Card Details</p>
                        </div>
                    </div>
                    <div className="subscriptionPlan_main-renewal">
                        <div className="subscriptionPlan_main-renewal_wrapper">
                            <div className="subscriptionPlan-renewal_content">
                                <h3 className="renewal_heading">Mastercard</h3>
                                <p className="renewal_subHeading">012x xxxx xxxx xx09 , Expires 26/11</p>
                            </div>
                            <div className="subscriptionPlan-renewal_action">
                                <ButtonEvent text={"Update Details"} customStyle={{backgroundColor:"#5367FD", color:"white"}}/>
                            </div>
                        </div>
                    </div>
                    <div className="subscriptionPlan_main-addCard">
                        <ButtonEvent text={"Add Card"} icon={<FaPlus/>} customStyle={{backgroundColor:"#5367FD", color:"white"}}/>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ManageSubscriptionComponent;