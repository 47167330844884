import React, { useEffect, useState } from 'react'
// import ReactPlayer from 'react-player';
import ButtonEvent from '../../Global/inputs/button.component';
import './AddCandidateFeedback.component.scss';
import InputEvent from "../../Global/inputs/input.component";
import AWS from 'aws-sdk';
// import { useReactMediaRecorder } from 'react-media-recorder';
import VideoRecorder from 'react-video-recorder';
// import { RecordWebcam, useRecordWebcam, CAMERA_STATUS } from 'react-record-webcam';
import { awsAccessKey, awsBucketName, awsRegion, awsSecretKey } from '../../../constants/envExports';
import { isLoading } from '../../../redux/progressLoader/progressLoader.actions';
import { useDispatch, useSelector } from 'react-redux';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { addCandidateAssessment, fetchAssessmentRatings } from '../../../redux/CompleteAssessment/CompleteAssessment.actions';
import { useNavigate, useParams } from 'react-router-dom';
import AlertDialog from '../../Global/AlertDialog/AlertDialog.component';
import { submitFeedbackValidation } from './AddCandidateFeedback.validation';
import { fetchCandidateDetail } from '../../../redux/Common/manageCandidates/manageCandidates.actions';
import { IoMdDownload } from 'react-icons/io';
import ReactPlayer from 'react-player';
import useAuth from '../../../hooks/useAuth.hook';
import AptillioToaster from '../../Global/AptillioToaster/AptillioToaster.component';
import { getCandidateNotes } from '../../../redux/Common/videoInterview/videoInterview.actions';
import AptillioProgressLoaderComponent from '../../Global/AptillioProgressLoader/AptillioProgressLoader.component';
import { selectLoaderData } from '../../../redux/progressLoader/progressLoader.selectors';

AWS.config.update({
    accessKeyId: awsAccessKey,
    secretAccessKey: awsSecretKey
})
const myBucket = new AWS.S3({
    params: { Bucket: awsBucketName },
    region: awsRegion,
})

const AddCandidateFeedbackComponent = () => {
    const dispatch = useDispatch()
    const { getUserAuth } = useAuth()
    const axiosInstance = useAxiosInstance()
    const navigateTo = useNavigate()
    const { id } = useParams()
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [recordingEnabled, setRecordingEnabled] = useState(false);
    const [notes, setNotes] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [summary, setSummary] = useState("");
    const [candidateDetail, setCandidateDetail] = useState("");
    const [additionalComments, setAdditionalComments] = useState("");
    const [ratingId, setRatingId] = useState("");
    const [assessmentRatings, setAssessmentRatings] = useState([]);
    const [confirmDialog, setConfirmDiaogue] = useState({ openAlert:false, alertMessage:""});
    const [confirmAction, setConfirmAction] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [toastList, setToastList] = useState([]);
    const loader = useSelector(selectLoaderData);

    useEffect(() => {
        //fetch candidate details in case of edit draft
            dispatch(fetchCandidateDetail(axiosInstance,{id})).then(res=>{
                setCandidateDetail(res?.candidate)
            }).catch(err=>{
                setCandidateDetail(null)
                setToastList([{id:0, title:"Fetch Error", description: err?.response?.data?.message, icon:"error"}])
            })
            dispatch(getCandidateNotes(axiosInstance,{id})).then(res=>{
                setNotes(res?.data?.candidateNote?.notes)
            }).catch(err=>{
                setNotes(null)
                setToastList([{id:0, title:"Fetch Error", description: err?.response?.data?.message || "Error while fetching notes of this candidate.", icon:"error"}])
            })
    }, [axiosInstance, dispatch, id])

    useEffect(()=>{
        dispatch(fetchAssessmentRatings(axiosInstance)).then(res=>{
            setAssessmentRatings(res?.data?.ratings || [])
        })
    },[dispatch])

    useEffect(()=>{
        if(confirmAction===true){
            setConfirmDiaogue({ openAlert:false, alertMessage:""});
            handleSubmitFeedback()
        }
    },[confirmAction])

    const handleSubmit = (e, asDraft) => {
        e?.preventDefault();
        const validationError = submitFeedbackValidation({ratingId, videoUrl, summary})
        setFormErrors(validationError);
        if (Object.keys(validationError).length === 0) {
            asDraft ? handleSubmitFeedback()
            : setConfirmDiaogue({ openAlert:true, alertMessage:`Are you sure to submit the candidate feedback details?`})
        }
    };

    const uploadVideo = async(e) => {
        // const fileReader = new File
        let file = new File([recordedBlob], 'recording', { type: 'video/webm',    lastModified: Date.now() })
        // fileReader.onload = function (e) {
        // }
        // fileReader.readAsDataURL(recordedBlob);
        dispatch(isLoading(true))
        const fileKey = `video-${new Date(Date.now()).getTime()}`;
        const params = {
            // ACL: 'public-read',
            Body: file,
            Bucket: awsBucketName,
            Key: fileKey
        };
        try {
            const upload = await myBucket.upload(params).promise();
            if(upload){
                setVideoUrl(upload?.Location)
                dispatch(isLoading(false))
                setToastList([{id:0, title:"Success", description: "Recording Uploaded Successfully", icon:"success"}])
            }
        } catch (error) {
            setToastList([{id:0, title:"Error", description: "Error while uploading the resume.", icon:"error"}])
            dispatch(isLoading(false))
        }
    };

    const handleSubmitFeedback=()=>{
        setConfirmAction(false)
        const created_by = getUserAuth().userId
        dispatch(addCandidateAssessment(axiosInstance,{ratingId, summary, videoUrl, additionalComments, candidateId :id, created_by})).then((res)=>{
            setToastList([{id:0, title:"Feedback Added", description: "Feedback response has been submitted successfully", icon:"success"}])
            setTimeout(()=>{
                navigateTo('/')
            },1500)
        }).catch((err)=>{
            setToastList([{id:0, title:"Error", description: err?.response?.data?.message || "Error while adding response.", icon:"error"}])
        })
    }

    return (
        <div className="candidateFeedback_container">
            <div className="candidateFeedback_wrapper">
                <div className="candidateFeedback_details">
                    <div className="candidateFeedback_details-content">
                        <div className="candidateFeedback_details-content_header">
                            <h2 className='header'>Candidate Details</h2>
                        </div>
                        <div className="candidateFeedback_details-content_form">
                            <div className="candidateFeedback_details-form_input_wrapper">
                                <div className="candidateFeedback_details-form_input_wrapper-label">
                                    <label className='candidate_main-input'> Name:</label>
                                </div>
                                <div className="candidateFeedback_details-form_input_wrapper-input">
                                    <p className='view_textarea-text'>{`${candidateDetail?.firstname || ""}` + " " + `${candidateDetail?.lastname || ""}`}</p>
                                </div>
                            </div>
                            <div className="candidateFeedback_details-form_input_wrapper">
                                <div className="candidateFeedback_details-form_input_wrapper-label">
                                    <label className='candidate_main-input_label'> Job Title:</label>
                                </div>
                                <div className="candidateFeedback_details-form_input_wrapper-input">
                                        <p className='view_textarea-text'>{candidateDetail?.jobRoleId?.jobTitle}</p>
                                </div>
                            </div>
                            <div className="candidateFeedback_details-form_input_wrapper">
                                <div className="candidateFeedback_details-form_input_wrapper-label">
                                    <label className='candidate_main-input_label'>Download Resume:</label>
                                </div>
                                <div className="candidateFeedback_details-form_input_wrapper-input">
                                    <p onClick={()=>window.open(candidateDetail?.resumePath)} className='view_textarea-text'>{candidateDetail?.resumePath?.split("-")[3]}
                                        {
                                            candidateDetail?.resumePath && <span style={{marginLeft: '5px', cursor:'pointer'}}><IoMdDownload/></span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="candidateFeedback_details-content_notes">
                                <div className="notes-header">
                                <h3>Notes</h3>  
                                </div>
                                <div className="notes-input">
                                    <InputEvent disabled={true} nolabel={true} type={"textarea"} value={notes} setValue={setNotes}></InputEvent>
                                </div>
                        </div>
                        <div className='candidateFeedback_details-submit'>
                            <ButtonEvent text={"Copy Notes"} theme={"secondary"}
                                func={(e)=>{
                                    navigator.clipboard.writeText(notes);
                                    setToastList([{id:0, title:"Copied", description: "Copied notes to clipboard", icon:"success"}])

                                }}
                                />
                        </div>
                    </div>
                </div>
                <div className="candidateFeedback_main">
                    <div className="candidateFeedback_main-header_container">
                        <div className="candidateFeedback_main_header">
                            <p className='candidateFeedback_main_header_text'> Candidate Feedback </p>
                        </div>
                    </div>
                    <div className="candidateFeedback_main-rating">
                        <div className='candidateFeedback_main-rating_label'>
                            <h3>Candidate Rating:</h3>
                        </div>
                        <div className='candidateFeedback_main-rating_container'>
                            {
                                assessmentRatings?.map((rating)=>{
                                    return (
                                    <div key={rating?.name} className="rating_select-wrap" style={{color: ratingId !== rating._id ? "#9da5dc" : "#3f464f"}}>
                                        <input type="radio" id="customRadio1" name="customRadio" checked={ratingId===rating._id} value={rating?._id} onChange={()=>setRatingId(rating?._id)}/>
                                        <label>{rating?.name}</label>
                                    </div>
                                    )
                            })}
                            {
                                formErrors.ratingId && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.ratingId}</p>
                            }
                        </div>
                    </div>
                    <div className="candidateFeedback_main-recording">
                        <div className='candidateFeedback_main-recording_label'>
                            <h3>Video Recording:</h3>
                        </div>
                        <div className='candidateFeedback_main-recording_container'>
                            {
                                recordingEnabled ? <div className="recording_container-video">
                                {
                                    !videoUrl ? 
                                    <>
                                        <VideoRecorder
                                        // isOnInitially
                                        isFliped
                                        showReplayControls
                                        // mimeType={text('mimeType')}
                                        countdownTime={3000}
                                        timeLimit={120000}
                                        renderLoadingView={() => {
                                            setRecordedBlob("")
                                            setVideoUrl("")
                                            // uploadVideo(videoBlob);/
                                        }}
                                        onRecordingComplete={(videoBlob) => {
                                            setRecordedBlob(videoBlob)
                                            setVideoUrl("")
                                            // uploadVideo(videoBlob);/
                                        }}
                                    />
                                    {
                                        (recordedBlob && !videoUrl) && <ButtonEvent text={"Upload Video Summary"} customStyle={{marginTop: "10px"}} theme={"secondary"} func={uploadVideo}/>
                                    }
                                </>
                                : 
                                <>
                                    <ReactPlayer width={"100%"} height={"300px"} controls={true} url={videoUrl} />
                                    {/* <div className="recording_btn"> */}
                                        <ButtonEvent text={"Start New Recording"} customStyle={{marginTop: "10px"}} theme={"secondary"} func={()=>{setVideoUrl(null); setRecordedBlob(false)}}/>
                                    {/* </div> */}
                                </>
                                }
                                
                            </div> :
                            <div className="recording_btn">
                                <ButtonEvent text={"Start Recording"} theme={"secondary"} func={()=>{setRecordingEnabled(true)}}/>
                            </div>
                            }
                            {
                                formErrors.videoUrl && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.videoUrl}</p>
                            }
                        </div>
                    </div>
                    <div className="candidateFeedback_main-summary">
                        <div className='candidateFeedback_main-summary_label'>
                            <h3>Video Transcription:</h3>
                        </div>
                        <div className='candidateFeedback_main-summary_container'>
                            <div className="summary_input">
                                <InputEvent nolabel={true} type={"textarea"} maxLength={1000} value={summary} setValue={setSummary} placeholder={"Write here....."}/>
                                {
                                formErrors.summary && <p style={{color: "red"}} className="input_field-error_text">{formErrors?.summary}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="candidateFeedback_main-summary">
                        <div className='candidateFeedback_main-summary_label'>
                            <h3>Additional Comments(Optional)</h3>
                        </div>
                        <div className='candidateFeedback_main-summary_container'>
                            <div className="summary_input">
                                <InputEvent nolabel={true} type={"textarea"} maxLength={1000} value={additionalComments} setValue={setAdditionalComments} placeholder={"Additional info....."}/>
                            </div>
                        </div>
                    </div>
                    <div className="candidateFeedback_main-actions">
                        <div className="candidateFeedback_main-actions_btn">
                            <ButtonEvent theme={"secondary"} text={"Submit"} func={handleSubmit}/>
                        </div>
                        {/* <div className="candidateFeedback_main-actions_btn">
                            <ButtonEvent text={"Save As Draft"} customStyle={{color:"#5165F8", backgroundColor:"white"}}/>
                        </div> */}
                    </div>
                </div>
            </div>
            <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
            <AlertDialog
                openAlert={confirmDialog?.openAlert}
                alertMessage={confirmDialog?.alertMessage}
                setConfirmDiaogue = {setConfirmDiaogue}
                setConfirmAction={setConfirmAction}/>
            {
                loader && <AptillioProgressLoaderComponent/>
            }
        </div>
    )
}

export default AddCandidateFeedbackComponent;