import { filterActions } from './tableFilter.reducer';

const { setFilters, resetFilters } = filterActions;

export const addFilters =(filters) => async (dispatch) => {
    dispatch(setFilters(filters))
};

export const removeFilters =() => async (dispatch) => {
    dispatch(setFilters({}))
};
