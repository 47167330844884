import React from 'react';
import ManageReportsComponent from '../../components/AptillioAdmin/ManageReports/ManageReports.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';

const ManageReportsPage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageReportsComponent/>}/>
            </div>
        </div>
    );
};

export default ManageReportsPage;
