import React, { useEffect, useState } from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth.hook';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { fetchAssessmentTypes, fetchCandidateJobRoles, fetchCandidatesListing, updateCandidateDetail } from '../../../redux/Common/manageCandidates/manageCandidates.actions';
import { selectCandidateListingData } from '../../../redux/Common/manageCandidates/manageCandidates.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import { AiOutlineArrowLeft } from "react-icons/ai";
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';
import { MANAGE_CANDIDATES_BP_DASHBOARD, MANAGE_CANDIDATES_HEADING, MANAGE_CANDIDATES_HIRING_DASHBOARD, MANAGE_CANDIDATES_SELF_HIRING_DASHBOARD } from './ManageCandidate.constants';

const ManageCandidateComponent = () => {
    const { getUserAuth } = useAuth()
    const navigateTo = useNavigate()
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const filtersData = useSelector(selectFilterData)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [jobRole, setJobRoleFilter] = useState("")
    const [userType, setUserType] = useState("")
    const [assessmentStatus, setAssessmentStatusFilter] = useState("")
    const [jobRoleOptions, setJobRolesOptions] = useState([]);
    const [assessmentTypeOptions, setAssessmentTypeOptions] = useState([]);
    const [ candidateTableColumns, setCandidateColumns] = useState([
        {
            dataField: 'firstname',
            text: 'Name',
            sort: true,
            formatter: (cell,row, rowIndex, formatExtraData)=>{
                return <span>{row?.firstname} {row?.lastname}</span>
            }
        },
        {
            dataField: 'email',
            text: 'Candidate Email',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '18%'};
            }
        },
        {
            dataField: 'orgName',
            text: 'Organization',
            sort: true,
        },
        {
            dataField: 'jobRole',
            text: 'Job Role',
        },
        {
            dataField: 'assessmentStatus',
            text: 'Assessment Status',
        },
    ]);

    const inputFilterList = [
        { text:"Filter By Job Role",  type:"select", selectOptions:jobRoleOptions, key:"jobRole", value:jobRole, setValue:setJobRoleFilter,},
        { text:"Filter By Assessment Status", type:"select", selectOptions:assessmentTypeOptions, key:"assessmentStatus", value:assessmentStatus, setValue:setAssessmentStatusFilter},
    ]
    
    useEffect(() => {
        dispatch(fetchAssessmentTypes(axiosInstance,setAssessmentTypeOptions))
        dispatch(fetchCandidateJobRoles(axiosInstance,setJobRolesOptions))
    }, [])

    useEffect(()=>{
        let user= getUserAuth()
        setUserType(user.userType)
        if(user.userType !== "business-psychologist"){
            setCandidateColumns([
                {
                    dataField: 'firstname',
                    text: 'Name',
                    sort: true,
                    formatter: (cell,row, rowIndex, formatExtraData)=>{
                        return <span>{row?.firstname} {row?.lastname}</span>
                    }
                },
                {
                    dataField: 'email',
                    text: 'Candidate Email',
                    sort: true,
                    headerStyle: (colum, colIndex) => {
                        return { width: '16%'};
                    }
                },
                {
                    dataField: 'orgName',
                    text: 'Organization',
                    sort: true,
                },
                {
                    dataField: 'jobRole',
                    text: 'Job Role',
                },
                {
                    dataField: 'assessmentStatus',
                    text: 'Assessment Status',
                    
                },
                {
                    dataField: 'bpAssign',
                    text: 'Assigned BP',
                    headerStyle: (colum, colIndex) => {
                        return { width: '8%'};
                    },
                    formatter: (cell,row, rowIndex, formatExtraData)=>{
                        return <span>{row?.bpFirstName ? row?.bpFirstName : "Not Assigned"} {row?.bpLastName}</span>
                    }
                }
            ])
        }
        if(user.userType === "aptillio-admin"){
            setCandidateColumns([
                {
                    dataField: 'firstname',
                    text: 'Name',
                    sort: true,
                    formatter: (cell,row, rowIndex, formatExtraData)=>{
                        return <span>{row?.firstname} {row?.lastname}</span>
                    }
                },
                {
                    dataField: 'email',
                    text: 'Email',
                    sort: true,
                },
                {
                    dataField: 'orgName',
                    text: 'Organization',
                    sort: true,
                },
                {
                    dataField: 'hmFirstName',
                    text: 'Hiring Manager',
                    headerStyle: (colum, colIndex) => {
                        return { width: '11%'};
                    },
                    formatter: (cell,row, rowIndex, formatExtraData)=>{
                        return <span>{row?.hmFirstName} {row?.hmLastName}</span>
                    }
                },
                {
                    dataField: 'jobRole',
                    text: 'Job Role',
                },
                {
                    dataField: 'assessmentStatus',
                    text: 'Assess. Status',
                },
                {
                    dataField: 'bpAssign',
                    text: 'Assigned BP',
                    formatter: (cell,row, rowIndex, formatExtraData)=>{
                        return <span>{row?.bpFirstName ? row?.bpFirstName : "Not Assigned"} {row?.bpLastName}</span>
                    }
                }
            ])
        }
    },[getUserAuth])

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>
                                {userType==="hiring-manager"? MANAGE_CANDIDATES_HIRING_DASHBOARD: userType==="org-admin"? MANAGE_CANDIDATES_SELF_HIRING_DASHBOARD : userType==="business-psychologist"? MANAGE_CANDIDATES_BP_DASHBOARD :MANAGE_CANDIDATES_HEADING}
                            </p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-filter">
                                <ButtonEvent func={()=>setIsDrawerOpen(true)} text={userType!=="org-admin" ? "Search/Filter":""} icon={<FaFilter/>}></ButtonEvent>
                            </div>
                            {
                                userType==="org-admin" &&
                                <div className="dashboard_main-listing_header_actions-filter">
                                    <ButtonEvent func={()=>navigateTo("/manageHiringManager")} icon={<AiOutlineArrowLeft/>} text={"Back To Organization Dashboard"}></ButtonEvent>
                                </div>
                            }
                            <div className="dashboard_main-listing_header_actions-search">
                                {(userType==="hiring-manager" || userType==="org-admin") &&
                                    <ButtonEvent text={"Submit Candidate"} customStyle={{backgroundColor:"#5367FD", color:"white"}} func={()=>{navigateTo(`/manageCandidate/add`)}} icon={<FaPlus/>}></ButtonEvent>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={candidateTableColumns} apiAction={fetchCandidatesListing} apiSelector={selectCandidateListingData} filters={filtersData}
                        dispatchActions={{updateCandidateAssessment:updateCandidateDetail}}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageCandidateComponent;