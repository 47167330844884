import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AptillioToaster from '../../components/Global/AptillioToaster/AptillioToaster.component';
import ButtonEvent from '../../components/Global/inputs/button.component';
import InputEvent from '../../components/Global/inputs/input.component';
import { resetPasswordAction } from '../../redux/users/user.actions';
import { passwordToolTip } from './resetPassword.constant';
import './resetPassword.styles.scss';
import { validate } from './resetPasswordValidation';

const ForgetPasswordPage = () => {
    
    const dispatch = useDispatch();
    const navigateTo = useNavigate()
    const location = useLocation()
    const [password, setPassword] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState(null)
    const [toastList, setToastList] = useState([]);
    
    useEffect(() => {
        return()=>{
            clearInterval()
        }
    }, []);

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleResetPassword()
        }
    }, [formErrors, isSubmit]);
    
    const onVisibleClick=()=>{
        setPasswordVisible(!passwordVisible)
    }

    const handleResetPassword = () => {
        const routeSplit = location?.pathname.split('/')
        const urlToken = routeSplit[routeSplit.length-1]
        dispatch(resetPasswordAction({password, passwordConfirm: confirmPassword ,urlToken})).then(res=>{
            setToastList([{id:0, title:"Reset Password Success", description: "Password has been updated succcessfully.", icon:"success"}])
            setTimeout(()=>{
                navigateTo('/login')
            },1500)
        }).catch((err)=>{
            setToastList([{id:0, title:"Reset Password Error", description: err?.response?.data?.message, icon:"error"}])
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setApiErrorMessage(null)
        setFormErrors(validate({password, confirmPassword}));
        setIsSubmit(true);
    };
    
    return (
        <div className="reset-container">
            <div className="reset">
                <div className="reset__child resetpage__child-right">
                    <div className="reset__child__textbox reset__child__textbox-right">
                        <div className='aptillioLogo'><img src="/logo-one.png" alt="logo" /></div>
                        <br />
                        <div className="reset__heading">
                            <h3>Reset Password</h3>
                        </div>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <InputEvent text="Password" maxLength={16} required={true} type={passwordVisible ? "text" : "password"} 
                                value={password} setValue={setPassword} icon={passwordVisible ? "/icons/visible-eye.png" : "/icons/not-visible-eye.png"} 
                                iconFunc={onVisibleClick} tooltipText={passwordToolTip}/>
                            <br />
                            {formErrors.password && 
                                <>
                                    <p className='input-error'>{formErrors?.password}</p>
                                    <br></br>
                                </>
                            }
                            <InputEvent text="Confirm Password" required={true} id="reset_confirm_pass" type={"text"} value={confirmPassword} setValue={setConfirmPassword}/>
                            <br />
                            {formErrors.confirmPassword && 
                                <>
                                    <p className='input-error'>{formErrors?.confirmPassword}</p>
                                    <br></br>
                                </>
                            }
                            <ButtonEvent text={"PROCEED"} customStyle={{backgroundColor: '#5265f9', color: '#fff'}} />
                            <br />
                            {apiErrorMessage && 
                                <>
                                    <p className='input-error'>{apiErrorMessage}</p>
                                    <br></br>
                                </>
                            }
                        </form>
                    </div>
                </div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
            </div>
        </div>
    );
};

export default ForgetPasswordPage;
