import React, { useState } from 'react'
import ButtonEvent from '../../../Global/inputs/button.component';
import InputEvent from '../../../Global/inputs/input.component';
import './InterviewDrawer.component.scss';
import { IoMdDownload } from 'react-icons/io';

const InterviewDrawerComponent = ({candidateDetail, addCandidateNotes}) => {
  const [notes, setNotes] = useState("");

  return (
    <div className="interviewDrawer-container">
      <div className="interviewDrawer-wrapper">
        <div className="interviewDrawer-content">
            <div className="interviewDrawer-content_header">
                <h2 className='header'>Candidate Details</h2>
            </div>
            <div className="interviewDrawer-content_form">
                <div className="interviewDrawer-form_input_wrapper">
                    <div className="interviewDrawer-form_input_wrapper-label">
                        <label className='candidate_main-input'> Name:</label>
                    </div>
                    <div className="interviewDrawer-form_input_wrapper-input">
                        <p className='view_textarea-text'>{`${candidateDetail?.firstname || ""}` + " " + `${candidateDetail?.lastname || ""}`}</p>
                    </div>
                </div>
                <div className="interviewDrawer-form_input_wrapper">
                    <div className="interviewDrawer-form_input_wrapper-label">
                        <label className='candidate_main-input_label'> Job Title:</label>
                    </div>
                    <div className="interviewDrawer-form_input_wrapper-input">
                            <p className='view_textarea-text'>{candidateDetail?.jobRoleId?.jobTitle}</p>
                    </div>
                </div>
                <div className="interviewDrawer-form_input_wrapper">
                    <div className="interviewDrawer-form_input_wrapper-label">
                        <label className='candidate_main-input_label'>Download Resume:</label>
                    </div>
                    <div className="interviewDrawer-form_input_wrapper-input">
                            <p onClick={()=>window.open(candidateDetail?.resumePath)} className='view_textarea-text'>{candidateDetail?.resumePath.split("-")[3]}
                                {candidateDetail?.resumePath && <span style={{marginLeft: '5px', cursor:'pointer'}}><IoMdDownload/></span>}
                            </p>
                    </div>
                </div>
            </div>
            <div className="interviewDrawer-content_notes">
                <div className="notes-header">
                  <h3>Add Notes</h3>  
                </div>
                <div className="notes-input">
                    <InputEvent nolabel={true} type={"textarea"} value={notes} setValue={setNotes}></InputEvent>
                </div>
            </div>
        </div>
        <div className='interviewDrawer-submit'>
            <ButtonEvent text={"Save Notes"} customStyle={{color:"#5165F8", backgroundColor:"white"}}
                    func={(e)=>addCandidateNotes(notes)}/>
        </div>
      </div>
    </div>
  );
}
  

export default InterviewDrawerComponent;