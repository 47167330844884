import React from 'react';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';
import ManageBusinessPsychologistComponent from '../../components/AptillioAdmin/ManageBusinessPsychologist/ManageBusinessPsychologist.component';

const ManageBusinessPsychologistPage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageBusinessPsychologistComponent/>}/>
            </div>
        </div>
    );
};

export default ManageBusinessPsychologistPage;
