import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import AptillioToaster from '../AptillioToaster/AptillioToaster.component';
import ButtonEvent from '../inputs/button.component';
import InputEvent from '../inputs/input.component';
import './MyAccount.component.scss';
import { myAccountFormValidation } from './MyAccountValidation';
import AlertDialog from '../AlertDialog/AlertDialog.component';
import { fetchMyAccountUserDetail, updateMyAccountUserDetail, updateMyAccountUserPassword, } from '../../../redux/Common/myAccount/myAccount.actions';
import useAuth from '../../../hooks/useAuth.hook';
import { BiInfoCircle } from 'react-icons/bi';
import { passwordToolTip } from '../../../pages/resetPassword/resetPassword.constant';

const MyAccountComponent = () => {
    const dispatch = useDispatch()
    const { getUserAuth } = useAuth()
    const axiosInstance = useAxiosInstance()
    const [firstname, setFirstName] = useState("")
    const [lastname, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [currentPassword, setOldPassword] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [org_name, setOrganizationName] = useState("")
    const [accountDetail, setAccountDetail] = useState(null)
    const [toastList, setToastList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [isDetailSubmit, setDetailSubmit] = useState(false);
    const [passwordSubmit, setPasswordSubmit] = useState(false);
    const [confirmDialog, setConfirmDiaogue] = useState({ openAlert:false, alertMessage:""});
    const [confirmAction, setConfirmAction] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);

    useEffect(() => {
        dispatch(fetchMyAccountUserDetail(axiosInstance)).then((res)=>{
            setAccountDetail(res?.user)
            setFirstName(res?.user?.firstname)
            setEmail(res?.user?.email)
            setLastName(res?.user?.lastname)
            setContactNo(res?.user?.contactNo)
            setOrganizationName(res?.user?.org_name)
            // setUserType(res?.user?.userType)
        }).catch((err)=>{
            setToastList([{id:0, title:"Fetch Error", description: err?.response?.data?.message, icon:"error"}])
        })
    }, [])



    useEffect(()=>{
        const userId = getUserAuth().userId
        if(confirmAction===true && isDetailSubmit){
            let apiData={}
            accountDetail?.userTypeId?.name ==="org-admin" ? apiData={ org_name, firstname, lastname, email, contactNo, id: userId} :
            apiData={ firstname, lastname, email, contactNo, id: userId}
            dispatch(updateMyAccountUserDetail(axiosInstance,apiData)).then(res=>{
                setToastList([{id:0, title:"Account Detail Upated", description: "Account data has been updated successfully.", icon:"success"}])
            }).catch(err=>{
                setToastList([{id:0, title:"Update Error", description: err?.response?.data?.message || "seems like there's a issue with the server.", icon:"error"}])
            });
            onUpdateApiResponse()
        }else if(confirmAction===true && passwordSubmit){
            dispatch(updateMyAccountUserPassword(axiosInstance,{ currentPassword, password, id:userId})).then(res=>{
                setPassword("")
                setPasswordConfirm("")
                setOldPassword("")
                setToastList([{id:0, title:"Account Password Upated", description: "Password has been updated successfully.", icon:"success"}])
            }).catch(err=>{
                setToastList([{id:0, title:"Update Error", description: err?.response?.data?.message || "seems like there's a issue with the server.", icon:"error"}])
            });
            onUpdateApiResponse()
        }
        setDetailSubmit(false)
        setPasswordSubmit(false)
    },[confirmAction])
    
    const onUpdateApiResponse=()=>{
        setConfirmDiaogue({ openAlert:false, alertMessage:""});
        setConfirmAction(false)
        setDetailSubmit(false)
        setPasswordSubmit(false)
    }

    const handleSubmit = (e) => {
        e?.preventDefault();
        setFormErrors(myAccountFormValidation("detail",{firstname, lastname, email, contactNo}));
        setDetailSubmit(true);
    };

    const handleChangePassword = (e) => {
        e?.preventDefault();
        setFormErrors(myAccountFormValidation("password",{ password, passwordConfirm, currentPassword}));
        setPasswordSubmit(true);
    };

    useEffect(()=>{
        return()=>{
            clearTimeout()
            setDetailSubmit(false)
            setPasswordSubmit(false)
            setConfirmAction(false)
        }
    },[])

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isDetailSubmit) {
            setConfirmDiaogue({ openAlert:true, alertMessage:`Are you sure to update account details?`})
        }
        else if (Object.keys(formErrors).length === 0 && passwordSubmit) {
            setConfirmDiaogue({ openAlert:true, alertMessage:`Are you sure to update your password?`})
        }

    }, [formErrors]);
    
    return (
        <div className="myAccount-container">
            <div className="myAccount-wrapper">
                <div className="myAccount_main">
                    <div className="myAccount_main-header_container">
                        <div className="myAccount_main-listing_header">
                            <p className='myAccount_main-listing_header_text'>Account Details</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="myAccount_main-inputs">
                            <div className="myAccount_main-inputs_wrapper" style={{justifyContent:"flex-end"}}>
                                    <label className='account_main-input_label'><span style={{fontSize:"1.5rem", color: "red"}}>*</span> Required Fields</label>
                            </div>
                            <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> First Name <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} value={firstname} setValue={setFirstName} placeholder={"Enter First Name"}/>
                                    {
                                        formErrors.account_name && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.account_name}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> Last Name <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} value={lastname} setValue={setLastName} placeholder={"Enter Last Name"}/>
                                    {
                                        formErrors.account_name && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.account_name}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            {accountDetail && accountDetail?.userTypeId?.name==="org-admin" && <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> Organization Name <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} value={org_name} setValue={setOrganizationName} placeholder={"Enter Organization Name"}/>
                                    {
                                        formErrors.org_name && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.org_name}</p>
                                        </>
                                    }
                                </div>
                            </div>}
                            <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> Phone Number <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} type="number" value={contactNo} setValue={setContactNo} placeholder={"Enter 10 Digit Phone Number"}/>
                                    {
                                        formErrors.contactNo && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.contactNo}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> Email ID</label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} value={email} setValue={setEmail} disabled/>
                                    {
                                        formErrors.email && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.email}</p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="myAccount_main-action">
                            <div className="myAccount_main-action_button">
                                <ButtonEvent text={"Save"} func={handleSubmit}/>
                            </div>
                        </div>
                    </form>
                </div>
                <AptillioToaster
                    toastList={toastList}
                    position={"top-right"}
                    autoDelete={true}
                    autoDeleteTime={2500}
                />
                <AlertDialog 
                    openAlert={confirmDialog?.openAlert}
                    alertMessage={confirmDialog.alertMessage}
                    setConfirmDiaogue = {setConfirmDiaogue}
                    setConfirmAction={setConfirmAction}
                />
            </div>
            <div className="myAccount-container-separation"></div>
            <div className="myAccount-wrapper">
                <div className="myAccount_main">
                    <div className="myAccount_main-header_container">
                        <div className="myAccount_main-listing_header">
                            <p className='myAccount_main-listing_header_text'>Change Password</p>
                        </div>
                    </div>
                    <form onSubmit={(e)=>e.preventDefault()}>
                        <div className="myAccount_main-inputs">
                            <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> Old Password<span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} placeholder={"Enter your old password"} required={true} type={oldPasswordVisible ? "text" : "password"} 
                                        value={currentPassword} setValue={setOldPassword} icon={oldPasswordVisible ? "/icons/visible-eye.png" : "/icons/not-visible-eye.png"} 
                                        iconFunc={()=>setOldPasswordVisible(!oldPasswordVisible)}/>
                                    {
                                        formErrors.currentPassword && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.currentPassword}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> New Password <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span><span className='info-icon' data-tip={passwordToolTip}><BiInfoCircle/></span></label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} placeholder={"Enter new password"} maxLength={16} required={true} type={passwordVisible ? "text" : "password"} 
                                        value={password} setValue={setPassword} icon={passwordVisible ? "/icons/visible-eye.png" : "/icons/not-visible-eye.png"} 
                                        iconFunc={()=>setPasswordVisible(!passwordVisible)}/>
                                    {
                                        formErrors.password && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.password}</p>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="myAccount_main-inputs_wrapper account_name-input">
                                <div className="account_main-label">
                                    <label className='account_main-input_label'> Confirm New Password <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span></label>
                                </div>
                                <div className="account_main-input">
                                    <InputEvent nolabel={true} required={true} id="reset_confirm_pass" placeholder={"Confirm your password"} type={"text"} value={passwordConfirm} setValue={setPasswordConfirm}/>
                                    {
                                        formErrors.passwordConfirm && 
                                        <>
                                            <p style={{color: "red"}} className="input_field-error_text">{formErrors?.passwordConfirm}</p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="myAccount_main-action">
                            <div className="myAccount_main-action_button">
                                <ButtonEvent text={"Save"} func={handleChangePassword}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MyAccountComponent;