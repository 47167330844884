import { createContext, useCallback, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    useEffect(()=>{
        setAuth({
            aptillioToken: localStorage.getItem("aptillioToken"),
            refreshToken: localStorage.getItem("refreshToken"),
            userId: localStorage.getItem("userId"),
            userType: localStorage.getItem("userType"),
            keepMeloggedIn: localStorage.getItem("keepMeLoggedIn"),
            is_HM: localStorage.getItem("is_HM")
        })
    },[])

    const getUserAuth=useCallback(()=>{
        return {
            aptillioToken: localStorage.getItem("aptillioToken"),
            refreshToken: localStorage.getItem("refreshToken"),
            userId: localStorage.getItem("userId"),
            userType: localStorage.getItem("userType"),
            keepMeloggedIn: localStorage.getItem("keepMeLoggedIn"),
            is_HM: localStorage.getItem("is_HM")
        }
    },[])

    const removeUserAuth =()=>{
        localStorage.removeItem("aptillioToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("userType");
        localStorage.removeItem("is_HM");
        localStorage.setItem("keepMeLoggedIn", false);
        setAuth({})
    }

    const createUserAuth=(accessToken, refreshToken, userId, userType, is_HM, created_by)=>{
        localStorage.setItem("aptillioToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("userId", userId);
        localStorage.setItem("userType", userType)
        localStorage.setItem("is_HM", is_HM);
        if(userType==="hiring-manager"){
            localStorage.setItem("organizationId", created_by);
        }else if(is_HM){
            localStorage.setItem("organizationId", userId);
        }
    }

    return (
        <AuthContext.Provider value={{ auth, createUserAuth, removeUserAuth ,getUserAuth }}>
            {children}
        </AuthContext.Provider>
    )
    
}
export default AuthContext;
// export const useAuth = () => useContext(AuthContext);