import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authProvider.context';
import useAxiosInstance from '../hooks/useAxiosInstance';
import { fetchCheckSubscription } from '../redux/AptillioAdmin/manageSubscription/manageSubscription.actions';
// import useAuth from '../hooks/useAuth.hook';

const PrivateRoute = ({ children, path }) => {
    const dispatch = useDispatch()
    const navigateTo = useNavigate()
    const axiosInstance = useAxiosInstance()
    const isUserLoggedIn = localStorage.getItem("keepMeLoggedIn");
    const aptillioToken = localStorage.getItem("aptillioToken");
    if (!aptillioToken && path !=='/login') return <Navigate exact to="/login" />
    if (aptillioToken && isUserLoggedIn == null && path !== '/login') return <Navigate exact to="/login" />;
    const userType = localStorage.getItem("userType");
    if (userType==="org-admin" && path!=='/login' && path!=="/subscriptionPlans" && path!=="/productFeature" && path!=="/subscription/payment/:id"){
        dispatch(fetchCheckSubscription(axiosInstance, {})).then(res=>{
            if(res.data.data.result.length===0) {
                return navigateTo('/subscriptionPlans')
            }
        })
    }
    return children;
};

export default PrivateRoute;
