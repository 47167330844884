import './ProductFeature.component.scss';
import manageCandidateImage from '../../../assets/aptillio-ui-images/Manage_candidates_image@2x.png';
import submitCandidateImage from '../../../assets/aptillio-ui-images/submit_candidates_image@2x.png';
import buySubscriptionImage from '../../../assets/aptillio-ui-images/buy_Subscription_image.png';
import ButtonEvent from '../../Global/inputs/button.component';
import { useNavigate } from 'react-router-dom';

const ProductFeatureComponent = () => {
    const navigateTo = useNavigate()
    return (
        <div className="productFeature_container">
            <div className="productFeature_wrapper">
                <div className="productFeature_main">
                    <div className="productFeature_main-header_container">
                        <div className="productFeature_main-header">
                            <h1 className='productFeature_main-header_text'>Aptillio Features</h1>
                        </div>
                        <div className="productFeature_main-sub_header">
                            {/* <h3 className='productFeature_main-sub_header_text'>Free trial for 10 days</h3> */}
                        </div>
                    </div>
                    <div className="productFeature_main-content">
                        <div className="productFeature_main-submitCandidate">
                            <div className="productFeature_main-product_image">
                                <img alt="product-feature-icon" src={submitCandidateImage}></img>
                            </div>
                            <div className="productFeature_main-product_info">
                                <div className="productFeature_main-product_info-header">
                                    <h2>Submit Candidates</h2>
                                </div>
                                <div className="productFeature_main-product_info-text">
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus, facilis magni repudiandae aspernatur commodi ipsa hic doloribus vero, 
                                        molestiae repellat explicabo eligendi. Suscipit, velit. Aperiam quaerat et ea mollitia eligendi.</p>
                                </div>
                            </div>
                        </div>
                        <div className="productFeature_main-manageCandidate">
                            <div className="productFeature_main-product_image">
                                <img alt="product-feature-icon" src={manageCandidateImage}></img>
                            </div>
                            <div className="productFeature_main-product_info">
                                <div className="productFeature_main-product_info-header">
                                    <h2>Manages Candidates</h2>
                                </div>
                                <div className="productFeature_main-product_info-text">
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus, facilis magni repudiandae aspernatur commodi ipsa hic doloribus vero, 
                                        molestiae repellat explicabo eligendi. Suscipit, velit. Aperiam quaerat et ea mollitia eligendi.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="productFeature_main-buySubscription">
                        <div className="productFeature_main-buySubscription_wrapper">
                            <img alt="buy-subscription-img" src={buySubscriptionImage}></img>
                            <div className="productFeature_main-buySubscription_content">
                                <p className='productFeature_main-buySubscription_content-header'>Buy Subscription as low as $100/-</p>
                                <p className='productFeature_main-buySubscription_content-text'>Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                In quia pariatur quibusdam atque asperiores sint earum sunt dolor, dolorem neque.</p>
                                <div className='productFeature_main-buySubscription_content-btn'>
                                    <ButtonEvent func={()=>navigateTo("/subscriptionPlans")} customStyle={{width:"20%"}} text={"View Plans"}></ButtonEvent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductFeatureComponent;