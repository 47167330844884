import { endpoints } from '../../../constants/url.endpoint';
import { candidateParameterActions } from './candidateParameter.reducer';
const { candidateParameterListingSuccess, resetCandidateParameterAllData, viewCandidateParameterSuccess, resetCandidateParameterDetail} = candidateParameterActions;

export const fetchCandidateParameterListing =(axiosInstance ,apiData) => async (dispatch) => {
    if(apiData.sortBy ==="label"){
        apiData.sortBy = "paramName";
    }
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: endpoints.candidateParams,
                method: 'GET',
                params:apiData
            }).then((res) => {
                dispatch(candidateParameterListingSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetCandidateParameterAllData());
                reject(err)
            });
    })
};

export const addCandidateParameter =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: endpoints.addCandidateParam,
                method: 'POST',
                data:apiData
            }).then((res) => {
                dispatch(viewCandidateParameterSuccess(res?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetCandidateParameterDetail());
                reject(err)
            });
    })
};

export const fetchCandidateParameter =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.candidateParams}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                dispatch(viewCandidateParameterSuccess(res?.data));
                resolve(res?.data?.data?.data)
            }).catch(err => {
                dispatch(resetCandidateParameterDetail());
                reject(err)
            });
    })
};

export const updateCandidateParameter =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.candidateParams}/${apiData._id}`,
                method: 'PATCH',
                data: apiData,
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const deleteCandidateParameter =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.candidateParams}/${apiData.id}`,
                method: 'DELETE',
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};