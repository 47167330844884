// import axios from '../api/axios';
import { axiosPrivate } from '../constants/axiosInstance';
// import useAuth from './useAuth.hook';

const useRefreshToken = () => {
    // const { setAuth } = useAuth();

    const refresh = async () => {
        try{
            const response = await axiosPrivate({
                url: `/user/refresh`,
                method: 'POST',
                data: {
                  "refreshToken" : localStorage.getItem("refreshToken"),
                  "id": localStorage.getItem("userId"),
                }
            });
            return response;
        }
        catch(err){
            return err
        }
        // setAuth(prev => {
            // return { ...prev, accessToken: response.data.accessToken }
        // });
    }
    return refresh;
};

export default useRefreshToken;