import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAxiosInstance from '../../hooks/useAxiosInstance';
import { checkAssessmentStatus } from '../../redux/CompleteAssessment/CompleteAssessment.actions';
import '../CompleteAssessment/CompleteAssessment.styles.scss';

const CheckAssessmentStatusPage = () => {
    const dispatch = useDispatch();
    const { id } = useParams()
    const axiosInstance = useAxiosInstance()
    const [assessmentError, setAssessmentError] = useState("")
    
    useEffect(()=>{
        dispatch(checkAssessmentStatus(axiosInstance, { id })).then((res)=>{
            // window.open(res?.data?.url, '_blank')
            window.location.href = res?.data?.url; //For redirection
            setAssessmentError("Please complete your online written test on the given link.")
        })
        .catch(err=>{
            setAssessmentError(err?.response?.data?.message)
        })
    },[])

    return (
        <div className="forgetPassword-container">
            <div className="forgetPassword">
                <div className="forgetPassword__child forgetPasswordpage__child-right">
                    <div className="forgetPassword__child__textbox forgetPassword__child__textbox-right">
                        <div className='center'><img src="/logo-one.png" alt="logo" /></div>
                        <br/>
                        <div className="forgetPassword__heading">
                            <h3>Written Test Status</h3>
                        </div>
                        <div className="forgetPassword-success">
                            <h4>
                                <br></br> 
                                {/* Your "Online Assessment Test" submission has been sent. */}
                                <br></br>
                                {assessmentError}
                            </h4>
                        </div> : 
                    <br></br>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckAssessmentStatusPage;
