/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../aptllioTable/table.component.scss';
import { useDispatch } from 'react-redux';
import { removeFilters } from '../../../redux/Common/tablefilter/tableFilter.actions';
import useAuth from '../../../hooks/useAuth.hook';

const InnerListingComponent = ({columns, tableData, actions}) => {
    const { auth } = useAuth()
    // const axiosInstance = useAxiosInstance()
    // const [pageSize, setPageSize] = useState(10);
    // const [page, setPage] = useState(1);
    const [dataList, setDataList] = useState([]);
    // const [totalData, setTotalData] = useState("");
    const [apiDefaultData, setApiDefaultData] = useState({});
    // const [sortData, setSortData] = useState({})
    const [selectedRowIndex, setSelectedRowIndex] = useState("")
    
    const dispatch = useDispatch();
    const actionMenuRef = useRef()

    useEffect(()=>{
        dispatch(removeFilters())

        //for listening to on-click event for table actions
        function handler(event) {
            if(!actionMenuRef.current?.contains(event.target)) {
                setSelectedRowIndex("")
            }
        }
        window.addEventListener('click', handler)
        return () => window.removeEventListener('click', handler)
    },[])

    useEffect(() => {
        if(auth?.userType !== "aptillio-admin"){
            const userId = auth?.userId
            setApiDefaultData({userId: userId})
        }
    }, [auth])


    useEffect(()=>{
        setDataList(tableData || [])
    },[tableData])

    const pageButtonRenderer = ({
        page,
        active,
        text,
        // disabled,
        // title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        // ....
        return (
            <li key={page + Math.random()} className={active ? "active page-item" : "page-item"}>
                <a href="#" onClick={handleClick}>{page}</a>
            </li>
        );
    };

    return (
        <div className="table_container">
            <div className="table_wrapper">
                <div className="table_search">
                </div>
                <div className="table_main">
                    <BootstrapTable headerWrapperClasses="table_main-header" keyField='_id' data={ dataList } columns={ columns }
                    bootstrap4
                    striped
                    hover
                    condensed
                    remote
                    bordered={ false }
                    noDataIndication="No Records Found."/>
                </div>
            </div>
        </div>
    )
}

export default InnerListingComponent;