import React from 'react';
import ViewOrganisationComponent from '../../../components/AptillioAdmin/EditOrganisationDetails/EditOrganisationDetails.component';
import HomeComponent from '../../../components/Global/HomeLayout/HomeLayout.component';

const ViewOrganisationPage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ViewOrganisationComponent/>}/>
            </div>
        </div>
    );
};

export default ViewOrganisationPage;
