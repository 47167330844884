import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCandidateParameterListing } from '../../../redux/AptillioAdmin/candidateParameter/candidateParameter.actions';
import { selectCandidateParameterListingData } from '../../../redux/AptillioAdmin/candidateParameter/candidateParameter.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';

const CandidateParameterComponent = () => {
    const navigateTo = useNavigate()
    const filtersData = useSelector(selectFilterData)
    const [statusFilter, setStatusFilter] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const statusFilterOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const inputFilterList = [
        { text:"Filter By Status", placeholder:"Select", value:statusFilter, key:"status", setValue:setStatusFilter, type:"select", selectOptions:statusFilterOptions}
    ]
    const candidateParamsTableColumns = [
        {
            dataField: 'label',
            text: 'Candidate Parmeters',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (cell, row, rowIndex, formatExtraData)=>{
                return <>{row?.status?.split("")[0]?.toUpperCase() + row?.status?.substring(1,row?.status?.length)}</>
            }
        }
    ];

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>Candidate Parameters</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-search">
                                <ButtonEvent text={"Add Candidate Paramter"} func={()=>navigateTo("./add")} customStyle={{backgroundColor:"#5367FD", color:"white"}} icon={<FaPlus/>}></ButtonEvent>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={candidateParamsTableColumns} apiAction={fetchCandidateParameterListing} apiSelector={selectCandidateParameterListingData} filters={filtersData}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default CandidateParameterComponent