import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AddManageDataComponent from '../../../components/Global/AddManageDataForm/AddManageDataForm.component';
import { jobRoleFieldValidation } from '../../../components/Global/AddManageDataForm/JobRoleFieldsValidations';
import HomeComponent from '../../../components/Global/HomeLayout/HomeLayout.component';
import { addJobRole, fetchJobRoleDetail, updateJobRoleDetail } from '../../../redux/Common/manageJobRole/manageJobRole.actions';

const AddJobRolePage = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [jobTitle, setJobTitle] = useState("")
    const [jobDescription, setJobDescription] = useState("")
    const [formConstants, setFormConstants] = useState(null)
    const [inputFields, setInputFields] = useState(
        [
            { label:"Job Title", type:"text", placeholder:"Enter Job Title", key:"jobTitle", value:jobTitle, setValue:setJobTitle, required:true},
            { label:"Job Description", type:"textarea", placeholder:"Enter Job Description", key:"jobDescription", value:jobDescription, setValue:setJobDescription, required:true},
        ]
    );
    
    const apiAction={
        add:addJobRole,
        fetch:fetchJobRoleDetail,
        update:updateJobRoleDetail,
    }
    useEffect(() => {
        const routeSplit = location?.pathname.split('/')
        if(routeSplit[routeSplit.length-1] ==="add") { 
            setFormConstants({
                title:"Job Role",
                formHeader:"Add Job Role",
                actionButton:"Add Job Role",
                confirmDialogue:"Are you sure to add this Job Role?",
                actionType: "add",
                routeBack: "/manageJobRole",
            })
        }else{
            setFormConstants({
                title:"Job Role",
                formHeader:"Update Job Role",
                actionButton:"Edit Job Role",
                confirmDialogue:"Are you sure to edit this Job Role?",
                actionType: "edit",
                routeBack: "/manageJobRole",
            })
        }
    }, [])


    useEffect(() => {
        setInputFields([
            { label:"Job Title", type:"text", placeholder:"Enter Job Title", key:"jobTitle", value:jobTitle, setValue:setJobTitle, required:true},
            { label:"Job Description", type:"textarea", maxlength:"1500", placeholder:"Enter Job Description", key:"jobDescription", value:jobDescription, setValue:setJobDescription, required:true},
        ])
    }, [jobDescription, jobTitle])
    
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<AddManageDataComponent inputFields={inputFields} formConstants={formConstants} apiAction={apiAction} inputValidation={jobRoleFieldValidation}/>}/>
            </div>
        </div>
    );
};

export default AddJobRolePage;
