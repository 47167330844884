import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import AptillioToaster from '../../components/Global/AptillioToaster/AptillioToaster.component';
import ButtonEvent from '../../components/Global/inputs/button.component';
import InputEvent from '../../components/Global/inputs/input.component';
import PrivacyPolicyDialogue from '../../components/Global/PrivacyPolicyDialogue/PrivacyPolicyDialogue.component';
import useAuth from '../../hooks/useAuth.hook';
import {login } from '../../redux/users/user.actions';
import './Login.styles.scss';
import { validate } from './loginValidation';

const LoginPage = () => {
    
    const dispatch = useDispatch();
    // const user = useSelector(selectUserData);
    const {auth, createUserAuth} = useAuth();
    const navigateTo = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [toastList, setToastList] = useState([]);
    const [rememberMe, setRememberMe] = useState(false)
    const [privacyDialogue, setPrivacyDialogue] = useState({open: false, type:"pp"});
    
    const handleLogin = () => {
        dispatch(login({email, password})).then(res=>{
            createUserAuth(res?.data?.token, res?.data?.refreshToken, res?.data?.data?._id, res?.data?.data?.userTypeId?.name, res?.data?.data?.is_HM, res?.data?.data?.created_by)
            setToastList([{id:0, title:"Login Success", description: "User has been logged in.", icon:"success"}])
            setTimeout(()=>{
                return navigateTo('/')
            },1500)
        }).catch(err=>{
            setToastList([{id:0, title:"Login Error", description: err?.response?.data?.message, icon:"error"}])
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate({email: email, rememberMe: rememberMe, password: password}));
        setIsSubmit(true);
    };

    useEffect(()=>{
        return()=>{
            clearTimeout()
        }
    },[])

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleLogin()
        }
    }, [formErrors]);

    useEffect(()=>{
        localStorage.setItem("keepMeLoggedIn",rememberMe)
    },[rememberMe])

    const onVisibleClick=()=>{
        setPasswordVisible(!passwordVisible)
    }

    if (auth?.aptillioToken) return <Navigate to="/" />;
    // else if (auth?.aptillioToken && auth?.userType==="org-admin") return <Navigate to="/manageHiringManager" />;
    // else if (auth?.aptillioToken && (auth?.userType==="hiring-manager" || auth?.userType==="business-psychologist")) return <Navigate to="/manageCandidate" />;
    
    return (
        <div className="loginpage-container">
            <div className="loginpage">
                <div className="loginpage__child">
                    <div className="loginpage__child__profile-left">
                        <img alt='admin-login-profile' src='/images/admin-login-profile.png'></img>
                        <div className="loginpage__child__profile-left_logo_text">
                            <h1 className='text__heading'>Ease The Hiring Process With Aptillio</h1>
                            <p className='text_subheading'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur modi eum consequatur nostrum quidem!</p>
                        </div>
                    </div>
                </div>
                <div className="loginpage__child loginpage__child-right">
                    <div className="loginpage__child__textbox loginpage__child__textbox-right">
                        <div className='aptillio-logo'>
                            <img src="/logo-one.png" alt="logo" />
                        </div>
                        <div className="loginpage__heading">
                            <h3>Login</h3>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="loginpage_form">
                                <div className="loginpage_form-input">
                                    <InputEvent text="Email" required={true} value={email} setValue={setEmail}/>
                                </div>
                            {
                            formErrors?.email &&
                                <>
                                    <p style={{"color": 'red'}}>{formErrors?.email}</p>
                                    <br></br>
                                </>
                            }
                            <div className="loginpage_form-input">
                                <InputEvent text="Password" required={true} maxLength={16} type={passwordVisible ? "text" : "password"} value={password} setValue={setPassword} icon={passwordVisible ? "/icons/visible-eye.png" : "/icons/not-visible-eye.png"} iconFunc={onVisibleClick}/>
                            </div>
                            {formErrors?.password &&
                                <>
                                    <p style={{color: 'red'}}>{formErrors?.password}</p>
                                    <br></br>
                                </>
                            }
                            <div className="loginpage_form-submit">
                                <ButtonEvent text={"LOGIN"} customStyle={{backgroundColor: '#5367FD', color: '#ffffff'}} />
                                {apiErrorMessage &&
                                    <>
                                        <p style={{color: 'red'}}>{apiErrorMessage}</p>
                                    </>
                                }
                            </div>
                            </div>
                            
                            <div className="redirect_actions">
                                <div className="redirect_actions-forget">
                                    <p style={{color: (rememberMe===false || rememberMe ==="false") ? "black" : ""}} className='remember-me'>{(rememberMe ===true || rememberMe ==="true") ? <input className='remember_input' name="rememberMe" type="checkbox" checked={rememberMe} onChange={(e)=>setRememberMe(e.target.checked)}/>: <input className='remember_input' name="rememberMe" type="checkbox" checked={false} onChange={(e)=>setRememberMe(e.target.checked)}/>}Keep me logged in</p>
                                    <p className='forget-password'><a href='/forget-password'>Forgot Your Password?</a></p>
                                </div>
                                <div className="redirect_actions-signup flex-center-center">
                                    <p><a href="/organization/signup">Sign-up</a> as an organization admin.</p>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="loginpage__child-privacypolicy">
                        <p>Copyright 2022 Aptillio | All Rights Reserved | <a style={{cursor: 'pointer'}} onClick={()=>setPrivacyDialogue({open:true, type:"pp"})}>Privacy policy</a></p>
                    </div>
                </div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
            </div>
            <PrivacyPolicyDialogue
            dialogueBox={privacyDialogue}
            setConfirmDiaogue={setPrivacyDialogue}
            />
        </div>
    );
};

export default LoginPage;
