export const endpoints = {
    // USER ENDPOINT
    user: '/user',
    userLogin: '/user/login',
    orgSignup: '/user/signup',
    orgSignupVerify: '/user/signup/verify',
    userForgetPassword: '/user/forgotPassword',
    userResetPassword: '/user/resetPassword',
    userChangePassword: '/user/changePassword',
    getUser : '/user/getUser',

    // ORGANISATION
    organisation: '/organisations',
    getOrganisation : '/organisations/:id',

    //HIRING MANAGER
    hiringManager: '/hiringManagers',
    addHiringManager: '/hiringManagers/signup',
    hiringManagerList : '/hiringManagers/list',
    hiringManagerStatusUpdate : '/hiringManagers/status',
    hiringManagerShowOthers : '/hiringManagers/showOthers',

    //CANDIDATES
    candidates: '/candidates',
    candidatesListing : '/candidates/list',
    submitCandidate: '/candidates/signup',

    //CANDIDATE PARAMETERS
    candidateParams:'/candidateParams',
    addCandidateParam: '/candidateParams/add',

    //JOB ROLES BY HM
    candidatesAddJob: '/candidates/addJob',
    candidatesJobListing : '/candidates/jobs',
    
    //JOB ROLES/DESIGNATIONS LIST
    jobRoles: '/jobDesignations',

    //ASSESSMENT TYPES/STATUS API
    assessmentType: '/assessmentTypes',

    //Business Psychologist
    businessPsychologist:'/businessPsychologists',
    businessPsychologistListing:'/businessPsychologists/list',
    addBusinessPsychologist:'/businessPsychologists/signup',

    //Subscriptions
    subscription:'/subscriptions',
    addSubscriptionDetail:'/subscriptions/add',
    getSubscriptionPlans:'/user/subscription',
    checkSubscription:'/user/checkSubscription',

    //Add stripe subscription Payment
    addSubscriptionPayment:'/user/subscription/add',

    //Candidate Book Slot Urls
    onCandidateBookSlot:'/candidates/bookSlot',
    //Candidate Scheduled Interview Event
    onScheduledInterview:'/candidates/scheduleInterview',

    //add Notes in Inerview
    addCandidateNotes: '/candidates/addNotes',
    getCandidateNotes: '/candidates/notes',

    //Interview Page Api
    interviewDetail:'/meetings',
    endInterview:'/candidates/endInterview',

    //Invoices
    invoiceDetail: '/user/invoice',
    invoiceListings: '/user/subscriptionHistory',

    //CHECK ASSESSMENT STATUS
    checkAssessmentStatus: '/candidates/checkStatus',
    addCandidateAssessment: '/candidates/assessmentFeedback/add',
    candidateAssessment: '/candidates/assessmentFeedback',
    getAssessmentRatings: '/ratings',

    //Assessment Score
    candidateAssessmentScore: '/candidates/assessmentStatus',

    //Reports for AA
    activeUserReport: "/reports/activeUsers",
    hiringManagerReport: "/reports/hiringManagers",
    assignedCandidatesReport: "/reports/assignedCandidates",
    assessmentCompletdReport: "/reports/completeAssessment",
    activeOrganizationsData : "/reports/activeOrgs",
    organizationPerformanceReport: "/reports/orgPerformances",

    // MANAGE NOTIFICACTION PREFERENCES
    notificationPreferences: "/notifications/preferences",
    editNotificationPreference: "/notifications/preference",

    //NOTIFICATON COUNT
    notificationCount: "/notifications/notifyCnt",

    //NOTIFICATIONS
    notifications:"/notifications",
    
    //CALENDLY SCHEDULED EVENTS ENDPOINT
    calendlyScheduledEvents: "https://api.calendly.com/scheduled_events"
};
