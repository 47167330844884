import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { addToaster } from '../../../redux/appToaster/appToaster.actions';
import { fetchAssessmentRatings, getCandidateAssessment } from '../../../redux/CompleteAssessment/CompleteAssessment.actions';
import ButtonEvent from '../../Global/inputs/button.component';
import './AssessmentFeedback.component.scss';

const AssessmentFeedbackComponent = () => {
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const navigateTo = useNavigate()
    const { id } = useParams()
    const [feedbackDetails, setFeedbackDetails] = useState(null)
    const [assessmentRatings, setAssessmentRatings] = useState([]);

    useEffect(() => {
            dispatch(getCandidateAssessment(axiosInstance,{ id })).then(res=>{
                setFeedbackDetails(res?.data?.data?.CandidateFeedback)
            }).catch(err=>{
                setFeedbackDetails({...feedbackDetails, notes: null})
                // setToastList([{id:0, title:"Fetch Error", description: err?.response?.data?.message || "Error while fetching notes of this candidate.", icon:"error"}])
                dispatch(addToaster({id:0, title:"Fetch Error", description: err?.response?.data?.message || "Error while fetching feedback details of this candidate.", icon:"error"}))
            })
    }, [axiosInstance, dispatch, id])

    useEffect(()=>{
        dispatch(fetchAssessmentRatings(axiosInstance)).then(res=>{
            setAssessmentRatings(res?.data?.ratings || [])
        })
    },[dispatch])

    return (
        <div className="assessmentFeedback_container">
            <div className="assessmentFeedback_wrapper">
                <div className="assessmentFeedback_main">
                    <div className="assessmentFeedback_main-header_container">
                        <div className="assessmentFeedback_main_header">
                            <p className='assessmentFeedback_main_header_text'> Assessment Feedback : <span className='subheader'>{feedbackDetails?.candidateId?.firstname} {feedbackDetails?.candidateId?.lastname}</span></p>
                        </div>
                    </div>
                    <div className="assessmentFeedback_main-video_summary">
                        <div className='assessmentFeedback_main-video_summary-header'>
                            <h3>Video Summary</h3>
                        </div>
                        <div className='assessmentFeedback_main-video_summary-content'>
                            <div className="video_summary-container">
                                <ReactPlayer width={"100%"} height={"300px"} controls={true} url={feedbackDetails?.videoUrl} />
                            </div>
                            <div className="video_summary-detail">
                                <div className="video_summary-detail-bp">
                                    <h4>Business Psychologist</h4>
                                    <p>{feedbackDetails?.created_by?.firstname} {feedbackDetails?.created_by?.lastname}</p>
                                </div>
                                <div className="video_summary-detail-date">
                                    <h4>Date of submission</h4>
                                    <p>{feedbackDetails?.created_at && new Date(feedbackDetails?.created_at).toLocaleDateString('en-us',{ weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })}</p>
                                </div>
                                <div className="video_summary-detail-rating">
                                    <h4>Candidate Rating:</h4>
                                    <div className="video_summary-detail-rating_select">
                                        {
                                            assessmentRatings?.map((rating)=>{
                                                return (
                                                <div key={rating?.name} className="video_summary-detail-rating_select-wrap" style={{color: feedbackDetails?.ratingId._id !== rating._id ? "#9da5dc" : "#3f464f"}}>
                                                    <input type="radio" disabled={feedbackDetails?.ratingId?._id!==rating._id} id="customRadio1" name="customRadio" checked={feedbackDetails?.ratingId?._id===rating._id} value={rating?._id}/>
                                                    <label>{rating?.name}</label>
                                                </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="assessmentFeedback_main-written_summary">
                        <div className='assessmentFeedback_main-written_summary-header'>
                            <h3>Written Summary</h3>
                        </div>
                        <div className='assessmentFeedback_main-written_summary-text'>
                            <p>
                                {feedbackDetails?.summary}
                            </p>
                        </div>
                    </div>
                    <div className="assessmentFeedback_main-additional_comments">
                        <div className='assessmentFeedback_main-additional_comments-header'>
                            <h3>Additional Comments</h3>
                        </div>
                        <div className='assessmentFeedback_main-additional_comments-text'>
                            <p>
                                {feedbackDetails?.additionalComments}
                            </p>
                        </div>
                    </div>
                    <div className="assessmentFeedback_main-actions">
                        <div className="assessmentFeedback_main-actions_btn">
                            <ButtonEvent theme={"secondary"} text={"Back To Dashboard"} func={()=>navigateTo('/')}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssessmentFeedbackComponent