import React from 'react';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';
import ManageInvoiceComponent from '../../components/Common/ManageInvoice/ManageInvoice.component';

const ManageInvoicePage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageInvoiceComponent/>}/>
            </div>
        </div>
    );
};

export default ManageInvoicePage;
