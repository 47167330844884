import React from 'react';
import ManageNotificationComponent from '../../components/Common/ManageNotifications/ManageNotifications.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';

const ManageNotificationPage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageNotificationComponent/>}/>
            </div>
        </div>
    );
};

export default ManageNotificationPage;