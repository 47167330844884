import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    listing: [],
    data:null
};
const hiringManagerSlice = createSlice({
  name: 'manageHiringManager',
  initialState,
  reducers: {
    hiringManagerListDataSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;

    },
    hiringManagerDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetHiringManagerDetail: (state, action) => {
      state.data = null;
    },
    resetHiringManagerAllData: (state, action) => {
      state={
        listing: [],
        data:null
      };
    },
  }
});

export const {
    actions: manageHiringManagerActions,
    reducer: manageHiringManagerReducer,
    name: manageOrganisation
} = hiringManagerSlice;

