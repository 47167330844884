import { endpoints } from '../../../constants/url.endpoint';
import { manageCandidatesActions } from './manageCandidates.reducer';

const { candidateListDataSuccess, resetManageCandidateAllData, candidateDetailsSuccess, resetCandidateDetail } = manageCandidatesActions;

export const fetchCandidatesListing =(axiosInstance ,apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.candidatesListing}/${apiData?.userId}`,
                method: 'GET',
                params:apiData
            }).then((res) => {
                dispatch(candidateListDataSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetManageCandidateAllData());
                reject(err)
            });
    })
};

export const fetchCandidateParams =(axiosInstance, setOptions) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.candidateParams}`,
                method: 'GET',
            }).then((res) => {
                setOptions(res.data.data.listingData)
                resolve(res.data.dataListing)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchCandidateJobRoles =(axiosInstance, setOptions) => async (dispatch) => {
    let userId = localStorage.getItem("userId")
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.candidatesJobListing}/${userId}`,
                method: 'GET',
            }).then((res) => {
                setOptions(res?.data?.data?.listingData)
                resolve(res.data.data)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchAssessmentTypes =(axiosInstance, setOptions) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.assessmentType}`,
                method: 'GET',
            }).then((res) => {
                setOptions(res?.data?.data?.assessments)
                resolve(res?.data?.data?.assessments)
            }).catch(err => {
                reject(err)
            });
    })
};

export const submitCandidateDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.submitCandidate}`,
                method: 'POST',
                params:{draft: apiData.draft},
                data:apiData
            }).then((res) => {
                dispatch(candidateDetailsSuccess(res?.data?.data));
                resolve(res?.data?.data)
            }).catch(err => {
                dispatch(resetCandidateDetail());
                reject(err)
            });
    })
};

export const fetchCandidateDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.candidates}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                dispatch(candidateDetailsSuccess(res?.data?.candidate));
                resolve(res?.data)
            }).catch(err => {
                dispatch(resetCandidateDetail());
                reject(err)
            });
    })
};

export const updateCandidateDetail =(axiosInstance ,apiData) => (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.candidates}/${apiData?._id}`,
                method: 'PATCH',
                data:apiData
            }).then((res) => {
                resolve(res?.data?.data || "")
            }).catch(err => {
                dispatch(resetCandidateDetail());
                reject(err)
            });
    })
};