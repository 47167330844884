import React from 'react';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';
import ManageInvoiceGroupComponent from '../../components/AptillioAdmin/ManageAdminInvoices/ManageAdminInvoices.component';

const ManageInvoiceGroupPage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageInvoiceGroupComponent/>}/>
            </div>
        </div>
    );
};

export default ManageInvoiceGroupPage;
