import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endpoints } from '../../../constants/url.endpoint';
// import { getSubscriptionPlans } from './manageSubscription.actions';

export const initialState = {
    listing: [],
    data:null,
    plans:[]
};

export const getSubscriptionPlans = createAsyncThunk(
  //action type string
  'manageSubscription/getSubscriptionPlans',
  // callback function
  async (apiData) => {
    const res = await apiData?.axiosInstance({
          url: `${endpoints?.getSubscriptionPlans}/${apiData?.userId}`,
          method: 'GET',
          // params:apiData
      })
      const data = await res.data
      return data
})

const manageSubscriptionSlice = createSlice({
  name: 'manageSubscription',
  initialState,
  reducers: {
    manageSubscriptionListDataSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    subscriptionDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetSubscriptionDetail: (state, action) => {
      state.data = null;
    },
    resetSubscriptionAllData: (state, action) => {
      state={
        listing: [],
        data:null
      };
    },
    subscriptionPlansSuccess:(state, action) => {
      state.plans = action.payload;
    },
    subscriptionPlansReset:(state, action) => {
      state.plans = [];
    }
  },
  extraReducers: {
    [getSubscriptionPlans.rejected]: (state, action) => {
      // returns 'Opps there seems to be an error'
      state.plans = []
    },
    [getSubscriptionPlans.fulfilled]: (state, { payload }) => {
      //returnr plan when the api result is fulfilled
      state.loading = false
      state.plans = payload.data
    },
  }
});

export const {
    actions: manageSubscriptionActions,
    reducer: manageSubscriptionReducer,
    name: manageSubscription
} = manageSubscriptionSlice;