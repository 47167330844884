import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddManageDataComponent from '../../../components/Global/AddManageDataForm/AddManageDataForm.component';
import { businessPsychologistFieldValidation } from '../../../components/Global/AddManageDataForm/BusinessPsychologistFieldsValidations';
import HomeComponent from '../../../components/Global/HomeLayout/HomeLayout.component';
import { addBusinessPsychologist, fetchBusinessPsychologistDetail, updateBusinessPsychologist } from '../../../redux/AptillioAdmin/manageBusinessPsychologist/manageBusinessPsychologist.actions';

const AddEditBusinessPsychologistPage = () => {
    const location = useLocation()
    const [email, setEmail] = useState("")
    const [firstname, setFirstName] = useState("")
    const [lastname, setLastName] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [expertise, setExpertise] = useState("")
    const [calendlyUsername, setCalendlyUsername] = useState("")
    const [status, setBPStatus] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setConfirmPassword] = useState("")
    const [formConstants, setFormConstants] = useState(null)
    const [inputFields, setInputFields] = useState()
    const bpStatusOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const apiActions={
        add:addBusinessPsychologist,
        fetch:fetchBusinessPsychologistDetail,
        update:updateBusinessPsychologist,
    }
    useEffect(() => {
        const routeSplit = location?.pathname.split('/')
        if(routeSplit[routeSplit.length-1] ==="add") { 
            setFormConstants({
                title:"Business Psychologist",
                formHeader:"Add Business Psychologist",
                actionButton:"Add Business Psychologist",
                confirmDialogue:"Are you sure to add this Business Psychologist?",
                actionType: "add",
                routeBack: "/manageBusinessPsychologist",
            })
        }else{
            setFormConstants({
                title:"Business Psychologist",
                formHeader:"Update Business Psychologist",
                actionButton:"Edit Business Psychologist",
                confirmDialogue:"Are you sure to edit this Business Psychologist?",
                actionType: "edit",
                routeBack: "/manageBusinessPsychologist",
            })
        }
    }, [location?.pathname])


    useEffect(() => {
        setInputFields([
            { label:"First Name", type:"text", placeholder:"Enter first name", key:"firstname", value:firstname, setValue:setFirstName, required:true},
            { label:"Last Name", type:"text",placeholder:"Enter last name", key:"lastname", value:lastname, setValue:setLastName, required:true},
            { label:"Email", type:"text", placeholder:"Enter email", key:"email", value:email, setValue:setEmail, required:true},
            { label:"Expertise", type:"text", placeholder:"Enter expertise", key:"expertise", value:expertise, setValue:setExpertise, required:true},
            { label:"Status", type:"select", placeholder:"Select", key:"status", value:status, setValue:setBPStatus,selectOptions: bpStatusOptions},
            { label:"Calendly.com/", type:"text", placeholder:"Enter a valid calendly user name", key:"calendlyUsername", value:calendlyUsername, setValue:setCalendlyUsername, required: true},
            { label:"Phone Number", type:"number", max:"9999999999", placeholder:"Enter 10 digit phone Number", key:"contactNo", value:contactNo, setValue:setContactNo, required:true},
            { label:"Password", type:"text", placeholder:"Enter password", key:"password", value:password, setValue:setPassword, required:true},
            { label:"Confirm Password", id:"reset_confirm_pass", type:"text",placeholder:"Confirm password", key:"passwordConfirm", value:passwordConfirm, setValue:setConfirmPassword, required:true},
        ])
    }, [contactNo, expertise, email, firstname, lastname, password, passwordConfirm, status, calendlyUsername])
    
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<AddManageDataComponent inputFields={inputFields} formConstants={formConstants} apiAction={apiActions} inputValidation={businessPsychologistFieldValidation}/>}/>
            </div>
        </div>
    );
};

export default AddEditBusinessPsychologistPage;
