import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from "@stripe/react-stripe-js";
import "./stripePayment.component.scss";
import { CheckoutForm } from "./checkoutForm";
import { useDispatch } from "react-redux";
import { fetchSubscriptionDetail } from "../../../redux/AptillioAdmin/manageSubscription/manageSubscription.actions";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { useParams } from "react-router-dom";
import { stripePublicKey } from "../../../constants/envExports";

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
    }
  ]
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripePublicKey);

const StripePaymentComponent = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const axiosInstance = useAxiosInstance()
  const [subscriptionDetail, setSubscriptionDetail] = useState(null)

  useEffect(()=>{
    dispatch(fetchSubscriptionDetail(axiosInstance,{id})).then(res=>{
      setSubscriptionDetail(res?.data?.data)
    })
  },[])

  return (
    <div className="reset-container">
        <div className="reset">
            <div className="reset__child resetpage__child-right">
                <div className="reset__child__textbox reset__child__textbox-right">
                    <div className='aptillioLogo'><img src="/logo-one.png" alt="logo" /></div>
                    <br />
                    <div className="reset__heading">
                        <h3>Buy Subscription</h3>
                    </div>
                    <br />
                    <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                      <CheckoutForm subscriptionDetail={subscriptionDetail}/>
                    </Elements>
                </div>
            </div>
        </div>
    </div>
  );
};

export default StripePaymentComponent;