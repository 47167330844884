import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AptillioToaster from '../../../components/Global/AptillioToaster/AptillioToaster.component';
import ButtonEvent from '../../../components/Global/inputs/button.component';
import InputEvent from '../../../components/Global/inputs/input.component';
import PrivacyPolicyDialogue from '../../../components/Global/PrivacyPolicyDialogue/PrivacyPolicyDialogue.component';
import { orgSignup } from '../../../redux/users/user.actions';
import { passwordToolTip } from '../../resetPassword/resetPassword.constant';
import './signup.styles.scss';
import { signupValidate } from './signupValidation';
const SignupPage = () => {
    const dispatch = useDispatch();
    const [orgName, setOrgName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [toastList, setToastList] = useState([]);
    const [agree, setAgree] = useState(false)
    const [isHiringManager, setHiringManager] = useState(false)
    const [isFormSubmitted, setFormSubmitted] = useState(false);
    const [privacyDialogue, setPrivacyDialogue] = useState({open: false, type:"pp"});

    
    const handleSignup = () => {
        const signUpData = {email, password, passwordConfirm:confirmPassword, org_name:orgName, is_HM: isHiringManager, contactNo: phoneNumber,firstname: firstName,
        lastname: lastName}
        dispatch(orgSignup(signUpData)).then(res=>{
            setToastList([{id:0, title:"Registered Successfully", description: res.data.message, icon:"success"}])
            setFormSubmitted(true)
        }).catch(err=>{
            setToastList([{id:0, title:"Login Error", description: err?.response?.data?.message, icon:"error"}])
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(signupValidate({firstName, lastName, orgName, phoneNumber, email, password, confirmPassword}));
        setIsSubmit(true);
    };

    useEffect(()=>{
        return()=>{
            clearTimeout()
        }
    },[])

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleSignup()
        }
    }, [formErrors]);

    return (
        <div className="org_signup animate__animated animate__fadeIn">
            <div className="org_signup_main">
                <div className="org_signup_main_child">
                    <div className="org_signup_main_child_row">
                        <div className="org_signup_main_child_row_left">
                            <div className="org_signup_main_child_row_right_top org_signup_main_child_mobile__logo">
                                <figure><img src="/logo-one.png" alt="logo" /></figure>
                            </div>
                            <div className="org_signup_main_child_row_left_profile">
                                <img src="/images/admin-signup-profile.png" alt="" />
                                <div className="org_signup_main_child_row_left_profile_content">
                                    <h3>
                                        <div> Ease The Hiring</div>
                                        Process With Aptillio
                                    </h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur id mauris sed elit finibus pretium in quis felis.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="org_signup_main_child_row_right">
                            <div className='org_signup_main_child_row_right_top'>
                                <figure><img src="/logo-one.png" alt="logo" /></figure>
                                <h3>Get Started</h3>
                            </div>
                            {
                                isFormSubmitted ? 
                                <div className="org_signup_main_child_row_right_form-submitted">
                                    <div className="org_signup_main_child_row_right_form-submitted_wrapper">
                                        <p>
                                            An Account verification link has been shared on your submitted Email. 
                                            Please verify your account to proceed further. Thanks!
                                        </p>
                                    </div>
                                </div>
                                :
                                <div className="org_signup_main_child_row_right_form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="org_signup_main_child_row_right_form-mandatory_text" style={{justifyContent:"flex-end"}}>
                                            <label className='org_main-input_label'><span style={{fontSize:"1.5rem", color: "red"}}>*</span> Required Fields</label>
                                        </div>
                                        <InputEvent customStyle={{marginBottom:"20px"}} text="Organization Name" placeholder="Adobe" value={orgName} setValue={setOrgName} required/>
                                        <div className="org_signup_main_child_row_right_form_checkwrp">
                                        {isHiringManager ?<input className='remember_input' name="isHiringManager" type="checkbox" checked={isHiringManager} onChange={(e)=>setHiringManager(e.target.checked)}/>: <input className='remember_input' name="notHiring" type="checkbox" checked={false} onChange={(e)=>setHiringManager(e.target.checked)}/>}
                                            <label className="custom-checkbox">Are You Hiring Manager?</label>
                                        </div>
                                        {formErrors?.name &&
                                            <>
                                                <p style={{"color": 'red'}}>{formErrors?.name}</p>
                                                <br></br>
                                            </>
                                        }
                                        <InputEvent customStyle={{marginBottom:"20px"}} text="First Name" placeholder="Aurelia" value={firstName} setValue={setFirstName} required/>
                                        {formErrors?.firstName &&
                                            <>
                                                <p style={{"color": 'red'}}>{formErrors?.firstName}</p>
                                                <br></br>
                                            </>
                                        }
                                        <InputEvent customStyle={{marginBottom:"20px"}} text="Last Name" placeholder="Lesch" value={lastName} setValue={setLastName} required/>
                                        {formErrors?.lastName &&
                                            <>
                                                <p style={{"color": 'red'}}>{formErrors?.lastName}</p>
                                                <br></br>
                                            </>
                                        }
                                        <InputEvent type={"number"} customStyle={{marginBottom:"20px"}} text="Phone Number" placeholder="Enter 10 Digit Phone Number" value={phoneNumber} setValue={setPhoneNumber} required/>
                                        {formErrors?.phoneNumber &&
                                            <>
                                                <p style={{"color": 'red'}}>{formErrors?.phoneNumber}</p>
                                                <br></br>
                                            </>
                                        }
                                        <InputEvent customStyle={{marginBottom:"20px"}} text="Email" placeholder="Lesch_Aurelia@gmail.com" value={email} setValue={setEmail} required/>
                                        {formErrors?.email &&
                                            <>
                                                <p style={{"color": 'red'}}>{formErrors?.email}</p>
                                                <br></br>
                                            </>
                                        }
                                        <div className="org_signup_main_child_row_right_form-input-wrapper">
                                            <InputEvent text="Password" maxLength={16} required={true} type={passwordVisible ? "text" : "password"} 
                                                value={password} setValue={setPassword} icon={passwordVisible ? "/icons/visible-eye.png" : "/icons/not-visible-eye.png"} 
                                                iconFunc={()=>setPasswordVisible(!passwordVisible)} tooltipText={passwordToolTip}/>
                                            {formErrors?.password &&
                                                <>
                                                    <p style={{"color": 'red'}}>{formErrors?.password}</p>
                                                    <br></br>
                                                </>
                                            }
                                        </div>
                                        <div className="org_signup_main_child_row_right_form-input-wrapper">
                                            <InputEvent customStyle={{marginBottom:"20px"}} text="Confirm Password" required={true} id="reset_confirm_pass" type={"text"} value={confirmPassword} setValue={setConfirmPassword}/>
                                            {
                                                formErrors?.confirmPassword &&
                                                    <>
                                                        <p style={{"color": 'red'}}>{formErrors?.confirmPassword}</p>
                                                        <br></br>
                                                    </>
                                            }
                                        </div>
                                        <div className="org_signup_main_child_row_right_form_checkwrp">
                                        {agree ?<input className='remember_input' name="rememberMe" type="checkbox" checked={agree} onChange={(e)=>setAgree(e.target.checked)}/>: <input className='remember_input' name="rememberMe" type="checkbox" checked={false} onChange={(e)=>setAgree(e.target.checked)}/>}
                                            <label className="custom-checkbox">I agree to the <a style={{cursor: 'pointer'}} onClick={()=>setPrivacyDialogue({open:true, type:"pp"})}>Privacy Policy</a> and <a style={{cursor: 'pointer'}} onClick={()=>setPrivacyDialogue({open:true, type:"tc"})}> Terms  & Conditions</a>
                                                
                                            </label>
                                        </div>
                                        <div className="org_signup_main_child_row_right_form_btm">
                                            <ButtonEvent text={"SIGN UP"} disabled={!agree}></ButtonEvent>
                                            <p>Already have an account?   <a href="/login">Login</a></p>
                                        </div>
                                        <div className="org_signup_main_child_row_right_form_ftr">
                                            <p>Copyright 2022 Aptillio | All Rights Reserved | <a style={{cursor: 'pointer'}} onClick={()=>setPrivacyDialogue({open:true, type:"pp"})}>Privacy Policy</a></p>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
            </div>
            <PrivacyPolicyDialogue
            dialogueBox={privacyDialogue}
            setConfirmDiaogue={setPrivacyDialogue}/>
        </div>
    );
};

export default SignupPage;
