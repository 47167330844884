import { endpoints } from '../../../constants/url.endpoint';
import { myAccountActions } from './myAccount.reducer';

const { myAccountDetailsSuccess, resetMyAccountDetail } = myAccountActions;

export const fetchMyAccountUserDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.getUser}`,
                method: 'GET',
            }).then((res) => {
                dispatch(myAccountDetailsSuccess(res?.data?.user));
                resolve(res?.data)
            }).catch(err => {
                dispatch(resetMyAccountDetail());
                reject(err)
            });
    })
};

export const updateMyAccountUserDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.user}/${apiData.id}`,
                method: 'PATCH',
                data: apiData,
            }).then((res) => {
                dispatch(myAccountDetailsSuccess(res?.data));
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const updateMyAccountUserPassword =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.userChangePassword}/${apiData.id}`,
                method: 'PATCH',
                data: apiData,
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};