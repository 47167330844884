import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    data: null,
    single: null
};
const postSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchPostsSuccess: (state, action) => {
            state.data = action.payload;
        },
        fetchAPostSuccess: (state, action) => {
            state.single = action.payload;
        }
    }
});

export const {
    actions: postsActions,
    reducer: postsReducer,
    name: userName
} = postSlice;
