import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AddManageDataComponent from '../../../components/Global/AddManageDataForm/AddManageDataForm.component';
import { hiringManagerFieldValidation } from '../../../components/Global/AddManageDataForm/HiringManagerFieldsValidations';
import HomeComponent from '../../../components/Global/HomeLayout/HomeLayout.component';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { addHiringManagerDetail, fetchHiringManagerDetail, updateHiringManagerDetail } from '../../../redux/Common/manageHiringManager/manageHiringManager.actions';

const AddHiringManagerPage = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const axiosInstance = useAxiosInstance()
    const [email, setEmail] = useState("")
    const [firstname, setFirstName] = useState("")
    const [lastname, setLastName] = useState("")
    const [designation, setDesignation] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setConfirmPassword] = useState("")
    const [status, setBPStatus] = useState("")
    const [formConstants, setFormConstants] = useState(null)
    const statusOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const hiringManageFields=[
        { label:"First Name", type:"text", placeholder:"Enter First name", key:"firstname", value:firstname, setValue:setFirstName, required:true},
        { label:"Last Name", type:"text",placeholder:"Enter Last name", key:"lastname", value:lastname, setValue:setLastName, required:true},
        { label:"Email", type:"text", placeholder:"Enter Email", key:"email", value:email, setValue:setEmail, required:true},
        { label:"Designation", type:"text", placeholder:"Enter Designation", key:"designation", value:designation, setValue:setDesignation, maxLength:'25',required:true},
        { label:"Phone Number", type:"number", placeholder:"Enter 10 Digit Phone Number", key:"contactNo", value:contactNo, setValue:setContactNo, required:true},
        { label:"Password", type:"text", placeholder:"Enter Password", key:"password", value:password, setValue:setPassword, required:true},
        { label:"Confirm Password", type:"text",placeholder:"Enter Confirm Password", key:"passwordConfirm", value:passwordConfirm, setValue:setConfirmPassword, required:true},
        { label:"Status", type:"select", placeholder:"Select", key:"status", value:status, setValue:setBPStatus,selectOptions: statusOptions},
    ]
    const [inputFields, setInputFields] = useState(hiringManageFields);
    
    const apiActions={
        add:addHiringManagerDetail,
        fetch:fetchHiringManagerDetail,
        update:updateHiringManagerDetail,
    }
    useEffect(() => {
        const routeSplit = location?.pathname.split('/')
        if(routeSplit[routeSplit.length-1] ==="add") { 
            setFormConstants({
                title:"Hiring Manager",
                formHeader:"Add Hiring Manager",
                actionButton:"Add Hiring Manager",
                confirmDialogue:"Are you sure to add this Hiring Manager?",
                actionType: "add",
                routeBack: "/manageHiringManager",
            })
        }else{
            setFormConstants({
                title:"Hiring Manager",
                formHeader:"Update Hiring Manager",
                actionButton:"Edit Hiring Manager",
                confirmDialogue:"Are you sure to edit this Hiring Manager?",
                actionType: "edit",
                routeBack: "/manageHiringManager",
            })
        }
    }, [])


    useEffect(() => {
        setInputFields(hiringManageFields)
    }, [contactNo, designation, email, firstname, lastname, password, passwordConfirm, status])
    
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<AddManageDataComponent inputFields={inputFields} formConstants={formConstants} apiAction={apiActions} inputValidation={hiringManagerFieldValidation}/>}/>
            </div>
        </div>
    );
};

export default AddHiringManagerPage;
