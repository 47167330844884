import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './AlertDialog.component.scss'
import { AiOutlineClose } from 'react-icons/ai';

export default function AlertDialog({openAlert, alertMessage, setConfirmDiaogue, setConfirmAction}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(()=>{
    setOpen(openAlert)
  },[openAlert])

  React.useEffect(()=>{
      return ()=>{
          setOpen(false)
      }
  },[])

  const handleClose = () => {
    setConfirmDiaogue({ openAlert:false, alertMessage:""});
    setOpen(false);
  };

    const handleSubmit = () => {
        setConfirmAction(true)
    };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{overflow:'unset !important'}}
      >
        <DialogTitle id="alert-dialog-title">
            {alertMessage}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit} autoFocus>
                Yes
            </Button>
            <Button onClick={handleClose}>
                No
            </Button>
        </DialogActions>
        <button onClick={handleClose} className='dialogue_box-close'>
            <AiOutlineClose></AiOutlineClose>
        </button>
      </Dialog>
    </div>
  );
}