import React from 'react';
import ManageSubscriptionComponent from '../../components/AptillioAdmin/ManageSubscription/ManageSubscription.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';

const ManageSubscriptionPage = () => {

    // const userType = localStorage.getItem("userType")
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageSubscriptionComponent/>}/>
            </div>
        </div>
        );
    }

export default ManageSubscriptionPage;
