import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { addToaster } from '../../../redux/appToaster/appToaster.actions';
import { fetchNotificationPreferences, updateNotificationPreferences } from '../../../redux/Common/manageNotification/manageNotification.actions';
import './ManageNotifications.component.scss';

const ManageNotificationComponent = () => {
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const [notificaionList, setNotificaionList] = useState([]);
    
    const fetchPreferences=()=>{
        dispatch(fetchNotificationPreferences(axiosInstance, {})).then(res=>{
            setNotificaionList(res?.data?.preferences)  
        }).catch(err=>{
            dispatch(addToaster({id:0, title:"Fetch Error", description: err?.response?.data?.message || "Error while fetching notification preferences.", icon:"error"}))
        })
    }

    useEffect(() => {
        fetchPreferences()
    }, [])

    const handleNotificationToggle=(instance)=>{
        dispatch(updateNotificationPreferences(axiosInstance, {status: instance?.status==="active" ? "inactive" : "active", id: instance?._id})).then(res=>{
            fetchPreferences()
        }).catch(err=>{
            dispatch(addToaster({id:0, title:"Update Error", description: err?.response?.data?.message || "Error while updating notification preference.", icon:"error"}))
        })
    }

    return (
        <div className="manageNotitifications-container">
            <div className="manageNotitifications-wrapper">
                <div className="notitification-main">
                    <div className="notitification-main-header_container">
                        <div className="notitification-main-listing_header">
                            <p className='notitification-main-listing_header_text'>Manage Notification Preferences</p>
                        </div>
                    </div>
                    <form>
                        <div className="notitification-main-inputs">
                            {
                                notificaionList.map(input=>{
                                    return(
                                        <div key={input?._id} className="notitification-main-inputs_wrapper account_name-input">
                                            <div className="account_main-input">
                                                <Switch defaultChecked={input?.status === "active"} onChange={()=>handleNotificationToggle(input)}/>
                                            </div>
                                            <div className="account_main-label">
                                                <label className='account_main-input_label'> {input?.notificationTypeId?.description} </label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ManageNotificationComponent;