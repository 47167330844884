export const signupValidate = ({firstName, lastName, orgName, phoneNumber, email, password, confirmPassword}) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneNumberRegex = new RegExp(/^[0-9]{10}$/i);
    const passWordregex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/i);
    if (!email) {
      errors.email = "Email can't be empty!";
    } else if (!regex.test(email.trim())) {
      errors.email = "This is not a valid email format!";
    }
    
    if (!firstName) {
        errors.password = "First Name is required.";
    }
    if (!lastName) {
        errors.password = "Last Name is required.";
    }
    if (!orgName) {
        errors.password = "Organisation Name is required.";
    }
    if (!phoneNumber) {
        errors.phoneNumber = "Phone Number is required.";
    }else if(phoneNumber.length !==10){
        errors.phoneNumber = "Phone Number must be of 10 numbers";
    }else if(!phoneNumberRegex.test(phoneNumber.trim())){
        errors.phoneNumber = "Phone Number is invalid";
    }
    
    if (!password) {
      errors.password = "Password is required.";
    } else if (password.length < 8) {
      errors.password = "Password must be more than 8 characters.";
    } else if (password.length > 16) {
      errors.password = "Password cannot exceed more than 16 characters.";
    }else if (!passWordregex.test(password.trim())) {
      errors.password = "Password is invalid.";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    } else if (confirmPassword.length < 8) {
      errors.confirmPassword = "Confirm Password must be more than 8 characters.";
    } else if (confirmPassword.length > 16) {
      errors.confirmPassword = "Confirm Password cannot exceed more than 16 characters.";
    } else if (password!==confirmPassword) {
      errors.confirmPassword = "Password & Confirm Password must be same.";
    }
    return errors;
};