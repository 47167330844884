
export const candidateParamterdValidation = ({ paramName, status, actionType}) => {
    const errors = {};
    
    if (!paramName) {
      errors.paramName = "Please add a parameter .ex: hardworking, honest, etc!";
    }
    if (!status) {
      errors.status = "Status can't be empty";
    }
    return errors;
};