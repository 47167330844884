import React from 'react';
import AssessmentFeedbackComponent from '../../components/AptillioAdmin/AssessmentFeedback/AssessmentFeedback.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';

const AssessmentFeedbackPage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<AssessmentFeedbackComponent/>}/>
            </div>
        </div>
    );
};

export default AssessmentFeedbackPage;
