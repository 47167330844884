import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    data: null,
    isAdmin: null
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.data = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.data = null;
    }
  }
});

export const {
    actions: userActions,
    reducer: userReducer,
    name: userName
} = userSlice;

