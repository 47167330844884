import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    listing: [],
    data:null
};
const manageInvoiceSlice = createSlice({
  name: 'manageHiringManager',
  initialState,
  reducers: {
    manageInvoiceListSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;

    },
    manageInvoiceDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetManageInvoice: (state, action) => {
      state.data = null;
      state.listing = []
    },
  }
});

export const {
    actions: manageInvoiceActions,
    reducer: manageInvoiceReducer,
    name: manageInvoice
} = manageInvoiceSlice;

