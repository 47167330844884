import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetToasters } from '../../../redux/appToaster/appToaster.actions';
import { selectToasterData } from '../../../redux/appToaster/appToaster.selectors';
import { selectLoaderData } from '../../../redux/progressLoader/progressLoader.selectors';
import AptillioProgressLoaderComponent from '../AptillioProgressLoader/AptillioProgressLoader.component';
import AptillioToaster from '../AptillioToaster/AptillioToaster.component';
import HeaderComponent from '../header/header.component';
import SidebarComponent from '../sidebar/sidebar.component';
import './HomeLayout.component.scss';

const HomeComponent = ({childrenComponent}) => {
    const [toastList, setToastList] = useState([]);
    const toasterData = useSelector(selectToasterData)
    const dispatch = useDispatch()
    const loader = useSelector(selectLoaderData);

    useEffect(()=>{
        if (toasterData !=={} && toasterData){ 
            setToastList([toasterData])
            dispatch(resetToasters())
        }
    },[dispatch, toasterData])
    
    return (
        <div className="homeLayout-container">
            <div className="homeLayout-wrapper">
                <div className="homeLayout-header">
                    <HeaderComponent/>
                </div>
                <div className="homeLayout">
                    <div className="homeLayout-sidebar_main">
                        <SidebarComponent/>
                    </div>
                    <div className="homeLayout-screen">
                        {childrenComponent}
                    </div>
                    <div className="homeLayout-right">
                        <div className="homeLayout-right-main">
                        </div>
                    </div>
                </div>
                <div className="homeLayout-end-border"></div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
            </div>
            {
                loader && <AptillioProgressLoaderComponent/>
            }
        </div>
    );
};

export default HomeComponent;
