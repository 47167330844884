import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import './FilterDrawer.component.scss';
import InputEvent from '../inputs/input.component';
import { useDispatch, useSelector } from "react-redux";
import { selectFilterData } from "../../../redux/Common/tablefilter/tableFilter.selector";
import { addFilters, removeFilters } from "../../../redux/Common/tablefilter/tableFilter.actions";
import { AiOutlineClose } from "react-icons/ai";
import SelectBox from "../inputs/select.component";

const FilterDrawerComponent=({isDrawerOpen, setIsDrawerOpen, inputFilters})=>{
    const dispatch = useDispatch()
    const filtersData = useSelector(selectFilterData)

    const filterFunction=()=>{
        let filtersObj = {}
        inputFilters?.map(input=>{
            return filtersObj[input.key] = input.value
        })
        dispatch(addFilters(filtersObj))
    }

    const resetFilters=()=>{
        inputFilters?.map(input=>{
            return input.setValue("")
        })
        dispatch(removeFilters())
    }

    useEffect(()=>{
        if(filtersData !== {} || !filtersData){
            inputFilters?.map(input=>{
                return input.setValue(filtersData[input?.key] || "")
            })
        }
    },[filtersData])
    
    return (
        <Drawer
        anchor="right"
        variant="persistent"
        hideBackdrop={true}
        open={isDrawerOpen}>
            <div className="filter_drawer">
                <div className="filter_drawer-wrapper">
                    <div className="filter_drawer-wrapper_header">
                        <div className="filter_drawer-wrapper_header-wrapper">
                            <p className="filter-header"> Filters/Search</p>
                            <button className="filter-header_btn" onClick={()=>setIsDrawerOpen(false)}>
                                <AiOutlineClose className="filter-header_btn_icon"></AiOutlineClose>
                            </button>
                        </div>
                    </div>
                    <div className="filter_drawer-main">
                        <div className="filter_drawer-main_wrapper">
                            {
                                inputFilters?.map((inputFilter,i) => {
                                    return(
                                        <div key={i} className="filter-input">
                                            {
                                            inputFilter.type==="select" ?
                                            <SelectBox label={inputFilter.text} value={inputFilter.value} setValue={inputFilter.setValue} options={inputFilter.selectOptions}/>
                                                :
                                            <InputEvent text={inputFilter.text}  placeholder={inputFilter.placeholder} value={inputFilter.value} setValue={inputFilter.setValue} type={inputFilter.type || "text"}></InputEvent>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="filter_drawer-wrapper_footer">
                        <div className="filter_drawer-wrapper_footer-wrapper">
                            <button className="filter-footer_btn" onClick={()=>{filterFunction();setIsDrawerOpen(false)}}>Find</button>
                            <button className="filter-footer_btn" onClick={()=>{resetFilters();setIsDrawerOpen(false)}}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default FilterDrawerComponent;