import React from 'react';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';
import MyAccountComponent from '../../components/Global/MyAccount/MyAccount.component';

const MyAccountPage = () => {
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<MyAccountComponent/>}/>
            </div>
        </div>
    );
};

export default MyAccountPage;
