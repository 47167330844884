import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import "./PrivacyPolicyDialogue.component.scss";
import { AiOutlineClose } from "react-icons/ai";

export default function PrivacyPolicyDialogue({dialogueBox, setConfirmDiaogue}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(()=>{
    setOpen(dialogueBox?.open)
  },[dialogueBox?.open])

  React.useEffect(()=>{
      return ()=>{
          setOpen(false)
      }
  },[])

  const handleClose = () => {
    setConfirmDiaogue({...dialogueBox, open:false});
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="privacy_policy">
          <div className="privacy_policy-wrapper">
              <div className="privacy_policy-wrapper-heading_main"><p>{dialogueBox?.type ==="pp" ? "Privacy Policy" : "Terms & Conditions"}</p></div>
              <div className="privacy_policy-wrapper-heading_sub"><p>Updated June 3, 2022</p></div>
              <div className="privacy_policy-wrapper-content">
                <div className="privacy_policy-wrapper-content-heading"><p>Introduction</p></div>
                <div className="privacy_policy-wrapper-content-paragraph">
                  <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestiae dolores magnam aliquam, dolor nam qui velit. Consequatur, blanditiis, iusto, itaque optio nisi voluptas nobis voluptatibus debitis cupiditate deserunt provident illum.</p>
                </div>
                <div className="privacy_policy-wrapper-content-paragraph">
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam animi, neque et enim velit harum aut libero, voluptas possimus atque mollitia distinctio magni, laboriosam ea vero architecto accusantium error tempore!</p>
                </div>
                <div className="privacy_policy-wrapper-content-paragraph">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, placeat nobis! Ex corporis cupiditate adipisci necessitatibus consequatur suscipit autem, unde porro sed voluptates. Asperiores eveniet vitae eius, necessitatibus blanditiis id.</p>
                </div>
                <div className="privacy_policy-wrapper-content-paragraph">
                  <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea dolorum reprehenderit quaerat voluptas libero qui, accusantium earum excepturi quo quae sint, maxime id aperiam pariatur, expedita non? Quos, cupiditate sapiente!</p>
                </div>
                <div className="privacy_policy-wrapper-content-paragraph">
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam animi, neque et enim velit harum aut libero, voluptas possimus atque mollitia distinctio magni, laboriosam ea vero architecto accusantium error tempore!</p>
                </div>
                <div className="privacy_policy-wrapper-content-paragraph">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, placeat nobis! Ex corporis cupiditate adipisci necessitatibus consequatur suscipit autem, unde porro sed voluptates. Asperiores eveniet vitae eius, necessitatibus blanditiis id.</p>
                </div>
                <div className="privacy_policy-wrapper-content-paragraph">
                  <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea dolorum reprehenderit quaerat voluptas libero qui, accusantium earum excepturi quo quae sint, maxime id aperiam pariatur, expedita non? Quos, cupiditate sapiente!</p>
                </div>
              </div>
          </div>
        </div>
        <button onClick={handleClose} className='dialogue_box-close'>
            <AiOutlineClose></AiOutlineClose>
        </button>
      </Dialog>
    </div>
  );
}