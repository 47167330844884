import { endpoints } from '../../../constants/url.endpoint';
import { manageBusinessPsychologistActions } from './manageBusinessPsychologist.reducer';

const { businessPsychologistListDataSuccess, resetBusinessPsychologistAllData, businessPsychologistDetailsSuccess, resetBusinessPsychologistDetail } = manageBusinessPsychologistActions;

export const fetchBusinessPsychologistListing =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.businessPsychologistListing}`,
                method: 'GET',
                params:apiData
            }).then((res) => {
                dispatch(businessPsychologistListDataSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetBusinessPsychologistAllData());
                reject(err)
            });
    })
};

export const addBusinessPsychologist =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.addBusinessPsychologist}`,
                method: 'POST',
                data:apiData
            }).then((res) => {
                dispatch(businessPsychologistDetailsSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                dispatch(resetBusinessPsychologistDetail());
                reject(err)
            });
    })
};

export const fetchBusinessPsychologistDetail =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosInstance({
                url: `${endpoints.businessPsychologist}/${apiData.id}`,
                method: 'GET',
            }).then((res) => {
                dispatch(businessPsychologistDetailsSuccess(res?.data));
                resolve(res?.data?.data?.data)
            }).catch(err => {
                dispatch(resetBusinessPsychologistDetail());
                reject(err)
            });
    })
};

export const updateBusinessPsychologist =(axiosInstance ,apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
             axiosInstance({
                url: `${endpoints.businessPsychologist}/${apiData?._id}`,
                method: 'PATCH',
                data:apiData
            }).then((res) => {
                dispatch(businessPsychologistDetailsSuccess(res?.data?.data));
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};