export const myAccountFormValidation = (sectionType,{ email, contactNo, firstname, lastname, currentPassword, passwordConfirm, password}) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneNumberRegex = new RegExp(/^[0-9]{10}$/i);
    const passWordregex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/i);
    if(sectionType ==="detail"){
      if (!email) {
        errors.email = "Email can't be empty!";
      } else if (!regex.test(email.trim())) {
        errors.email = "This is not a valid email format.";
      }
      if (!contactNo) {
        errors.contactNo = "Phone Number can't be empty";
      }else if(contactNo.length !==10){
          errors.contactNo = "Phone Number must be of 10 numbers only.";
      }else if(!phoneNumberRegex.test(contactNo)){
          errors.contactNo = "Phone Number is invalid.";
      }
  
      if (!firstname) {
        errors.firstname = "First Name can't be empty.";
      }
      if (!lastname) {
        errors.lastname = "Last Name can't be empty.";
      }
      return errors;
    }else{
      if (!currentPassword) {
        errors.currentPassword = "Please enter your old password";
      }
      if (!password) {
        errors.password = "Password is required.";
      } else if (password.length < 8) {
        errors.password = "Password must be more than 8 characters.";
      } else if (password.length > 16) {
        errors.password = "Password cannot exceed more than 16 characters.";
      }else if (!passWordregex.test(password.trim())) {
        errors.password = "Password is invalid.";
      }
  
      if (!passwordConfirm) {
        errors.passwordConfirm = "Confirm password is required.";
      } else if (passwordConfirm.length < 8) {
        errors.passwordConfirm = "Confirm Password must be more than 8 characters.";
      } else if (passwordConfirm.length > 16) {
        errors.passwordConfirm = "Confirm Password cannot exceed more than 16 characters.";
      } else if (password!==passwordConfirm) {
        errors.passwordConfirm = "Password & Confirm password must be same.";
      }
      return errors;
    }
};