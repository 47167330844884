import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { fetchInterviewToken } from '../../../redux/Common/videoInterview/videoInterview.actions';
import ButtonEvent from '../../Global/inputs/button.component';
import AgoraUIKitWidget from '../../Widget/AgoraUiKit/AgoraUiKit.component';
import './VideoInterview.component.scss';

const VideoInterviewComponent = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const navigateTo = useNavigate()
  const axiosInstance = useAxiosInstance()
  const [meetingDetail,setMeetingDetail] = useState(null)
  const [meetingError,setError] = useState(false)

  useEffect(()=>{
    dispatch(fetchInterviewToken(axiosInstance,{id})).then((res)=>{
      setMeetingDetail(res?.data?.meetingDetails)
    }).catch(err=>{
      setError(true)
    })
  },[axiosInstance, dispatch, id])

  return (
    <div className="interview-container">
      <div className="interview-wrapper">
        <div className="interview-logo">
          <img src="/logo-one.png" className='interview-logo-image' alt="logo" />
        </div>
        <div className="assessmentFeedback_main-actions_btn">
          <ButtonEvent theme={"secondary"} text={"Back To Dashboard"} func={()=>navigateTo('/')}/>
        </div>
      </div>
      <AgoraUIKitWidget meetingDetail={meetingDetail}/>
    </div>
  );
}
  

export default VideoInterviewComponent;