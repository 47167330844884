import React from 'react';
import { useDispatch } from 'react-redux';
import ManageCandidateComponent from '../../components/Common/ManageCandidate/ManageCandidate.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';
import useWindowSize from '../../hooks/window.hook';
import { logout } from '../../redux/users/user.actions';

const ManageCandidatePage = () => {
    const dispatch = useDispatch();
    const {width, height} = useWindowSize();
    const handleLogout = () => {
        dispatch(logout());
    };
    return (
        <div className="homepage_container">
            <div className="homepage_wrapper">
                <HomeComponent childrenComponent={<ManageCandidateComponent/>}/>
            </div>
        </div>
    );
};

export default ManageCandidatePage;