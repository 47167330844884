import * as React from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import Select, { components } from 'react-select';
import './select.component.scss'

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props?.selectProps?.menuIsOpen ?
                <AiOutlineCaretUp/>
                :
                <AiOutlineCaretDown/>
                }
            </components.DropdownIndicator>
        )
    );
};

const SelectBox=({label, value, setValue, options}) => {
    const [selectedValue, setSelectedValue] = React.useState({label: "Select", value: ""});

    React.useEffect(()=>{
        if(value){
            let option = options.filter((x)=>{
                if(x?.value === value) return x
            })
            setSelectedValue(option[0])
        }else{
            setSelectedValue({label: "Select", value: ""})
        }
    },[value])

    const handleChange=((e)=>{
        setValue(e?.value)
    })

    return (
        <div className='selectbox'>
            <div className="selectbox-wrapper">
                {label && <label className="selectbox-label">{label}</label>}
                <div>
                    <Select options={options}
                        onChange={handleChange}
                        value={selectedValue}
                        className='select'
                        classNamePrefix="select"
                        components={{ DropdownIndicator }}
                        isSearchable={false}
                        styles={{
                            singleValue:(baseStyles)=>({
                                ...baseStyles,
                                color: !value ? "#858585" : "#3f464f",

                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              color:"red"
                            },
                        })}
                    />
                </div>
            </div>
        </div>
    );
}

export default SelectBox;