import { endpoints } from '../../constants/url.endpoint';

export const writtenAssessmentSubmit =(axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosInstance({
                url: `${endpoints.onCandidateBookSlot}/${apiData.id}`,
                method: 'PATCH',
                // data: apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const checkAssessmentStatus =(axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosInstance({
                url: `${endpoints.checkAssessmentStatus}/${apiData.id}`,
                method: 'GET',
                // data: apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchAssessmentScore =(axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosInstance({
                url: `${endpoints.candidateAssessmentScore}/${apiData.id}`,
                method: 'GET',
                // data: apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const addCandidateAssessment =(axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.addCandidateAssessment}`,
                method: 'POST',
                data: apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const getCandidateAssessment =(axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
            axiosInstance({
                url: `${endpoints.candidateAssessment}/${apiData?.id}`,
                method: 'GET',
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};

export const fetchAssessmentRatings =(axiosInstance) => async (dispatch) => {
    return new Promise((resolve,reject) =>{
        // try {
            axiosInstance({
                url: `${endpoints.getAssessmentRatings}`,
                method: 'GET',
                // data: apiData
            }).then((res) => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
    })
};