import * as React from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async'
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import '../../Global/inputs/select.component.scss'

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {
                props?.selectProps?.menuIsOpen ?
                <AiOutlineCaretUp/>
                :
                <AiOutlineCaretDown/>
                }
            </components.DropdownIndicator>
        )
    );
};


const SearchableAsyncSelectBox=({selectApi, selectById, label, value,setValue}) => {
    const dispatch= useDispatch()
    const axiosInstance= useAxiosInstance()
    const [selectedValue, setSelectedValue] = React.useState({label: "Search", value: ""});
    const [options, setOptions] = React.useState([{label: "Select", value: ""}]);

    React.useEffect(()=>{
        if(value){
            let option = options?.filter((x)=>{
                if(x?.value === value) return x
            })
            setSelectedValue(option[0])
        }else{
            setSelectedValue({label: "Search", value: ""})
        }
    },[value])

    const handleChange=((e)=>{
        setValue(e?.value)
    })

    const searchOptions = (inputValue, callback) => {
        dispatch(selectApi(axiosInstance,{keyword:inputValue, status:'active', _id: selectById || ""})).then(res=>{
            setOptions(res?.data?.data?.listingData || res?.data?.data?.users)
            return callback(res?.data?.data?.listingData || res?.data?.data?.users)
        })
    }

    const NoOptionsMessage = props => {
        return (
          <components.NoOptionsMessage {...props}>
            <span className="custom-css-class">{selectedValue?.label !=="Select" ? `${'"'+selectedValue?.label+'"' + " is selected. Please search for other."}`: "Please search here."}</span> 
          </components.NoOptionsMessage>
        );
      };

    return (
        <div className='selectbox'>
            <div className="selectbox-wrapper">
                {label && <label className="selectbox-label">{label}</label>}
                <div className='selectoptions'>
                    <AsyncSelect
                        loadOptions={searchOptions}
                        options={options}
                        onChange={handleChange}
                        value={selectedValue}
                        className='select'
                        classNamePrefix="select"
                        components={{ DropdownIndicator, NoOptionsMessage }}
                        isSearchable={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default SearchableAsyncSelectBox;