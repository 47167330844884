import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import AptillioToaster from '../AptillioToaster/AptillioToaster.component';
import ButtonEvent from '../inputs/button.component';
import InputEvent from '../inputs/input.component';
import { AiOutlineArrowLeft } from "react-icons/ai";
import AlertDialog from '../AlertDialog/AlertDialog.component';
import SelectBox from '../inputs/select.component';
import { BiInfoCircle } from 'react-icons/bi';
import { passwordToolTip } from '../../../pages/resetPassword/resetPassword.constant';
import { addToaster } from '../../../redux/appToaster/appToaster.actions';
import AssignCandidateDialogue from '../../AptillioAdmin/AssignCandidateDialogue/AssignCandidateDialogue';
import { fetchAvailableHiringManagerListing, updateNewHiringManagerStatus } from '../../../redux/Common/manageHiringManager/manageHiringManager.actions';

const AddManageDataComponent = ({ inputFields, formConstants, apiAction, inputValidation}) => {
    const dispatch = useDispatch()
    const navigateTo = useNavigate()
    const location = useLocation()
    const axiosInstance = useAxiosInstance()
    const [toastList, setToastList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [confirmDialog, setConfirmDiaogue] = useState({ openAlert:false, alertMessage:""});
    const [confirmAction, setConfirmAction] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [fetchedDetailsObj, setFetchedDetailsObj] = useState(null);
    const [assignHMDialog, setAssignHMDiaogue] = useState({ openDialogue:false, dialogueMessage:""});

    useEffect(() => {
        if(formConstants && formConstants?.actionType !=="add" && inputFields?.length>0){
            const routeSplit = location?.pathname.split('/')
            const id = routeSplit[routeSplit.length-1]
            dispatch(apiAction.fetch(axiosInstance,{id})).then((res)=>{
                setFetchedDetailsObj(res)
                inputFields?.map(input=>{
                    return input.key && input.setValue(res[input.key])
                })
            }).catch((err)=>{
                setFetchedDetailsObj(null)
                setToastList([{id:0, title:"Fetch Error", description: err?.response?.data?.message, icon:"error"}])
            })
        }
    }, [formConstants])

    useEffect(()=>{
        if(confirmAction===true){
            const created_by = localStorage.getItem("userId")
            let apiData = {}
            inputFields?.map(input=>{
                return apiData[input.key] = input.value
            })
            if(formConstants?.actionType === "add"){
                apiData = {...apiData, created_by}
            }else{
                apiData = {...apiData, _id:fetchedDetailsObj?._id, created_by, modified_by: created_by}
                !apiData.password && delete apiData.password
                delete apiData.passwordConfirm
            }
            setConfirmDiaogue({ openAlert:false, alertMessage:""});
            setConfirmAction(false)
            dispatch(formConstants?.actionType==="add"? apiAction?.add(axiosInstance, {...apiData}): apiAction.update(axiosInstance, {...apiData})).then(res=>{
                dispatch(addToaster({id:0, title:`${formConstants.title} ${formConstants?.actionType==="add" ? "Added" : "Updated"}`, description: `${formConstants.title} has been ${formConstants?.actionType==="add" ? "added" : "updated"} successfully.`, icon:"success"}))
                setTimeout(()=>{
                    navigateTo(formConstants.routeBack)
                },1500)
            }).catch(err=>{
                if(err?.request?.status === 400 && err?.response?.data?.status === "Candidate Assigned"){
                    setAssignHMDiaogue({ openDialogue:true, dialogueMessage:"Assign New Hiring Manager", dispatchActions:{submit:updateHMAssignStatus, userId: fetchedDetailsObj?._id, select: fetchAvailableHiringManagerListing} });
                    return
                }
                setToastList([{id:0, title:`${formConstants?.actionType==="add" ? "Add Error" : "Update Error"}`, description: err?.response?.data?.message, icon:"error"}])
            });
        }
    },[confirmAction])

    const updateHMAssignStatus = (data) => {
        return new Promise((resolve, reject)=>{
            dispatch(updateNewHiringManagerStatus(axiosInstance, {...data, hiringManagerId: data?.assigneeId})).then(res=>{
                resolve("New Hirig Manager has been asssigned")
                dispatch(addToaster({id:0, title:"Hiring Manager Assigned", description: "New Hirig Manager has been assigned successfully.", icon:"success"}))
            }).catch(err=>{
                dispatch(addToaster({id:0, title:"Update Error", description: err?.response?.data?.message || "Error while assigning a new Hirig Manager.", icon:"error"}))
                reject(err)
            })
        })
    }

    const handleSubmit = (e) => {
        e?.preventDefault();
        let formData = {actionType:formConstants?.actionType}
        inputFields?.map(input=>{
            return formData[input.key] = input.value
        })
        setFormErrors(inputValidation(formData));
        setIsSubmit(true);
    };

    useEffect(()=>{
        return()=>{
            clearTimeout()
            setConfirmAction(false)
        }
    },[])

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            setConfirmDiaogue({ openAlert:true, alertMessage: formConstants.confirmDialogue})
        }
    }, [formErrors]);
    
    return (
        <div className="view_org-container">
            <div className="view_org-wrapper">
                <div className="view_org_main">
                    <div className="view_org_main-header_container">
                        <div className="view_org_main-listing_header">
                            <p className='view_org_main-listing_header_text'>{formConstants?.formHeader}</p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="view_org_main-inputs">
                            <div className="view_org_main-inputs_wrapper" style={{justifyContent:"flex-end"}}>
                                    <label className='org_main-input_label'><span style={{fontSize:"1.5rem", color: "red"}}>*</span> Required Fields</label>
                            </div>
                            {
                                inputFields?.map((input)=>{
                                    return(
                                        <div key={input?.key} className="view_org_main-inputs_wrapper org_name-input">
                                            <div className="org_main-label">
                                                <label className='org_main-input_label'>{input.label}
                                                {((input?.key ==="password" || input?.key ==="passwordConfirm") && formConstants?.actionType ==="edit") ? "": input.required ? <span style={{color: "red", fontSize:"1.5rem"}} className='info-icon'>*</span>: ""}
                                                {input?.key==="password" && <span className='info-icon' data-tip={passwordToolTip}><BiInfoCircle/></span>}</label>
                                            </div>
                                            <div className="org_main-input">
                                                {
                                                    input.type ==="select" ?
                                                    <SelectBox value={input.value} setValue={input.setValue} options={input.selectOptions}/> :
                                                    <>
                                                    {
                                                        input.key !== "password" ?
                                                        <InputEvent id={input?.key==="passwordConfirm" ? "reset_confirm_pass": undefined} nolabel={true} value={input?.value} type={input?.type} placeholder={input.placeholder} setValue={input.setValue} maxLength={input?.maxLength ? input?.maxLength: undefined}/>
                                                        :
                                                        <InputEvent nolabel={true} placeholder={input.placeholder} maxLength={16} required={formConstants?.actionType==="add"} type={passwordVisible ? "text" : "password"} 
                                                        value={input.value} setValue={input.setValue} icon={passwordVisible ? "/icons/visible-eye.png" : "/icons/not-visible-eye.png"} 
                                                        iconFunc={()=>setPasswordVisible(!passwordVisible)}/>
                                                    }
                                                    </>
                                                }
                                                {
                                                    formErrors[input.key] && 
                                                    <>
                                                        <p style={{color: "red"}} className="input_field-error_text">{formErrors[input.key]}</p>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="view_org_main-action">
                            {
                                (formConstants?.actionType ==="add" || (formConstants?.actionType ==="edit" && fetchedDetailsObj)) &&
                                <div className="view_org_main-action_button">
                                    <ButtonEvent text={"Save"} func={handleSubmit}/>
                                </div>
                            }
                        </div>
                        <div className="view_org_main-action">
                            <div className="view_org_main-action_button">
                                <ButtonEvent text={"Back"} customStyle={{backgroundColor:"#5367FD", color:"white"}} 
                                icon={<AiOutlineArrowLeft/>} func={()=>navigateTo(formConstants.routeBack)}/>
                            </div>
                        </div>
                    </form>
                </div>
                <AptillioToaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}/>
                
                <AlertDialog
                openAlert={confirmDialog?.openAlert}
                alertMessage={confirmDialog.alertMessage}
                setConfirmDiaogue = {setConfirmDiaogue}
                setConfirmAction={setConfirmAction}/>
            </div>
            <AssignCandidateDialogue
                dialogueContainer={assignHMDialog}
                setConfirmDiaogue = {setAssignHMDiaogue}/>
        </div>
    )
}

export default AddManageDataComponent