import AgoraUIKit, { layout } from 'agora-react-uikit';
import React, { useEffect, useState } from 'react'
import 'agora-react-uikit/dist/index.css';
import ButtonEvent from '../../Global/inputs/button.component';
import { AiOutlineMenu } from 'react-icons/ai';
import InterviewDrawerComponent from '../../Common/Interview/InterviewDrawer/InterviewDrawer.component';
import { useDispatch } from 'react-redux';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { addCandidateNotes, endInterview } from '../../../redux/Common/videoInterview/videoInterview.actions';
import { addToaster } from '../../../redux/appToaster/appToaster.actions';
import { BiArrowBack } from 'react-icons/bi';
import { fetchCandidateDetail } from '../../../redux/Common/manageCandidates/manageCandidates.actions';
import { useNavigate } from 'react-router-dom';
import AptillioToaster from '../../Global/AptillioToaster/AptillioToaster.component';
import { agoraAppId } from '../../../constants/envExports';

const AgoraUIKitWidget = ({meetingDetail}) => {
    const [videocall, setVideocall] = useState(false)
    const [isHost, setHost] = useState(true)
    const [isPinned, setPinned] = useState(true)
    const [candidateDetail, setCandidateDetail] = useState(null)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const navigateTo = useNavigate()
    const [toastList, setToastList] = useState([]);
    
    // const [username, setUsername] = useState('')
    const styles = {
      container: { height: '80vh', width:'95%', margin:"auto", display: 'flex',justifyContent:"center", flex: 1, backgroundColor: '#007bff22'},
      heading: { textAlign: 'center', marginBottom: 0 },
      videoContainer: { display: 'flex', width:"100%",height:"100%", position:'relative', backgroundColor: '#007bff22'},
      // errorContainer:{ display: 'flex', width:"100%",height:"100%", position:'relative', backgroundColor: '#007bff22'},
      nav: { display: 'flex', justifyContent: 'center', flexDirection:'column'},
      btn: { backgroundColor: '#007bff', cursor: 'pointer', borderRadius: 5, padding: '4px 8px', color: '#ffffff', fontSize: 20 },
      input: { display: 'flex', height: 24, alignSelf: 'center'}
    }

    useEffect(()=>{
      if(meetingDetail && meetingDetail?.token){
        if(meetingDetail.type ==="bp"){
          setVideocall(true)
          setIsDrawerOpen(true)
          dispatch(fetchCandidateDetail(axiosInstance,{id: meetingDetail.candidateId})).then(res=>{
            setCandidateDetail(res?.candidate)
            
          }).catch(err=>{
              addToaster([{id:0, title:"Fetch Error", description: err?.response?.data?.message, icon:"error"}])
          }) 
        }else{
          setVideocall(true)
        }
      }
    },[meetingDetail])

    const handlePostInterview=(end)=>{
        dispatch(endInterview(axiosInstance, { end, id:meetingDetail?.candidateId, modified_by: meetingDetail?.bpId })).then(res=>{
          setToastList([{id:0, title:"Response Submitted", description: "Interview has been ended.", icon:"success"}])
          if (end) return navigateTo(`/assessment/feedback/add/${meetingDetail?.candidateId}`);
          return navigateTo(`/`);
        }).catch(err=>{
          setToastList([{id:0, title:"Error", description: err?.response?.data?.message || "Error while ending the interview", icon:"error"}])
        })
    }

    const handleAddNotes=(notes)=>{
      if(candidateDetail){
        dispatch(addCandidateNotes(axiosInstance, { id:meetingDetail?.candidateId, created_by: meetingDetail?.bpId, notes})).then(res=>{
          return setToastList([{id:0, title:"Notes added", description: "Notes has been successfully added.", icon:"success"}])
        }).catch(err=>{
          setToastList([{id:0, title:"Error", description: err?.response?.data?.message || "Error while adding notes.", icon:"error"}])
        })
      }
    }

    return (
      <div style={styles.container}>
        { isDrawerOpen && meetingDetail?.type ==="bp" && <InterviewDrawerComponent candidateDetail={candidateDetail} addCandidateNotes={handleAddNotes}/>}

        {meetingDetail && videocall ? 
  
        <div style={styles.videoContainer}>
          {meetingDetail?.type==="bp" && <div className='candidate_menu'>
            <ButtonEvent customStyle={{margin:'2px', backgroundColor:"transparent", fontSize:'1.5rem', padding:"5px", boxShadow:"unset"}} 
            func={()=>setIsDrawerOpen(!isDrawerOpen)} text={""} icon={isDrawerOpen ? <BiArrowBack/> :<AiOutlineMenu/>}></ButtonEvent>
          </div>}
            <>
              <AgoraUIKit
                rtcProps={{
                appId: `${agoraAppId}`,
                channel: `${meetingDetail?.channelName}`,
                token: `${meetingDetail?.token}`, //add your token if using app in secured mode
                role: isHost ? 'host' : 'audience',
                layout: isPinned ? layout.pin : layout.grid,
                // enableScreensharing: true
              }}
              // rtmProps={{username: username || 'user', displayUsername: true}}
              callbacks={{
                EndCall: () => {
                  setVideocall(false)
                  setIsDrawerOpen(false)
                },
              }} 
              styleProps={{
                localBtnContainer:{
                position:'absolute',
                backgroundColor: 'unset',
                width:'max-content',
                right:'45%',
                bottom:'5%',
                height:"unset"
                }
              }}/>
            </>
      </div>
      : !videocall && meetingDetail ? 
        <div style={styles.nav}>
          <div style={{padding:'5px'}} className="meeting_end-msg">
            <h3> Meeting has been ended.</h3>
          </div>
          {
            meetingDetail.type ==="bp" &&
            <>
              <div style={{padding:'5px'}} className="meeting_end-msg">
                <ButtonEvent theme="secondary" text={"Interview Completed"} func={()=>handlePostInterview(true)}></ButtonEvent>
              </div>
              <div style={{padding:'5px'}} className="meeting_end-msg">
                <ButtonEvent theme="secondary" text={"Not attended Interview"} func={()=>handlePostInterview(false)}></ButtonEvent>
              </div>
            </>
          }
          {/* <ButtonEvent func={()=>setVideocall(true)} style={{padding:"10px"}} theme="secondary" text={'Rejoin Meeting'}></ButtonEvent> */}
        </div>:(
        // <div style={styles.errorContainer}>
        <div style={styles.nav}>
          <h3> Unable to fetch the interview Details. Either the link is invalid or you are not accessing interview link on the scheduled time.</h3>
        </div>
        // </div>
      )
    }
    <AptillioToaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}/>
    </div>
    );
  }


export default AgoraUIKitWidget;