import React, { useEffect, useState } from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth.hook';
import { fetchHiringManagerListing } from '../../../redux/Common/manageHiringManager/manageHiringManager.actions';
import { selectHiringManagerListingData } from '../../../redux/Common/manageHiringManager/manageHiringManager.selectors';
import { selectFilterData } from '../../../redux/Common/tablefilter/tableFilter.selector';
import TableComponent from '../../Global/aptllioTable/table.component';
import FilterDrawerComponent from '../../Global/FilterDrawer/FilterDrawer.component';
import ButtonEvent from '../../Global/inputs/button.component';
import { HIRING_MANAGER_DASHBOARD, MANAGE_HIRING_MANAGER_HEADING } from './ManageHiringManager.constants';

const ManageHiringManagerComponent = () => {
    const {getUserAuth } = useAuth()
    const navigateTo = useNavigate()
    const filtersData = useSelector(selectFilterData)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [firstname, setFirstNameFilter] = useState("")
    const [lastname, setLastNameFilter] = useState("")
    const [userType, setUserType] = useState("")
    const [email, setEmailFilter] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const statusFilterOptions = [
        { value: 'active', label: 'Active'},
        { value: 'inactive', label: 'Inactive' },
    ]
    const inputFilterList = [
        { text:"Filter By First Name", placeholder:"Hiring manager first name", key:"firstname", value:firstname, setValue:setFirstNameFilter},
        { text:"Filter By Last Name", placeholder:"Hiring manager last name", key:"lastname", value:lastname, setValue:setLastNameFilter},
        { text:"Filter By Email", placeholder:"Hiring manager email", key:"email", value:email, setValue:setEmailFilter},
        { text:"Filter By Status", placeholder:"Select", value:statusFilter, key:"status", setValue:setStatusFilter, type:"select", selectOptions:statusFilterOptions}
    ]
    
    const organisationTableColumns = [
        {
            dataField: 'firstname',
            text: 'First Name',
            sort: true
        },
        {
            dataField: 'lastname',
            text: 'Last Name',
        },
        {
            dataField: 'email',
            text: 'Email Address',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '22%'};
            }
        },
        {
            dataField: 'designation',
            text: 'Designation',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '18%'};
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle: (colum, colIndex) => {
                return { width: '12%'};
            },
            formatter: (cell, row, rowIndex, formatExtraData)=>{
                return <>{row?.status?.split("")[0]?.toUpperCase() + row?.status?.substring(1,row?.status?.length)}</>
            }
        },
    ];

    useEffect(()=>{
        let user= getUserAuth()
        setUserType(user?.userType)
    },[getUserAuth])

    return (
        <div className="dashboard_container">
            <div className="dashboard_wrapper">
                <div className="dashboard_main">
                    <div className="dashboard_main-header_container">
                        <div className="dashboard_main-listing_header">
                            <p className='dashboard_main-listing_header_text'>{userType==="org-admin"? HIRING_MANAGER_DASHBOARD : MANAGE_HIRING_MANAGER_HEADING}</p>
                        </div>
                        <div className="dashboard_main-listing_header_actions">
                            <div className="dashboard_main-listing_header_actions-filter">
                                <ButtonEvent func={()=>setIsDrawerOpen(true)} text={userType!=="org-admin" ? "Search/Filter":""} icon={<FaFilter/>}></ButtonEvent>
                            </div>
                            {
                                (userType === "org-admin" && getUserAuth().is_HM==="true") &&
                                <div className="dashboard_main-listing_header_actions-filter">
                                    <ButtonEvent text={"Self Hiring Dashboard"} func={()=>navigateTo("/manageCandidate")}></ButtonEvent>
                                </div>
                            }
                            <div className="dashboard_main-listing_header_actions-search">
                                {
                                userType==="org-admin" &&
                                    <ButtonEvent text={"Add Hiring Manager"} customStyle={{backgroundColor:"#5367FD", color:"white"}} func={()=>{navigateTo(`/manageHiringManager/add`)}} icon={<FaPlus/>}></ButtonEvent>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_main-list_table">
                        <TableComponent columns={organisationTableColumns} apiAction={fetchHiringManagerListing} apiSelector={selectHiringManagerListingData} filters={filtersData}></TableComponent>
                    </div>
                </div>
            </div>
            <FilterDrawerComponent inputFilters={inputFilterList} setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen}/>
        </div>
    )
}

export default ManageHiringManagerComponent;