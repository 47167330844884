import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import ManageOrganisationComponent from '../../components/AptillioAdmin/ManageOrganisation/ManageOrganisation.component';
import HomeComponent from '../../components/Global/HomeLayout/HomeLayout.component';
import { baseURL } from '../../constants/axiosInstance';
import { endpoints } from '../../constants/url.endpoint';
import useAxiosInstance from '../../hooks/useAxiosInstance';
import { fetchCheckSubscription } from '../../redux/AptillioAdmin/manageSubscription/manageSubscription.actions';

const HomePage = () => {
    // const axiosInstance = useAxiosInstance()
    const navigateTo = useNavigate()
    // const dispatch = useDispatch()

    const getUserSubscription=()=>{
        const userId = localStorage.getItem("userId")
        const token = localStorage.getItem("aptillioToken")

        const apiOptions = {
            baseURL: baseURL,
            method: 'GET',
            url: `${endpoints.checkSubscription}/${userId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        axios.request(apiOptions).then(function (res) {
            if(res.data.data.result.length>0) {
                return navigateTo('/manageHiringManager')
            }
            return navigateTo('/productFeature')
        }).catch(function (error) {
            return navigateTo('/productFeature')
        });
    }

    const userType = localStorage.getItem("userType")
    switch(userType?.toLowerCase()){
        case "org-admin":
            // return <Navigate to="/manageHiringManager" />;
            return getUserSubscription()
        case "hiring-manager":
            return <Navigate to="/manageCandidate" />;
        case "business-psychologist":
            return <Navigate to="/manageCandidate" />;
        default:
            return (
                <div className="homepage_container">
                    <div className="homepage_wrapper">
                        <HomeComponent childrenComponent={<ManageOrganisationComponent/>}/>
                    </div>
                </div>
        );
    }
};

export default HomePage;
